import React from 'react';
import SearchAndSelect, { SearchAndSelectProp } from '../base';
import { useTranslate } from '@refinedev/core';
import { Administrator } from '~/api';
import isEmpty from 'lodash/isEmpty';

type Props = Omit<SearchAndSelectProp, 'queryKey' | 'getValueAndLabel'> & {
  onSelect?: (id: string) => void;
  initialValue?: Administrator;
};

export const AdministratorSelection: React.FC<Props> = (props) => {
  const { onSelect, initialValue, ...restProps } = props;
  const t = useTranslate();

  const constructLabel = (data: Administrator) => {
    const { firstName, lastName, customFields } = data;
    const { phoneNumber } = customFields || {};
    let label = `${firstName} ${lastName}`.trim();

    if (phoneNumber) {
      label = `${label} (${phoneNumber})`;
    }

    return label;
  };

  const searchSelect = (
    <SearchAndSelect
      {...restProps}
      {...(!isEmpty(initialValue)
        ? {
            initialOption: {
              label: constructLabel(initialValue),
              value: initialValue.id as string,
            },
          }
        : {})}
      onSelect={(id) => onSelect?.(id)}
      queryKey="getAdministrators"
      getValueAndLabel={(data) => ({
        value: data.id,
        label: constructLabel(data as Administrator),
      })}
      placeholder={t('outlet.placeholder.selectManager', {}, 'Select Manager')}
    />
  );

  return searchSelect;
};

export type AdministratorSelectionProps = Props;
