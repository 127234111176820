import { useState } from 'react';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import {
  getSdk,
  PointTransactionHistory,
  PointSignType,
  PointTransactionSourceType,
  Channel,
} from '~/api';
import { adjustmentSource, dateFormatter, formatFullName } from '~/resources/helpers';
import { useCreate, useNavigation } from '@refinedev/core';
import { numeralThousandFormat } from '~/config/helper';
import { renderTextWithPrefix } from '../helpers';
import { resourceNames } from '../../resource-names';
import { PointAdjustForm } from './adjustForm';
import { FullDateTimeFormat } from '~/config/constant';
import { generateSort } from '~/config/helper';
import { TranslateFunction } from '~/config/types';
import { ResourceContext } from '~/resources/type';
import { pointTransactionHistoriesCustomGetList } from '~/config/CustomFetcher/point-transaction-histories';
import { MdHistory } from "react-icons/md";

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<PointTransactionHistory>({
    resourceName: resourceNames.pointTransaction,
  });

const fields: ResourceField<PointTransactionHistory>[] = [
  'id',
  'createdAt',
  { membership: ['firstName', 'lastName'] },
];

export const pointAdjustmentResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames?.pointTransaction,
    label: 'Points Adjustment History',
    icon: <MdHistory />,
    fields: defineFields(fields),
    defaultValues: {} as any,
    defaultPageSize: 25,
    allowCreate: true,
    allowSearch: true,
    filterControls: {
      createdAt: {
        type: 'daterange',
        config: {
          label: 'Created at',
        },
      },
    } as any,
    filterConfig: {
      alwaysExpanded: true,
    },
    searchConfig: {
      placeholder: ({ t }) =>
        t('pointTransactionHistories.placeholder.search', {
          fallback: 'Search by Name',
          ns: 'common',
        }),
    },
    dataProvider: {
      getList: (options) =>
        // Not sure why need to spread like this with explicit resource name
        // to avoid ts error
        pointTransactionHistoriesCustomGetList({
          ...options,
          resource: resourceNames.pointTransaction,
        }),
      create: async ({ client, variables }) => {
        const formData = variables;

        return getSdk(client)
          ?.ManualAdjustPoints({
            input: {
              action: formData?.action,
              point: parseInt(formData?.points),
              toUserIds: [...formData?.customer],
              remarks: formData?.remarks,
            },
          })
          ?.then((res) => ({
            data: res,
          }));
      },
    },
    columns: ({ t }) => {
      return [
        {
          id: 'name',
          header: t('pointTransactionHistories.column.name', {
            fallback: 'Customer Name',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, membership } = data.row.original as PointTransactionHistory;
            const fullName = formatFullName(membership.firstName, membership.lastName);
            return <div>{`${fullName}`}</div>;
          },
        },
        {
          id: 'actions',
          header: t('pointTransactionHistories.column.actions', {
            fallback: 'Actions',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, source } = data.row.original as PointTransactionHistory;
            return (
              <div>
                <span>{adjustmentSource(source)}</span>
              </div>
            );
          },
        },
        {
          id: 'remarks',
          header: t('pointTransactionHistories.column.remarks', {
            fallback: 'Remarks',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, remarks } = data.row.original as PointTransactionHistory;
            return (
              <div>
                <span>{remarks || '-'}</span>
              </div>
            );
          },
        },
        {
          id: 'dateTime',
          header: t('pointTransactionHistories.column.dateAndTime', {
            fallback: 'Date and Time',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, createdAt } = data.row.original as PointTransactionHistory;
            return (
              <div>
                <span>{dateFormatter(createdAt, FullDateTimeFormat)}</span>
              </div>
            );
          },
        },
        {
          id: 'points',
          header: t('pointTransactionHistories.column.points', {
            fallback: 'Points',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, point, pointSign } = data.row.original as PointTransactionHistory;
            const isPositive = pointSign === PointSignType?.Positive;
            return (
              <div className={`${isPositive ? 'text-success-300' : 'text-error-300'}`}>
                {renderTextWithPrefix({
                  prefixText: 'PTS',
                  text: `${isPositive ? '+' : '-'} ${point?.toLocaleString()}`,
                  prefixPosition: 'right',
                })}
              </div>
            );
          },
        },
      ];
    },
    list: {
      tabs: {
        options: [
          {
            filterValue: { field: 'type', operator: 'eq', value: 'ALL' },
            label: ({ t }: { t: TranslateFunction }) =>
              t(`pointTransactionHistories.tabs.all`, { fallback: 'All', ns: 'common' }),
          },
          {
            filterValue: {
              field: 'type',
              operator: 'eq',
              value: PointTransactionSourceType?.Grant,
            },
            label: ({ t }: { t: TranslateFunction }) =>
              t(`pointTransactionHistories.tabs.grant`, { fallback: 'Grant', ns: 'common' }),
          },
          {
            filterValue: {
              field: 'type',
              operator: 'eq',
              value: PointTransactionSourceType?.Deduct,
            },
            label: ({ t }: { t: TranslateFunction }) =>
              t(`pointTransactionHistories.tabs.deduct`, { fallback: 'Deduct', ns: 'common' }),
          },
        ],
      },
    } as any,
    createConfig: {
      title: ({ t }) =>
        t('pointTransactionHistories.adjust.name', {
          fallback: 'Adjust Points',
          ns: 'common',
        }),
    },
    create: {
      render: (helpers) => {
        const { mutate, isLoading } = useCreate();
        const { goBack } = useNavigation();

        const onSubmit = (data: any) => {
          mutate(
            {
              resource: resourceNames?.pointTransaction,
              values: data,
            },
            {
              onSuccess: () => {
                goBack();
              },
            }
          );
        };
        return <PointAdjustForm onPressSubmit={onSubmit} loading={isLoading} />;
      },
    },
  });
};
