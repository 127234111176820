import camelCase from 'lodash/camelCase';
import { MultiSelectField, SelectField } from '@scalingworks/react-admin-ui';
import { Controller } from 'react-hook-form';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const FormSelectField = ({
  input: { item, register, setValue, watch, control },
}: InputProps) => {
  const { title, name, required, placeholder, defaultValue, props, options, multiSelect } = item;
  const formName = camelCase(name || title);
  const watchValue = watch(formName);

  useEffect(() => {
    if (defaultValue && !watchValue) {
      setValue(formName, defaultValue);
    }
  }, [watchValue, defaultValue]);

  return (
    <Controller
      name={formName}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        return multiSelect ? (
          <MultiSelectField
            {...props}
            defaultValue={defaultValue}
            label={title}
            required={required}
            layout="responsive"
            options={options?.map(({ label, value }) => ({ label, value })) || []}
            placeholder={placeholder}
            value={value || []}
            onValue={(val) => {
              onChange?.(val);
            }}
          />
        ) : (
          <SelectField
            {...props}
            label={title}
            required={required}
            options={options?.map(({ label, value }) => ({ label, value })) || []}
            placeholder={placeholder}
            value={watchValue}
            onValue={(val) => {
              setValue(formName, val);
              onChange?.(val);
            }}
          />
        );
      }}
    />
  );
};
