import React, { useEffect, useState } from 'react';
import { useTranslate, useUpdate } from '@refinedev/core';
import { ModalWrapper } from '~/components';
import { AdministratorSelection } from '~/components/SearchAndSelect';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';

export const ManagerModal: React.FC<Props> = ({
  open,
  setOpen,
  title,
  outletId,
  currentManager,
  refetch,
}) => {
  // ======================= HOOKS
  const t = useTranslate();
  const { mutate: update, isLoading } = useUpdate();
  const [selectedManagerId, setSelectedManagerId] = useState<string>(currentManager?.id || '');

  // ======================= EVENTS
  const handleSave = () => {
    update(
      {
        resource: resourceNames.outlet,
        id: outletId!,
        values: {
          managerId: selectedManagerId,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
        },
      }
    );
  };

  // ======================= EFFECTS
  useEffect(() => {
    setSelectedManagerId(currentManager?.id || '');
  }, [currentManager, open]);

  // ======================= VIEWS
  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={title}
      onConfirm={handleSave}
      contentClassName="w-[500px]"
      loading={isLoading}
    >
      <div className="flex flex-col space-y-6 p-4">
        <p className="text-gray-500 text-sm">
          {t('outlet.manager.description', {}, 'Select a manager for this outlet')}
        </p>
        <div className="space-y-3">
          <AdministratorSelection
            initialValue={currentManager || undefined}
            onSelect={(administratorId) => {
              setSelectedManagerId(administratorId);
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
