import React, { useRef, useState } from 'react';
import { ResourceField, createHelpers, createResource } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import { type Product, ProductCustomFields, ProductType, Channel, BusinessUnitFulfillmentMethods } from '~/api';
import { numeralThousandFormat } from '~/config/helper';
import { ActionButton, FormBuilder, VariantForm, ActionButtonRefProps } from 'components';
import { useCreate, useNavigation, useTranslate } from '@refinedev/core';
import { formBuilder } from '~/config/ProductResource';
import {
  ProductOptionGroupFormInput,
  ProductVariantFormInput,
} from '~/components/VariantForm/props';
import { variantFormBuilder } from '~/components/VariantForm/config';
import { ImageViewer } from '@scalingworks/react-admin-ui';
import { resourceNames } from '../../resource-names';
import { formatBackendPrice, renderStatusTag } from '../../helpers/product-helper';
import { FiGrid } from 'react-icons/fi';
import { ProductShowPage } from './show/product';
import { ProductEditPage } from './edit';
import { productDataProvider } from './data-provider';
import { currencyForColHeader, onlyOneCurrency } from '~/resources/helpers';
import map from 'lodash/map';
import take from 'lodash/take';
import head from 'lodash/head';
import { ResourceContext } from '~/resources/type';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Product>({
    resourceName: resourceNames.product,
  });

const defaultValues = {
  name: '',
  description: '',
};

export type FormInputs = {
  name: string;
  basePrice: string;
  quantity: string;
  description: string;
  enable: boolean;
  variants: ProductVariantFormInput[];
  optionGroups: ProductOptionGroupFormInput[];
  images: File[] | string[];
  businessUnitFulfillments?: BusinessUnitFulfillmentMethods[];
  categories?: string[];
  facetValueIds?: string[];
  modifierGroups?: string[];
  customFields?: ProductCustomFields;
};

const productFields: ResourceField<Product>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'description',
  'enabled',
  'slug',
  { collections: ['name', 'id'] },
  { facetValues: ['id', 'name', { facet: ['id', 'code'] }] },
  {
    variants: [
      'id',
      'price',
      'stockOnHand',
      'priceWithTax',
      'name',
      'currencyCode',
      'enabled',
      {
        stockLevels: [
          'id',
          'stockLocationId',
          'stockAllocated',
          'stockOnHand',
          'stockLocationId',
          { stockLocation: ['id', 'name'] },
        ],
      },
      { options: ['id', 'name'] },
      { assets: ['source'] },
    ],
  },
  {
    optionGroups: ['id', 'name', 'code', { options: ['code', 'id', 'name'] }],
  },
  { assets: ['source'] },
  { customFields: [{ fulfillChannels: ['id', 'name'] }, 'type', 'modifierGroupSorting'] },
  {
    businessUnitFulfillmentMethods: [
      'businessUnit',
      { fulfillmentChannels: ['id', 'name', 'code', 'disabled'] },
    ],
  },
  { modifierGroups: ['group', { modifiers: ['id', 'name'] }] },
];

export const productResource = (context: ResourceContext) => {
  const { channel, stockLocations } = context;
  return createResource({
    name: resourceNames.product,
    label: 'Products',
    icon: <FiGrid />,
    // @ts-ignore
    fields: defineFields(productFields),
    defaultValues,
    createConfig: {
      title: ({ t }) =>
        t('product.create.name', {
          ns: 'common',
          fallback: 'Create Product',
        }),
    },
    formatBeforeSubmit: (data) => {
      return data;
    },
    dataProvider: productDataProvider,
    filterControls: {
      createdAt: {
        type: 'daterange',
        config: {
          label: 'Created On',
        },
      },
    },
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('product.placeholder.search', {
          ns: 'common',
          fallback: 'Search',
        }),
    },
    allowDelete: true,
    filterConfig: {
      alwaysExpanded: true,
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
      return [
        {
          id: 'name',
          header: t('product.column.product', { fallback: 'Product', ns: 'common' }),
          cell: (data) => {
            const { name = '', id, assets } = data.row.original as Product;
            return (
              <LinkToDetails resourceId={id} style={{ flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <ImageViewer
                    src={assets?.[0]?.source}
                    className="!w-14 !h-14 rounded-lg object-cover mr-2"
                    alt="Product"
                  />
                  <span>{name}</span>
                </div>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'collection',
          header: t('product.column.collections', { fallback: 'Categories', ns: 'common' }),
          cell: (data) => {
            const { collections, id } = data.row.original as Product;

            const maxItemsToShow = 3;
            const categoryNames = map(collections, 'name');
            let result;
            if (categoryNames.length <= maxItemsToShow) {
              result = <p>{categoryNames.join(', ')}</p>;
            } else {
              const remainingItems = categoryNames.length - maxItemsToShow;
              result = (
                <p>
                  {take(categoryNames, maxItemsToShow).join(', ')}
                  <span className="text-primary-600">{` + ${remainingItems} more`}</span>
                </p>
              );
            }

            return (
              <LinkToDetails resourceId={id}>
                <span>{result}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'inventory',
          header: t('product.column.inventory', { fallback: 'Inventory', ns: 'common' }),
          cell: (data) => {
            const { variants, id } = data.row.original as Product;
            const mainVariants = variants?.[0];
            return (
              <LinkToDetails resourceId={id}>
                <span>{numeralThousandFormat(mainVariants?.stockOnHand)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'status',
          header: t('product.column.status', { fallback: 'Status', ns: 'common' }),
          cell: (data) => {
            const { id, enabled } = data.row.original as Product;
            return <LinkToDetails resourceId={id}>{renderStatusTag(enabled)}</LinkToDetails>;
          },
        },
        {
          id: 'price',
          header: t('product.column.price', {
            fallback: 'Price',
            ns: 'common',
            data: { currency: currencyForColHeader(channel) },
          }),
          cell: (data) => {
            const { variants, id } = data.row.original as Product;
            const lowestPrice = variants.reduce((minPrice, product) => {
              return product.price < minPrice ? product.price : minPrice;
            }, Infinity);
            const currency = head(variants)?.currencyCode;

            return (
              <LinkToDetails resourceId={id}>
                <span>
                  {!onlyOneCurrency(channel) && `${currency} `}
                  {numeralThousandFormat(
                    lowestPrice !== Infinity
                      ? formatBackendPrice({ price: lowestPrice, isDivide: true })
                      : 0,
                    true
                  )}
                </span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const t = useTranslate();
            const { id: productId, name } = data?.row?.original as Product;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            return (
              <ActionButton
                ref={actionButtonRef}
                actions={[
                  {
                    label: t('actions.edit', { fallback: 'Edit' }),
                    name: 'edit',
                    onAction: () => navigateToEdit({ id: productId }),
                  },
                  // Temporary not allowing delete for now to avoid issue on POS
                  // {
                  //   label: t('actions.delete', { fallback: 'Delete' }),
                  //   name: 'delete',
                  //   onAction: () => invokeDelete({ id: productId }),
                  //   render: (onAction) => {
                  //     return (
                  //       <button type="button">
                  //         <TriggerConfirmModal
                  //           visible={showDeleteConfirmation}
                  //           onOpenChange={(val) => {
                  //             const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                  //             setShowDeleteConfirmation(val);
                  //             actionButtonSetOpen && actionButtonSetOpen(val);
                  //           }}
                  //           onPressConfirm={onAction}
                  //         />
                  //       </button>
                  //     );
                  //   },
                  // },
                ]}
              />
            );
          },
        },
      ];
    },
    show: {
      component: (helpers) => {
        return (
          <ProductShowPage
            pageProps={helpers}
            resourceName={resourceNames.product}
            channel={channel}
          />
        );
      },
    },
    create: {
      render: (helpers) => {
        const navigation = useNavigation();
        const t = useTranslate();
        const { mutate: create } = useCreate({
          mutationOptions: {
            onSettled: (data) => {
              if (data) navigation?.goBack();
            },
          },
        });

        return (
          <FormBuilder
            resourceName={resourceNames.product}
            title={t('product.create.name')}
            extra={[
              {
                render: (renderProps) => {
                  return (
                    <VariantForm {...renderProps} items={variantFormBuilder({ stockLocations })} />
                  );
                },
                validate: true,
                key: 'variants',
              },
            ]}
            onSubmit={async (data) => {
              await create({
                resource: resourceNames.product,
                values: data,
              });
            }}
            keyToWatch={['optionGroups']}
            keyToHide={['quantity', 'basePrice']}
            items={(formHook) => formBuilder({ formHook })}
          />
        );
      },
    },
    edit: {
      render: () => {
        return (
          <ProductEditPage
            resourceContext={context}
            productFields={productFields}
            resourceName={resourceNames.product}
          />
        );
      },
    },
    list: {
      tabs: {
        options: [
          {
            // @ts-ignore
            filterValue: [{ field: 'type', operator: 'eq', value: ProductType.Product }],
            label: ({ t }) => t(`product.tabs.all`, { fallback: 'All', ns: 'common' }),
          },
          {
            filterValue: [
              // @ts-ignore
              { field: 'enabled', operator: 'eq', value: true },
              // @ts-ignore
              { field: 'type', operator: 'eq', value: ProductType.Product },
            ],
            label: ({ t }) => t(`product.tabs.available`, { fallback: 'Available', ns: 'common' }),
          },
          {
            filterValue: [
              // @ts-ignore
              { field: 'enabled', operator: 'eq', value: false },
              // @ts-ignore
              { field: 'type', operator: 'eq', value: ProductType.Product },
            ],
            label: ({ t }) => t(`product.tabs.hidden`, { fallback: 'Hidden', ns: 'common' }),
          },
        ],
      },
    },
  });
};
