import { createHelpers, createResource } from '@scalingworks/refine-react-admin';
import { FiPieChart } from 'react-icons/fi';
import { SellerResource } from '~/api';
import { ResourceContext } from '../type';
import { BaseReporting } from './components/base-reporting';
import { ReactNode } from 'react';

interface CreateReportingResourceOptions {
  resourceName: string;
  sellerResource: SellerResource;
  label: string;
  icon?: ReactNode;
}

export const createReportingResource = ({
  resourceName,
  sellerResource,
  label,
  icon = <FiPieChart />,
}: CreateReportingResourceOptions) => {
  const ReportingComponent = () => (
    <BaseReporting resourceName={resourceName} sellerResource={sellerResource} title={label} />
  );

  return (context: ResourceContext) =>
    createResource({
      name: resourceName,
      label,
      icon,
      defaultValues: {},
      fields: [],
      columns: () => [],
      allowCreate: false,
      allowDelete: false,
      allowEdit: false,
      allowSearch: false,
      list: {
        render: () => <ReportingComponent />,
      },
      show: createHelpers({ resourceName }).defineShowPage({
        component: () => <ReportingComponent />,
      }),
    });
}; 