import { OrderStates, OrderStatus, PaymentStates, TranslateFunction } from '~/config/types';
import { dateFormatter } from './format';
import { DayFormat, FullDateFormat, isUpdateTimeSlotEnabled } from '~/config/constant';
import { FulfillmentMethodType, Order, SalesChannel } from '~/api';
import { Action } from '~/components/ActionButton/props';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { TagProps } from '@scalingworks/react-admin-ui';
type OrderStatesValues = OrderStates[];

/**
 * NOTE: order flow
 * Delivery: Draft => Pending => Confirmed => Shipping => Completed
 * Pickup: Draft => Pending => Confirmed => Shipping => Completed
 *
 * (to be comfirmed)
 * DineIn (only backoffice): Confirmed => Completed
 */

// Excluded 'Created' & 'AddingItems'
// Because POS order will be in Draft state, and backoffice need to see the order
const Draft: OrderStatesValues = ['Draft'];
const Pending: OrderStatesValues = ['ArrangingPayment', 'ArrangingAdditionalPayment'];
const Confirmed: OrderStatesValues = ['PaymentAuthorized', 'PaymentSettled'];
const Preparing: OrderStatesValues = ['Preparing'];
const Shipping: OrderStatesValues = ['Shipped', 'PartiallyShipped'];
const Completed: OrderStatesValues = ['Delivered'];
const Cancelled: OrderStatesValues = ['Cancelled'];

export type RefactoredOrderStates =
  | 'All'
  | 'Draft'
  | 'To Pay'
  | 'Paid'
  | 'Preparing'
  | 'Shipped'
  | 'Delivered'
  | 'Cancelled';

/**
 * @description translate vendure order states into simplified status
 * @param state vendure's order state
 * @returns OrderStatus
 */
export const getOrderStatus = (state: string): OrderStatus | '' => {
  const s = state as OrderStates;
  if (Draft.includes(s)) return OrderStatus.Draft;
  if (Pending.includes(s)) return OrderStatus.Pending;
  if (Confirmed.includes(s)) return OrderStatus.Confirmed;
  if (Preparing.includes(s)) return OrderStatus.Preparing;
  if (Shipping.includes(s)) return OrderStatus.Shipping;
  if (Completed.includes(s)) return OrderStatus.Completed;
  if (Cancelled.includes(s)) return OrderStatus.Cancelled;
  return '';
};

/**
 * @description Get vendure order states by simplified status
 * @param status OrderStatus
 * @returns vendure's order states group (string[])
 */
export const getOrderStateByStatus = (status: RefactoredOrderStates): string[] => {
  switch (status) {
    case 'Draft':
      return Draft;
    case 'To Pay':
      return Pending;
    case 'Paid':
      return Confirmed;
    case 'Shipped':
      return Shipping;
    case 'Delivered':
      return Completed;
    case 'Preparing':
      return Preparing;
    case 'Cancelled':
      return Cancelled;
    default:
      return [
        ...Draft,
        ...Pending,
        ...Preparing,
        ...Confirmed,
        ...Shipping,
        ...Completed,
        ...Cancelled,
      ];
  }
};

export const getOrderStatusTagColor = (status: OrderStatus): TagProps['color'] => {
  const Dict: Record<OrderStatus, TagProps['color']> = {
    // [OrderStatus.Draft]: '!text-gray-500 !border-gray-500 !bg-gray-100',
    // [OrderStatus.Pending]: '!text-warning-500 !border-warning-500 !bg-warning-100',
    // [OrderStatus.Confirmed]: '!text-blue-500 !border-blue-500 !bg-blue-100',
    // [OrderStatus.Preparing]: '!text-purple-500 !border-purple-500 !bg-purple-100',
    // [OrderStatus.Shipping]: '!text-blue-500 !border-blue-500 !bg-blue-100',
    // [OrderStatus.Completed]: '!text-success-500 !border-success-500 !bg-success-100',
    // [OrderStatus.Cancelled]: '!text-error-300 !border-error-300 !bg-error-100',
    [OrderStatus.Draft]: 'default',
    [OrderStatus.Pending]: 'warning',
    [OrderStatus.Confirmed]: 'blue',
    [OrderStatus.Preparing]: 'purple',
    [OrderStatus.Shipping]: 'blue',
    [OrderStatus.Completed]: 'success',
    [OrderStatus.Cancelled]: 'error',
  };

  return Dict[status] ?? 'default';
};

export const canDownloadInvoiceOrReceipt = (status: OrderStatus): boolean => {
  if ([OrderStatus.Confirmed, OrderStatus.Shipping, OrderStatus.Completed].includes(status)) {
    return true;
  }

  return false;
};

export const isPendingAcceptOrder = (order?: Order): boolean => {
  const { state, customFields } = order || {};
  const { checkoutThrough, posAcceptedAt } = customFields || {};
  if (
    (checkoutThrough === SalesChannel.WebApp || checkoutThrough === SalesChannel.MobileApp) &&
    !posAcceptedAt &&
    state !== ('Cancelled' as OrderStates) &&
    state !== ('Delivered' as OrderStates)
  ) {
    return true;
  }

  return false;
};

export const getOrderFulfillmentSlot = (timeSlot = '', fulfillmentDate = new Date()): string => {
  if (!timeSlot) return '-';
  return `${timeSlot} (${dateFormatter(fulfillmentDate, `${DayFormat} ${FullDateFormat}`)})`;
};

/**
 * @description
 * this function returns the common actions for order
 * on Show / List Page
 *
 * @param order
 */
type OrderActions = {
  onDownloadInvoice?: (id: string, type?: string, url?: string) => Promise<void>;
  onPrintReceipt?: (transactionId: string) => Promise<void>;
  /**
   * @deprecated
   * Paid status is update by payment individually instead of updating from order
   */
  onUpdateStatus?: () => void;
  onArrangePayment?: () => void;
  onFulfillItem?: () => void;
  onUpdateStoreOrSlot?: () => void;
  onUpdateRemark?: () => void;
  onCancel?: () => void;
};
type GetOrderActionsProps = {
  order?: Order;
  t: TranslateFunction;
  options: OrderActions;
};
export const getOrderActions = (input: GetOrderActionsProps): Action[] => {
  const { order, t, options } = input;
  const actions: Action[] = [];
  if (!order || isEmpty(order)) return actions;
  const { payments, fulfillments, state, customFields, orderPlacementMetadata } = order;
  const { outletFulfillmentId } = orderPlacementMetadata || {};

  const deliveryMethod = customFields?.shippingMethodCode;
  const orderStatus = getOrderStatus(state as OrderStates) as OrderStatus;

  const isDineInDraft =
    orderStatus === OrderStatus.Draft && deliveryMethod === FulfillmentMethodType.DineIn;
  const noValidPayment =
    isEmpty(payments) ||
    isEmpty(
      payments?.filter((payment) =>
        (['Arranging', 'Authorized', 'Settled'] as PaymentStates[]).includes(
          payment.state as PaymentStates
        )
      )
    );
  /**
   * NOTE:
   * Before allow update status, need to done:
   * Delivery & Pickup: created in Pending, need to make fulfillment;
   * DineIn: created in Draft, need to add payment & fulfillment;
   */
  // TODO: arrange from backoffice api have error
  // if ((isDineInDraft || orderStatus === OrderStatus.Pending) && noValidPayment) {
  //   actions.push({
  //     label: t('actions.arrangePayment', 'Arrange Payment'),
  //     name: 'arrange-payment',
  //     // onAction: () => onArrangePayment(),
  //     onAction: () => options?.onArrangePayment?.(),
  //   });
  // }

  /**
   * Create Fulfillment for PaymentSettled order
   */
  if (!noValidPayment && orderStatus === OrderStatus.Confirmed) {
    // TODO: handle for dynamic fulfillments where if some lines haven't fulfill
    if (isEmpty(fulfillments)) {
      actions.push({
        label: t('actions.prepare'),
        name: 'fulfill-item',
        onAction: () => options?.onFulfillItem?.(),
      });
    }
  }

  // @deprecated
  // if (
  //   !noPayment &&
  //   [OrderStatus.Draft, OrderStatus.Pending, OrderStatus.Confirmed].includes(orderStatus)
  // ) {
  //   actions.push({
  //     label: t('actions.updateStatus', 'Update Status'),
  //     name: 'update',
  //     // onAction: () => setOpenUpdate(true),
  //     onAction: () => options?.onUpdateStatus?.(),
  //   });
  // }

  // TODO: re-enable invoice function after backend api ready
  // if (orderStatus !== OrderStatus.Draft && canDownloadInvoice(orderStatus)) {
  //   actions.push({
  //     label: t('actions.downloadInvoice', 'Download Invoice'),
  //     name: 'download-invoice',
  //     onAction: () => {
  //       // !!id && downloadInvoice(id);
  //       !!order.id && options?.onDownloadInvoice?.(order.id);
  //     },
  //   });
  // }

  // TODO: backend to have flag to differentiate seller use invoice or receipt (retail / fnb ?)
  if (orderStatus !== OrderStatus.Draft && canDownloadInvoiceOrReceipt(orderStatus)) {
    actions.push({
      label: t('actions.printReceipt', 'Print Receipt'),
      name: 'print-receipt',
      onAction: () => {
        const paidPayment = head(
          order.payments?.filter((payment) => payment.state === ('Settled' as PaymentStates))
        );
        if (isEmpty(paidPayment)) {
          console.error(`No Payment Found for Order ${order.code}`);
          return;
        }

        options?.onPrintReceipt?.(paidPayment.transactionId!);
      },
    });
  }

  // Generic for staff to be able to update
  // NOTE: Temporary disable for update if no slot defined previously
  // In order to hide from merchandise which doesn't required slot
  if (outletFulfillmentId && isUpdateTimeSlotEnabled) {
    actions.push({
      label: t('actions.updateStoreOrSlot'),
      name: 'update-outlet-or-slot',
      onAction: () => {
        options.onUpdateStoreOrSlot?.();
      },
    });
  }

  // For staff to update order remark
  actions.push({
    label: t('actions.updateRemark'),
    name: 'update-order-remark',
    onAction: () => {
      options.onUpdateRemark?.();
    },
  });

  // Need to use custom api to ensure the order total amount being updated to 0
  if (orderStatus !== OrderStatus.Cancelled) {
    actions.push({
      label: t('actions.cancel', 'Cancel'),
      buttonClassName: 'text-red-500',
      name: 'cancel',
      onAction: () => options?.onCancel?.(),
    });
  }

  return actions;
};

export const OrderFinalStatus = [OrderStatus.Cancelled, OrderStatus.Completed];
