import { useApiUrl, useCustom } from '@refinedev/core';
import { MetabaseIframeUrlOptions, MetabaseReportGroup, SellerResource } from '~/api';

export const useMetabaseApi = () => {
  // ====================== HOOKS
  const apiUrl = useApiUrl();

  const useGetMetabaseUrl = (variables: MetabaseIframeUrlOptions) => {
    const api = useCustom<String>({
      method: 'get',
      url: apiUrl,
      meta: {
        fields: [],
        variables: {
          options: {
            value: variables,
            type: 'MetabaseIframeUrlOptions!',
          },
        },
        operation: 'getMetabaseIframeUrl',
      },
    });

    return api;
  };

  const useGetMetabaseReportGroups = (sellerResource?: SellerResource) => {
    const api = useCustom<Array<MetabaseReportGroup>>({
      method: 'get',
      url: apiUrl,
      meta: {
        fields: [
          'title',
          'sellerResource',
          {
            collections: [
              'title',
              'description',
              {
                dashboards: ['id', 'title', 'description'],
              },
              {
                questions: ['id', 'title', 'description'],
              },
            ],
          },
        ],
        operation: 'getMetabaseReportGroups',
      },
    });

    // If sellerResource is provided, filter the results
    const filteredData = api.data?.data?.filter(
      (group) => !sellerResource || group.sellerResource === sellerResource
    );

    return {
      ...api,
      data: filteredData ? { data: filteredData } : undefined,
    };
  };

  return {
    useGetMetabaseUrl,
    useGetMetabaseReportGroups,
  };
};
