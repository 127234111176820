import { Button, SearchableSelectFieldProps, Tag } from '@scalingworks/react-admin-ui';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { FiBox } from 'react-icons/fi';
import ReceiptPrinterEncoder from '@point-of-sale/receipt-printer-encoder';

import { resourceNames } from '../resource-names';
import {
  type Order,
  getSdk,
  OutletFulfillment,
  AdminBulkOrderProcess,
  FulfillOrderInput,
  FulfillmentState,
  CancelOrderInput,
} from '~/api';
import {
  ActionButton,
  Loading,
  OutletFulfillmentEditModal,
  PaymentMethodModal,
  SomethingWentWrong,
} from '~/components';
import {
  useApiUrl,
  useCreate,
  useCustom,
  useCustomMutation,
  useNavigation,
  useTranslate,
} from '@refinedev/core';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  parsePrice,
  getOrderStatusTagColor,
  getOrderStatus,
  getOrderStateByStatus,
  toCamelCaseWord,
  dateFormatter,
  RefactoredOrderStates,
  currencyForColHeader,
  onlyOneCurrency,
  outletFulfillmentDateDisplay,
  generateCode,
} from '../helpers';
import { OrderStates, OrderStatus } from '~/config/types';
import head from 'lodash/head';
import { OrderShowPage } from './show';
import { useDownload } from '../helpers';
import {
  FulfillmentTrackingCodeLength,
  FullDateTimeFormat,
  ManualFulfillmentCode,
} from '~/config/constant';
import { GQLClient } from '~/config/gql-client';
import { BulkAction } from '@scalingworks/refine-react-admin/src/types';
import { camelCase, partition } from 'lodash';
import { PrinterContext } from '~/providers/Printer/context';
import { ResourceContext } from '../type';

const { defineFields, defineShowPage } = createHelpers<Order>({
  resourceName: resourceNames.order,
});

export const orderFields: ResourceField<Order>[] = [
  'id',
  'code',
  'createdAt',
  'updatedAt',
  'currencyCode',
  // delivery fee
  'shipping',
  'totalLinePrice',
  'orderPlacedAt',
  'state',
  'total',
  'totalWithTax',
  'subTotal',
  'couponCodes',
  // Just get the very basic line & fulfillment info needed for the bulk actions below
  { lines: ['id', 'quantity'] },
  { fulfillments: ['id'] },
  { surcharges: ['price', 'sku'] },
  { taxSummary: ['taxTotal'] },
  { promotions: ['couponCode'] },
  { customerSnapshot: ['name', 'contact'] },
  {
    fulfillments: [
      'id',
      'method',
      'state',
      'trackingCode',
      {
        additionalProperties: ['courier', 'driverContact', 'driverName', 'pickUpTime'],
      },
    ],
  },
  {
    dineInMetadata: ['adultPax', 'kidPax', 'tableCodeWithPrefix'],
  },
  { orderPlacementMetadata: ['date', 'isPlacedNow', 'timeSlot', 'outletFulfillmentId'] },
  {
    customFields: [
      'remark',
      'cancellationRemark',
      'shippingMethodCode',
      'giftFee',
      { giftOption: ['label'] },
      'pointAwarded',
      'checkoutThrough',
      { purchaseOutlet: ['id', 'name'] },
      'posAcceptedAt',
    ],
  },
  { customer: ['id', 'firstName', 'lastName', 'phoneNumber'] },
  {
    shippingAddress: [
      'fullName',
      'streetLine1',
      'streetLine2',
      'city',
      'province',
      'postalCode',
      'country',
    ],
  },
  {
    billingAddress: [
      'fullName',
      'streetLine1',
      'streetLine2',
      'city',
      'province',
      'postalCode',
      'country',
    ],
  },
  { discounts: ['amount'] },
  {
    variants: [{ items: ['productId'] }],
  },
  {
    shippingLines: [{ shippingMethod: ['id', 'code', 'fulfillmentHandlerCode'] }],
  },
  {
    payments: [
      'id',
      'createdAt',
      'method',
      'transactionId',
      'amount',
      'state',
      'metadata',
      { refunds: ['id'] },
    ],
  },
];

export const orderResource = (context: ResourceContext) => {
  const { channel } = context;
  const client = GQLClient.getInstance();

  const { printer, printerRef } = useContext(PrinterContext);
  const { bulkPrintReceipts, bulkDownloadReceipts, bulkDownloadLoadingRef } = useDownload();
  // not all browsers support web usb api
  const supportWebUsb = !!(navigator as any)?.usb;

  return createResource({
    name: resourceNames.order,
    label: 'Orders',
    icon: <FiBox />,
    fields: defineFields(orderFields),
    defaultValues: {},
    defaultPageSize: 25,
    defaultSorter: [{ field: 'orderPlacedAt', order: 'desc' }],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('order.placeholder.search', {
          ns: 'common',
          fallback: "Search by Order ID or Customer's Last Name",
        }),
    },
    createConfig: {
      title: ({ t }) =>
        t('order.create.name', {
          ns: 'common',
          fallback: 'Create Order',
        }),
    },
    filterControls: async () => {
      const rawOutletFulfillments =
        (await getSdk(client).GetOutletFulfillments({}))?.getOutletFulfillments?.items || [];

      // TODO: handle DAY TYPE, temporary handle DATE type only
      const outletFulfillments = rawOutletFulfillments.filter((slot) => !!slot.startDate);

      const outlets = (await getSdk(client).getOutlets({ options: {} }))?.getOutlets?.items || [];

      return {
        orderPlacedAt: {
          type: 'daterange' as any,
          config: {
            label: 'Order Date',
          },
        },
        // Currently using this way due to unexpected ts error
        // `Expression produces a union type that is too complex to represent.ts(2590)`
        ['shippingMethodCode' as any]: {
          type: 'select',
          operator: 'eq',
          config: {
            options: Object.values(channel?.customFields?.availableFulfillmentMethods || [])?.map(
              (value) => ({
                label: toCamelCaseWord(value),
                value,
              })
            ),
            label: 'Fulfillment method',
            placeholder: 'Select method',
          },
        },
        ...(outlets.length > 1 && {
          purchaseOutletId: {
            type: 'select',
            operator: 'eq',
            config: {
              options: outlets.map((outlet) => ({ label: outlet.name, value: outlet.id })),
              label: 'Store',
              placeholder: 'Select store',
            },
          },
        }),
        /**
         * This is customFields.orderPlacementMetadata.outletFulfillmentId
         * but since it's a stringified JSON we need to filter from orderPlacementMetadata
         */
        // TODO: find a way to handle DAY / DATE Outlet fulfillment type
        ['orderPlacementMetadata' as any]: {
          type: 'searchable-select',
          operator: 'contains',
          config: {
            menuAlignStart: true,
            floatingPlacement: 'bottom-end',
            options: outletFulfillments.map((slot) => ({
              label: `${slot.outlet.name}: ${slot.type} [${outletFulfillmentDateDisplay(
                slot as OutletFulfillment
              )}]`,
              value: slot.id,
            })),
            label: 'Order Slot',
            placeholder: 'Select slot',
          } as Omit<SearchableSelectFieldProps<string>, 'value' | 'onValue'>,
        },
      };
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    allowDelete: false,
    // Temp disable due to invalid values, just use the metabase dashboard instead
    // headerContent: () => {
    //   return <OrderDashboard />;
    // },
    headerContent: () => {
      return (
        <div className="flex flex-row justify-end mr-3">
          {supportWebUsb ? (
            <div>
              <Button
                onClick={() => {
                  printerRef.current.connect();
                }}
              >
                Connect Printer {printer?.productName ? `(${printer.productName})` : ''}
              </Button>
              <Button
                disabled={!printer}
                className={`ml-3 ${!printer ? '' : 'bg-green-500'}`}
                onClick={() => {
                  if (!printer) {
                    return;
                  }

                  let encoder = new ReceiptPrinterEncoder({
                    language: printer.language,
                  });

                  let data = encoder
                    .initialize()
                    .text('The quick brown fox jumps over the lazy dog')
                    .newline()
                    .qrcode('https://google.com')
                    .newline()
                    .newline()
                    .newline()
                    .newline()
                    .newline()
                    .cut('full')
                    .encode();
                  printerRef.current.print(data);
                }}
              >
                Test Print
              </Button>
            </div>
          ) : (
            <div>
              <Button disabled>Thermal Printer Not Supported on this browser</Button>
            </div>
          )}
        </div>
      );
    },
    list: {
      tabs: {
        options: (
          [
            'All',
            'Draft',
            'To Pay',
            'Paid',
            'Preparing',
            'Shipped',
            'Delivered',
            'Cancelled',
          ] as Array<RefactoredOrderStates>
        ).map((key) => ({
          label: ({ t }) => t(`order.tabs.${camelCase(key)}`, { ns: 'common', fallback: key }),
          filterValue: {
            field: 'state',
            operator: 'in',
            value: getOrderStateByStatus(key),
          },
        })),
      },
    },
    columns: ({ LinkToDetails, refetchData, t }) => [
      {
        id: 'id',
        header: t('order.columns.orderId', { ns: 'common', fallback: 'Order ID' }),
        cell: (data) => {
          const { id, code } = data.row.original;

          return <LinkToDetails resourceId={id}>#{code}</LinkToDetails>;
        },
      },
      {
        id: 'deliveryMethod',
        header: t('order.columns.method', { ns: 'common', fallback: 'Method' }),
        cell: (data) => {
          const { id, customFields } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              {toCamelCaseWord(customFields?.shippingMethodCode ?? '-')}
            </LinkToDetails>
          );
        },
      },
      {
        id: 'orderPlacedAt',
        header: t('order.columns.date', { ns: 'common', fallback: 'Order Date' }),
        cell: (data) => {
          const { id, orderPlacedAt } = data.row.original;
          const display = dateFormatter(orderPlacedAt, FullDateTimeFormat);

          return (
            <LinkToDetails resourceId={id}>
              <span>{display}</span>
            </LinkToDetails>
          );
        },
      },
      // {
      //   id: 'timeSlot',
      //   header: t('order.columns.slot', { ns: 'common', fallback: 'Fulfillment Slot' }),
      //   cell: (data) => {
      //     const { id, orderPlacementMetadata, orderPlacedAt } = data.row.original;
      //     const { date, isPlacedNow, timeSlot } = orderPlacementMetadata || {};
      //     const display = getOrderFulfillmentSlot(timeSlot || '', date);

      //     return <LinkToDetails resourceId={id}>{display}</LinkToDetails>;
      //   },
      // },
      {
        id: 'customer',
        header: t('order.columns.customer', { ns: 'common', fallback: 'Customer' }),
        cell: (data) => {
          const { id, customer, customerSnapshot } = data?.row?.original;
          const { firstName = '', lastName = '' } = customer || {};
          let display = firstName || lastName ? `${firstName} ${lastName}`.trim() : '';
          if (!display) {
            display = customerSnapshot?.name || '';
          }

          return <LinkToDetails resourceId={id}>{display || '-'}</LinkToDetails>;
        },
      },
      {
        id: 'orderState',
        header: t('order.columns.status', { ns: 'common', fallback: 'Status' }),
        cell: (data) => {
          const { id, state } = data.row.original;
          const simplified = getOrderStatus(state) as OrderStatus;
          const t = useTranslate();

          return (
            <LinkToDetails resourceId={id}>
              <Tag color={getOrderStatusTagColor(simplified)}>
                {t(`order.status.${simplified.toLowerCase()}`).toUpperCase()}
              </Tag>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'totalWithTax',
        header: t('order.columns.amount', {
          ns: 'common',
          fallback: 'Amount',
          data: { currency: currencyForColHeader(channel) },
        }),
        cell: (data) => {
          const { id, totalWithTax, currencyCode } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <span>
                {totalWithTax ? (
                  <span>
                    {!onlyOneCurrency(channel) && (
                      <span className="font-medium">{currencyCode} </span>
                    )}
                    {parsePrice(totalWithTax)}
                  </span>
                ) : (
                  '-'
                )}
              </span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const order = data.row.original;
          const { id, state, fulfillments } = order;

          // ====================== HOOKS
          const t = useTranslate();
          const navigateTo = useNavigation();

          return (
            <ActionButton
              actions={[
                {
                  label: t('actions.edit', 'Edit'),
                  name: 'update',
                  onAction: () => navigateTo.edit(resourceNames.order, id),
                },
              ]}
            />
          );
        },
      },
    ],
    // dataProvider: {}
    // TODO: re-enable create feature
    allowCreate: false,
    // create: {
    //   render: (helpers) => {
    //     const navigation = useNavigation();
    //     const { mutate } = useCreate({
    //       mutationOptions: {
    //         onSettled: () => {
    //           navigation?.goBack();
    //         },
    //       },
    //     });

    //     return <OrderCreatePage />;
    //   },
    // },
    edit: {
      render(helpers) {
        const { id } = useParams();
        const navigation = useNavigation();

        if (!id) return <SomethingWentWrong />;
        // go to detail instead; cant edit
        navigation.show(resourceNames.order, id);

        return <Loading />;
      },
    },
    show: defineShowPage({
      component: (props) => {
        return <OrderShowPage queryResult={props.queryResult} />;
      },
    }),
    bulkConfig: {
      allowBulkSelection: true,
      onBulkAction: async (action, orders) => {
        switch (action) {
          // TODO: improve the alert to use notification hook instead
          case 'print-receipt':
          case 'download-receipt':
            const type = action === 'print-receipt' ? 'print' : 'download';
            if (type === 'print' && bulkDownloadLoadingRef.current.bulkPrint) {
              alert(`Please wait for the current print job to finish`);
              return Promise.resolve(false);
            }
            if (type === 'download' && bulkDownloadLoadingRef.current.bulkDownload) {
              alert(`Please wait for the current download job to finish`);
              return Promise.resolve(false);
            }
            const confirmPrint = window.confirm(
              `Are you sure you want to ${type} ${orders.length} receipts?`
            );
            if (!confirmPrint) {
              return Promise.resolve(false);
            }

            const [transactions, rejectedTransactions] = partition(
              orders.map((order) => head(order.payments?.filter((p) => p.state === 'Settled'))),
              (payment) => !!payment
            );

            if (rejectedTransactions.length) {
              alert('Some orders do not have settled transactions, they will be skipped');
            }

            const transactionIds = transactions.map((p) => p.transactionId!);
            if (type === 'print') {
              await bulkPrintReceipts(transactionIds);
            } else {
              await bulkDownloadReceipts(transactionIds);
            }

            return Promise.resolve(true);

            break;
          case 'fulfill':
            const paymentSettledStates = orders.map((order) => order.state);

            if (paymentSettledStates.some((state) => state !== ('PaymentSettled' as OrderStates))) {
              alert('Some orders are not in Paid state, please unselect those orders to proceed');
              return Promise.resolve(false);
            }

            try {
              await getSdk(client).bulkProcessOrders({
                input: {
                  orderIds: orders.map((order) => order.id),
                  type: AdminBulkOrderProcess.AddFulfillment,
                  data: {
                    ADD_FULFILLMENT: orders.map((order) => {
                      return {
                        handler: {
                          arguments: [
                            { name: 'method', value: order.customFields?.shippingMethodCode },
                            {
                              name: 'trackingCode',
                              value: generateCode({ length: FulfillmentTrackingCodeLength }),
                            },
                          ],
                          code: ManualFulfillmentCode,
                        },
                        lines: order.lines.map((line) => ({
                          orderLineId: line.id,
                          quantity: line.quantity,
                        })),
                      } as FulfillOrderInput;
                    }),
                  },
                },
              });

              return Promise.resolve(true);
            } catch (err) {
              alert(`Something went wrong, please try again`);
              return Promise.resolve(false);
            }

            break;

          case 'ship':
            const preparingStates = orders.map((order) => order.state);
            if (preparingStates.some((state) => state !== ('Preparing' as OrderStates))) {
              alert(
                'Some orders are not in Preparing state, please unselect those orders to proceed'
              );
              return Promise.resolve(false);
            }

            try {
              await getSdk(client).bulkProcessOrders({
                input: {
                  orderIds: orders.map((order) => order.id),
                  type: AdminBulkOrderProcess.UpdateFulfillment,
                  data: {
                    UPDATE_FULFILLMENT: orders.map((order) => {
                      return {
                        fulfillmentId: head(order.fulfillments)?.id!,
                        state: FulfillmentState.Shipped,
                      };
                    }),
                  },
                },
              });
              return Promise.resolve(true);
            } catch (err) {
              alert(`Something went wrong, please try again`);
              return Promise.resolve(false);
            }

            break;
          case 'deliver':
            const shippedStates = orders.map((order) => order.state);
            if (shippedStates.some((state) => state !== ('Shipped' as OrderStates))) {
              alert(
                'Some orders are not in Shipped state, please unselect those orders to proceed'
              );
              return Promise.resolve(false);
            }

            try {
              await getSdk(client).bulkProcessOrders({
                input: {
                  orderIds: orders.map((order) => order.id),
                  type: AdminBulkOrderProcess.UpdateFulfillment,
                  data: {
                    UPDATE_FULFILLMENT: orders.map((order) => {
                      return {
                        fulfillmentId: head(order.fulfillments)?.id!,
                        state: FulfillmentState.Delivered,
                      };
                    }),
                  },
                },
              });
              return Promise.resolve(true);
            } catch (err) {
              alert(`Something went wrong, please try again`);
              return Promise.resolve(false);
            }

            break;

          case 'cancel-order':
            const confirmCancel = window.confirm(
              `Are you sure you want to cancel ${orders.length} orders?`
            );
            if (!confirmCancel) {
              return Promise.resolve(false);
            }

            try {
              await getSdk(client).bulkProcessOrders({
                input: {
                  orderIds: orders.map((order) => order.id),
                  type: AdminBulkOrderProcess.CancelOrder,
                  data: {
                    CANCEL_ORDER: orders.map((order) => {
                      return {
                        orderId: order.id,
                        // Cancel all lines by not specifying the lines parameter
                        // Cancel shipping charges too
                        cancelShipping: true,
                        reason: 'Bulk cancellation from admin',
                      } as CancelOrderInput;
                    }),
                  },
                },
              });
              return Promise.resolve(true);
            } catch (err) {
              alert(`Something went wrong, please try again`);
              return Promise.resolve(false);
            }

            break;

          default:
            return Promise.resolve(false);
            break;
        }
      },
      bulkActions: (selections) => {
        const orderStates = selections.rows.map((row) => row.original).map((order) => order.state);
        const isAllPaidState = orderStates.every(
          (state) => state === ('PaymentSettled' as OrderStates)
        );
        const isAllPreparingState = orderStates.every(
          (state) => state === ('Preparing' as OrderStates)
        );
        const isAllShippedState = orderStates.every(
          (state) => state === ('Shipped' as OrderStates)
        );
        const isAllNonCancelledState = orderStates.every(
          (state) => state !== ('Cancelled' as OrderStates)
        );

        const actions: BulkAction[] = [
          {
            key: 'print-receipt',
            label: 'Print Receipt',
            buttonProps: {
              variant: 'solid',
            },
          },
          {
            key: 'download-receipt',
            label: 'Download Receipt',
            buttonProps: {
              variant: 'solid',
            },
          },
        ];

        if (isAllPaidState) {
          actions.unshift({
            key: 'fulfill',
            label: 'Prepare',
            buttonProps: {
              variant: 'solid',
            },
          });
        }

        if (isAllPreparingState) {
          actions.unshift({
            key: 'ship',
            label: 'Ready to Pickup / Shipped',
            buttonProps: {
              variant: 'solid',
            },
          });
        }

        if (isAllShippedState) {
          actions.unshift({
            key: 'deliver',
            label: 'Mark as Delivered',
            buttonProps: {
              variant: 'solid',
            },
          });
        }

        if (isAllNonCancelledState) {
          actions.push({
            key: 'cancel-order',
            label: 'Cancel Order',
            buttonProps: {
              variant: 'danger',
            },
          });
        }

        return actions;
      },
    },
  });
};
