import React, { Fragment, useState, useEffect, useRef } from 'react';
import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import '@szhsin/react-menu/dist/index.css';
import {
  useNavigation,
  useUpdate,
  useCustom,
  useApiUrl,
  useTranslate,
  useCreate,
  useNotification,
  useOne,
} from '@refinedev/core';
import { Table, assertExists, Tag, Label, TagProps } from '@scalingworks/react-admin-ui';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  FormBuilder,
  Loading,
  SomethingWentWrong,
  ViewBuilder,
  flexRow,
  RadioGroup,
  BusinessUnitFulfillmentTag,
} from '~/components';
import { MdStore } from 'react-icons/md';
import { FiMapPin } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { type Outlet, OutletStatus, getSdk, AdministratorList } from '~/api';
import { formBuilder } from '~/config/OutletResource';
import { GQLClient } from '~/config/gql-client';
import { resourceNames } from '../resource-names';
import { formatFullName, joinOutletAddress } from '../helpers';
import { renderOutletStatusTag } from '../helpers/outlet-helper';
import { Helmet } from 'react-helmet';
import { ResourceContext } from '../type';
import { OutletDetailPage } from './detail/index';
import { AdministratorSelection } from '~/components/SearchAndSelect';
import { formatBusinessUnitFulfillment } from '../helpers/format';

const { defineFields, defineShowPage } = createHelpers<Outlet>({
  resourceName: resourceNames.outlet,
});

const defaultValues = {
  name: '',
  description: '',
};

export type FormInputs = {
  name: string;
  contactNo: string;
  personInCharge: string;
  status: OutletStatus;
  outletFulfillments: any;
};

export const OutletStatusTagColor: Record<OutletStatus, TagProps['color']> = {
  [OutletStatus.Active]: 'success',
  [OutletStatus.Inactive]: 'default',
};

// Define fields based on the Outlet type from API
export const outletFields: ResourceField<Outlet>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'contactNumber',
  'status',
  'onlineOrdering',
  {
    address: [
      'addressLineOne',
      'addressLineTwo',
      'city',
      'province',
      'postalCode',
      'country',
      'countryCode',
      'latitude',
      'longitude',
    ],
  },
  { operationHours: ['day', 'openingTime', 'closingTime', 'isClosed'] },
  'managerId',
  { manager: ['id', 'firstName', 'lastName', { customFields: ['phoneNumber'] }] },
  {
    businessUnitFulfillmentMethods: [
      'businessUnit',
      { fulfillmentChannels: ['id', 'code', 'name'] },
    ],
  },
];

export const outletResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames.outlet,
    label: 'Outlet',
    icon: <MdStore />,
    fields: defineFields(outletFields),
    // TODO: Handle create outlet
    allowCreate: false,
    defaultValues: {} as any,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    allowSearch: true,
    searchConfig: {
      placeholder: ({ t }) =>
        t('outlet.placeholder.search', {
          fallback: 'Search by Name or Contact No.',
          ns: 'common',
        }),
    },
    createConfig: {
      title: ({ t }) =>
        t('outlet.create.add', {
          fallback: 'Add Outlet',
          ns: 'common',
        }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    allowDelete: true,
    columns: ({ LinkToDetails, t, navigateToEdit }) => [
      {
        id: 'name',
        header: t('outlet.column.name', { fallback: 'Name', ns: 'common' }),
        accessorKey: 'name',
        cell: (data) => {
          const { id, name = '' } = data.row.original;
          return <LinkToDetails resourceId={id}>{name}</LinkToDetails>;
        },
      },
      {
        id: 'contactNumber',
        header: t('outlet.column.contact', { fallback: 'Contact No.', ns: 'common' }),
        accessorKey: 'contactNumber',
        cell: (data) => {
          const phone = data.row.original.contactNumber || '-';
          return (
            <LinkToDetails resourceId={data.row.original.id}>
              <span>{phone}</span>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'address',
        header: t('outlet.column.address', { fallback: 'Address', ns: 'common' }),
        cell: (data) => {
          const { id, address } = data.row.original;
          const fullAddress = address
            ? `${address.addressLineOne}, ${address.city}, ${address.province}`
            : '-';
          return <LinkToDetails resourceId={id}>{fullAddress}</LinkToDetails>;
        },
      },
      {
        id: 'status',
        header: t('outlet.column.status', { fallback: 'Status', ns: 'common' }),
        tooltip: 'If outlet is visible to customers',
        cell: (data) => {
          const { id, status } = data.row.original;
          return (
            <LinkToDetails resourceId={id}>
              <Tag color={OutletStatusTagColor[status as OutletStatus]}>
                {t(`outlet.status.${status?.toLowerCase()}`, {
                  fallback: status as string,
                  ns: 'common',
                }).toUpperCase()}
              </Tag>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'onlineOrdering',
        header: t('outlet.column.onlineOrdering', { fallback: 'Online Ordering', ns: 'common' }),
        tooltip: 'If Outlet is accepting online orders',
        cell: (data) => {
          const { id, onlineOrdering } = data.row.original;
          return (
            <LinkToDetails resourceId={id}>
              <Tag color={onlineOrdering ? 'success' : 'default'}>
                {onlineOrdering ? 'ENABLED' : 'DISABLED'}
              </Tag>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'fulfillmentChannels',
        header: t('outlet.column.fulfillmentChannels', {
          fallback: 'Fulfillment Channels',
          ns: 'common',
        }),
        tooltip: 'Outlet supported fulfillment channels',
        cell: (data) => {
          const { id, businessUnitFulfillmentMethods = [] } = data.row.original;
          return (
            <LinkToDetails resourceId={id}>
              <BusinessUnitFulfillmentTag
                businessUnitFulfillments={businessUnitFulfillmentMethods}
                showEditButton={false}
              />
            </LinkToDetails>
          );
        },
      },
      {
        id: 'actions',
        header: () => <div />,
        accessorKey: 'id',
        enableSorting: false,
        cell: (data) => {
          const userId = data.cell.getValue<string>();
          const actionButtonRef = useRef<ActionButtonRefProps>(null);
          const t = useTranslate();

          return (
            <ActionButton
              ref={actionButtonRef}
              actions={[
                {
                  label: t('actions.edit'),
                  name: 'edit',
                  onAction: () => navigateToEdit({ id: userId }),
                },
              ]}
            />
          );
        },
      },
    ],

    create: {
      render: (helpers) => {
        const navigateTo = useNavigation();
        const notif = useNotification();
        const t = useTranslate();
        const { mutate: createOutlet } = useCreate();

        return (
          <FormBuilder
            title={t('outlet.create.add')}
            resourceName={resourceNames.outlet}
            onSubmit={async (data) => {
              createOutlet(
                {
                  resource: resourceNames.outlet,
                  values: data,
                },
                {
                  onSuccess: () => navigateTo.list(resourceNames.outlet),
                }
              );
            }}
            items={() => [
              {
                name: 'name',
                title: t('outlet.form.name'),
                type: 'text',
                required: true,
              },
              {
                name: 'contactNumber',
                title: t('outlet.form.contactNumber'),
                type: 'text',
                required: true,
              },
              {
                name: 'status',
                title: t('outlet.form.status'),
                type: 'select',
                options: Object.values(OutletStatus).map((status) => ({
                  label: status,
                  value: status,
                })),
                required: true,
              },
              {
                name: 'onlineOrdering',
                title: t('outlet.form.onlineOrdering'),
                type: 'switch',
                required: true,
              },
              {
                name: 'address.addressLineOne',
                title: t('outlet.form.addressLineOne'),
                type: 'text',
                required: true,
              },
            ]}
          />
        );
      },
    },

    edit: {
      render(helpers) {
        const { id } = useParams();
        const navigation = useNavigation();
        const t = useTranslate();
        const notif = useNotification();

        if (!id) return <SomethingWentWrong />;

        const { data, isLoading } = useOne({
          resource: resourceNames.outlet,
          id,
          metaData: {
            fields: outletFields,
          },
        });
        const mainData = data?.data as Outlet;

        const { mutate: updateOutlet } = useUpdate();
        if (isLoading) return <Loading />;

        return (
          <FormBuilder
            isUpdate
            resourceName={resourceNames.outlet}
            title={t('outlet.update.name', {}, 'Update Outlet')}
            onSubmit={async (data) => {
              updateOutlet(
                {
                  id,
                  resource: resourceNames.outlet,
                  values: {
                    name: data.name,
                    status: data.status ? OutletStatus.Active : OutletStatus.Inactive,
                    contactNumber: data.contactNumber,
                    // managerId: data.manager?.id,
                    onlineOrdering: data.onlineOrdering,
                    address: {
                      addressLineOne: data.addressLineOne,
                      addressLineTwo: data.addressLineTwo,
                      city: data.city,
                      province: data.province,
                      postalCode: data.postalCode,
                      country: data.country,
                      countryCode: data.countryCode,
                      latitude: parseFloat(data.latitude),
                      longitude: parseFloat(data.longitude),
                    },
                  },
                },
                {
                  onSuccess: () => {
                    notif?.open?.({
                      message: t(
                        'outlet.update.success',
                        {},
                        'Outlet has been updated successfully'
                      ),
                      type: 'success',
                    });
                    navigation.list(resourceNames.outlet);
                  },
                  onError: () => {
                    notif?.open?.({
                      message: t('outlet.update.error', {}, 'Failed to update outlet'),
                      type: 'error',
                    });
                  },
                }
              );
            }}
            items={() => [
              {
                name: 'name',
                title: t('outlet.form.name', {}, 'Name'),
                type: 'text',
                required: true,
                defaultValue: mainData?.name,
              },
              {
                name: 'contactNumber',
                title: t('outlet.form.contactNumber', {}, 'Contact Number'),
                type: 'text',
                required: true,
                defaultValue: mainData?.contactNumber,
              },
              {
                name: 'status',
                title: t('outlet.form.status', {}, 'Status'),
                type: 'switch',
                defaultValue: mainData?.status === OutletStatus.Active,
              },
              // NOTE: Manager will be update separately out of this form
              // {
              //   name: 'managerId',
              //   title: t('outlet.form.manager', {}, 'Manager'),
              //   type: 'data-selection',
              //   required: true,
              //   defaultValue: mainData?.manager,
              //   dataSelectionProps: {
              //     selectionComponent: AdministratorSelection,
              //     selectionProps: {
              //       initialValue: mainData?.manager,
              //     },
              //   },
              // },
              {
                name: 'onlineOrdering',
                title: t('outlet.form.onlineOrdering', {}, 'Online Ordering'),
                type: 'switch',
                defaultValue: mainData?.onlineOrdering,
              },
              // Address Fields
              {
                name: 'addressLineOne',
                title: t('outlet.form.addressLineOne', {}, 'Address Line 1'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.addressLineOne,
              },
              {
                name: 'addressLineTwo',
                title: t('outlet.form.addressLineTwo', {}, 'Address Line 2'),
                type: 'text',
                defaultValue: mainData?.address?.addressLineTwo,
              },
              {
                name: 'city',
                title: t('outlet.form.city', {}, 'City'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.city,
              },
              {
                name: 'province',
                title: t('outlet.form.province', {}, 'Province'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.province,
              },
              {
                name: 'postalCode',
                title: t('outlet.form.postalCode', {}, 'Postal Code'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.postalCode,
              },
              // TODO: Use country selector and auto map the country code from backend
              {
                name: 'country',
                title: t('outlet.form.country', {}, 'Country'),
                type: 'text',
                required: true,
                props: {
                  disabled: true,
                },
                defaultValue: mainData?.address?.country,
              },
              {
                name: 'countryCode',
                title: t('outlet.form.countryCode', {}, 'Country Code'),
                type: 'text',
                required: true,
                props: {
                  disabled: true,
                },
                defaultValue: mainData?.address?.countryCode,
              },
              // TODO: Replace with Google Maps integration
              {
                name: 'latitude',
                title: t('outlet.form.latitude', {}, 'Latitude'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.latitude,
              },
              {
                name: 'longitude',
                title: t('outlet.form.longitude', {}, 'Longitude'),
                type: 'text',
                required: true,
                defaultValue: mainData?.address?.longitude,
              },
            ]}
          />
        );
      },
    },

    show: defineShowPage({
      component: (props) => {
        return <OutletDetailPage resourceId={props?.resourceId} />;
      },
    }),
  });
};
