import { Tag } from '@scalingworks/react-admin-ui';
import { GraphQLClient } from 'graphql-request';
import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import kebabCase from 'lodash/kebabCase';
import { Asset, FacetValue, Maybe, getSdk } from '~/api';
import {
  CreateProductOptionGroupMutation,
  CreateProductOptionMutation,
  CreateProductVariantInput,
  LanguageCode,
  ProductOption,
  ProductOptionGroup,
} from '~/api';
import {
  ProductOptionFormInput,
  ProductOptionGroupFormInput,
  ProductVariantFormInput,
} from '~/components/VariantForm/props';
import { GQLClient } from '~/config/gql-client';
import { useTranslate } from '@refinedev/core';

export const createVariantInput = async ({
  innerVariants,
  productId,
  optionResCombine,
}: {
  innerVariants: ProductVariantFormInput[];
  productId: string;
  optionResCombine: ProductOptionFormInput[];
}): Promise<CreateProductVariantInput[]> => {
  const gqlClient = GQLClient?.getInstance();
  for (const item1 of innerVariants) {
    const options = item1.options || [];

    for (const item2 of optionResCombine) {
      if (options.includes(item2.name)) {
        item1.optionIds = item1.optionIds || [];
        item1.optionIds.push(item2.id || '');
      }
    }
  }

  return await Promise.all(
    innerVariants?.map(async (subItem) => {
      const variantSlugName = kebabCase(subItem?.name);

      let variantAssetsUrl: any;
      if (!isEmpty(subItem?.image) && typeof subItem?.image?.[0] !== 'string') {
        variantAssetsUrl = await getSdk(gqlClient)?.createAssets({
          input:
            subItem?.image?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }) || [],
        });
      }

      return {
        optionIds: subItem?.optionIds,
        productId: productId,
        sku: variantSlugName,
        price: +parseFloat(`${subItem?.price}`).toFixed(2),
        stockOnHand: parseInt(`${subItem?.stockOnHand}`),
        assetIds: !isEmpty(variantAssetsUrl) ? variantAssetsUrl?.createAssets?.[0]?.id : undefined,
        translations: [
          {
            languageCode: LanguageCode?.En,
            name: subItem?.name,
          },
        ],
      } as CreateProductVariantInput;
    })
  );
};

export const loopCreateOptionGroup = async ({
  value: optionGroups,
  client,
  productId,
  getSdk,
}: {
  value: ProductOptionGroupFormInput[];
  client: GraphQLClient;
  productId: string;
  getSdk: any;
}) => {
  let optionResCombine: any[] = [];
  let createProductOptionGroupRes: CreateProductOptionGroupMutation | undefined = undefined;
  for (var item of optionGroups) {
    createProductOptionGroupRes = (await getSdk(client).createProductOptionGroup({
      input: {
        code: item?.name,
        translations: [
          {
            languageCode: LanguageCode?.En,
            name: item?.name,
          },
        ],
        options: item?.options?.map((subItem) => {
          return {
            code: subItem?.name,
            translations: [
              {
                languageCode: LanguageCode?.En,
                name: subItem?.name,
              },
            ],
          };
        }),
      },
    })) as CreateProductOptionGroupMutation;

    optionResCombine = [
      ...optionResCombine,
      ...createProductOptionGroupRes?.createProductOptionGroup?.options,
    ];

    const addOptionGroupToProductRes = await getSdk(client).addOptionGroupToProduct({
      optionGroupId: createProductOptionGroupRes?.createProductOptionGroup?.id,
      productId,
    });
  }
  return {
    optionResCombine,
    response: createProductOptionGroupRes?.createProductOptionGroup,
  };
};

export const getDifferenceById = (arr1: any[], arr2: any[]) => {
  const arr2Ids = arr2?.map((item) => item.id);
  return arr1?.filter((item) => !arr2Ids?.includes(item.id));
};

export const getOptionsDifference = (
  updatedRemovedOptions: ProductOptionGroupFormInput[],
  mainVariants: ProductOptionGroup[]
) => {
  let difference: ProductOption[] = [];

  // Iterate over each object in mainVariants
  mainVariants.forEach((mainVariant) => {
    const mainOptions = mainVariant.options;
    const exists = updatedRemovedOptions.some((option) => option.id === mainVariant.id);
    if (!exists) {
      difference.push(...mainOptions);
    }
    // Find the corresponding object in updatedRemovedOptions
    const updatedVariant = updatedRemovedOptions.find(
      (updatedVariant) => updatedVariant.id === mainVariant.id
    );

    if (updatedVariant) {
      const updatedOptions = updatedVariant.options;

      // Find the options present in mainOptions but not in updatedOptions
      const variantDifference = mainOptions.filter(
        (mainOption) => !updatedOptions.some((updatedOption) => updatedOption.id === mainOption.id)
      );

      // Add the difference to the final result
      difference.push(...variantDifference);
    }
  });

  return difference;
};

export const mergeOptions = ({
  optionGroupsWithId,
  optionResponse,
}: {
  optionGroupsWithId: ProductOptionGroupFormInput[];
  optionResponse: CreateProductOptionMutation['createProductOption'] & {
    parentId?: string;
  };
}) => {
  // Find the option group with matching parentId
  const parentOptionGroup = optionGroupsWithId.find(
    (group) => group.id === optionResponse.parentId
  );

  if (parentOptionGroup) {
    // Check if the option already exists in the options array
    const existingOption = parentOptionGroup.options.find(
      (option) => option.name === optionResponse.name
    );

    if (existingOption) {
      // Merge the existing option with the new optionResponse
      existingOption.id = optionResponse.id;
      // You can add more properties to merge here if needed
    } else {
      // Add the option to the options array
      parentOptionGroup.options.push({
        name: optionResponse.name,
        id: optionResponse.id,
      });
    }
  }
};

export const renderStatusTag = (isEnable: boolean) => {
  const color = isEnable ? 'success' : 'default';
  const t = useTranslate();

  return (
    <Tag color={color}>{isEnable ? t('product.status.available') : t('product.status.hidden')}</Tag>
  );
};

export const formatBackendPrice = ({ isDivide, price }: { isDivide?: boolean; price: number }) => {
  // Using round even for * 100 is avoid 4.9 value can be 490.00000000000006 after * 100
  return isDivide ? price / 100 : Math.round(price * 100);
};

export type ProductImageSrcParams = {
  prodAssets?: Asset[];
  prodFeatured?: Asset | Maybe<Asset>;
  variantAssets?: Asset[];
  variantFeatured?: Asset | Maybe<Asset>;
};

/**
 * @description
 * Extracts the img source according to fallback flow:
 * VariantFeatured -> head(variantAssets) -> ProductFeatured -> head(productAssets)
 * @returns image source string or empty string
 */
export const getProductImageSrc = (params: ProductImageSrcParams): string => {
  const { prodAssets = [], prodFeatured, variantAssets = [], variantFeatured } = params;

  const productImg = prodFeatured?.source || head(prodAssets)?.source || '';
  const variantImg = variantFeatured?.source || head(variantAssets)?.source || '';

  return variantImg || productImg;
};

/**
 * @description
 * facetValues `[{id: '1'},{id: '2'},{id: '3'},{id: '4'}]`
 * modifierGroupSorting `['2','3','1']`
 * result: `[{id: '2'},{id: '3'},{id: '1'},{id: '4'}]`
 */
export const sortProductModifierGroup = (
  facetValues: FacetValue[],
  modifierGroupSorting: string[]
) => {
  return [
    // Sort facetValues that is IN sorting arr
    ...facetValues
      .filter((facet) => modifierGroupSorting.includes(facet.id))
      .sort((a, b) => modifierGroupSorting.indexOf(a.id) - modifierGroupSorting.indexOf(b.id)),
    // Append the remaining facetValues NOT IN sorting arr
    ...facetValues.filter((facet) => !modifierGroupSorting.includes(facet.id)),
  ];
};
