import camelCase from 'lodash/camelCase';
import { NumberField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const FormNumberField = ({ input: { item, register, watch, setValue } }: InputProps) => {
  const { title, name, required, placeholder, prefix, props, defaultValue, suffix } = item;
  const formName = camelCase(name || title);

  const watchValue = watch(formName);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(formName, defaultValue);
    }
  }, [defaultValue]);

  return (
    <NumberField
      {...props}
      useFormatNumber={false}
      required={required}
      formattedToThousand={false}
      prefix={prefix}
      suffix={suffix}
      defaultValue={defaultValue}
      value={props?.value || watchValue}
      style={
        prefix && {
          textAlign: 'end',
        }
      }
      label={title}
      layout="responsive"
      placeholder={placeholder}
      {...(register(formName, { required }) as any)}
    />
  );
};
