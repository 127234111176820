import { IconButton } from '@scalingworks/react-admin-ui';
import React from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { Props } from './props';

export const EditIconButton: React.FC<Props> = (props) => {
  return (
    <IconButton {...props}>
      <FiEdit3 className="!text-success-300" size={25} />
    </IconButton>
  );
};
