import { useTranslate, useUpdate } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { useState, useEffect } from 'react';
import { OutletDevice } from '~/api';
import { ModalWrapper } from '~/components';
import { TextInput } from '@scalingworks/react-admin-ui';
import { resourceNames } from '~/resources/resource-names';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  deviceId?: string;
  currentName?: string;
  onSuccess?: () => void;
};

export const EditDeviceModal: React.FC<Props> = ({
  open,
  setOpen,
  title,
  deviceId,
  currentName,
  onSuccess,
}) => {
  const t = useTranslate();
  const { mutate: update, isLoading } = useUpdate();
  const [deviceName, setDeviceName] = useState(currentName || '');

  useEffect(() => {
    setDeviceName(currentName || '');
  }, [currentName]);

  const handleSubmit = () => {
    if (!deviceName.trim()) return;

    update(
      {
        resource: resourceNames.outletDevice,
        id: deviceId!,
        values: {
          name: deviceName,
        },
        metaData: {
          operation: 'updateOutletDevice',
          fields: ['id'] as ResourceField<OutletDevice>[],
        },
      },
      {
        onSuccess: () => {
          setOpen(false);
          onSuccess?.();
        },
      }
    );
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={title}
      contentClassName="w-[500px]"
      onConfirm={handleSubmit}
      loading={isLoading}
    >
      <div className="p-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {t('outlet.devices.form.name', {}, 'Device Name')}
          </label>
          <TextInput
            value={deviceName}
            onValue={(value) => setDeviceName(value)}
            placeholder={t('outlet.devices.form.nameRequired', {}, 'Please enter device name')}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
