import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover, Button } from '@scalingworks/react-admin-ui';
import { BsChevronDown } from 'react-icons/bs';
import { Props, RefProps } from './props';

export const ActionButton = forwardRef<RefProps, Props>((props, ref) => {
  const { actions, customTitle, renderTrigger: renderCustomTrigger } = props;

  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    get setOpen() {
      return setOpen;
    },
  }));

  if (!actions || actions.length < 1) return null;
  const renderTrigger = () => {
    if (renderCustomTrigger) return renderCustomTrigger();

    if (customTitle) {
      return (
        <Button size="sm" className="text-md whitespace-nowrap border-primary-500">
          <div className="flex flex-row items-center justify-center space-x-2">
            <span className="text-primary-500">{customTitle}</span>
            <BsChevronDown color="#8E24AA" />
          </div>
        </Button>
      );
    }
    return (
      <button className="text-sm whitespace-nowrap inline-flex items-center bg-gray-50 p-2 rounded-md">
        •••
      </button>
    );
  };
  return (
    <span className="flex justify-end">
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>{renderTrigger()}</Popover.Trigger>
        <Popover.Portal>
          <Popover.Content align="end" sideOffset={20} style={{ minWidth: 160 }}>
            <span className="flex flex-col justify-center items-start">
              {actions.map((action) => {
                const wrapperClassname = `text-black px-4 py-3 w-full flex flex-row justify-center hover:bg-primary-50 rounded-md ${action.buttonClassName}`;
                if (action?.render)
                  return (
                    <div key={action.name} className={wrapperClassname}>
                      {action?.render(action.onAction)}
                    </div>
                  );
                return (
                  <button
                    key={action.name}
                    className={wrapperClassname}
                    type="button"
                    onClick={action.onAction}
                  >
                    {action.label}
                  </button>
                );
              })}
            </span>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </span>
  );
});

export default ActionButton;
export type ActionButtonProps = Props;
export type ActionButtonRefProps = RefProps;
