import React, { useEffect } from 'react';
import { BusinessUnit, BusinessUnitFulfillmentMethods, Channel } from '~/api';
import { Button, Checkbox, CheckboxGroup } from '@scalingworks/react-admin-ui';
import { BusinessUnitTranslation } from '~/config/constant';
import { useTranslate, useNotification } from '@refinedev/core';
import { ModalWrapper } from '~/components';
import { toCamelCaseWord } from '~/resources/helpers';
import { useOutletApiHooks } from '~/resources/outlet/hooks';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  channel?: Channel | null;
  initialFulfillments: BusinessUnitFulfillmentMethods[];
  onConfirm?: (fulfillmentChannelIds: string[], grouped: BusinessUnitFulfillmentMethods[]) => void;
  isLoading?: boolean;
};

export const FulfillmentChannelModal: React.FC<Props> = ({
  open,
  setOpen,
  title,
  channel,
  initialFulfillments,
  onConfirm,
  isLoading,
}) => {
  const t = useTranslate();
  const [selected, setSelected] = React.useState<Record<string, string[]>>({});

  // Initialize or reset selected state when modal opens or currentFulfillments change
  useEffect(() => {
    if (initialFulfillments?.length) {
      const initialSelected = initialFulfillments.reduce(
        (acc, { businessUnit, fulfillmentChannels }) => {
          acc[businessUnit] = fulfillmentChannels.map((fc) => fc.code);
          return acc;
        },
        {} as Record<string, string[]>
      );
      setSelected(initialSelected);
    }
  }, [initialFulfillments, open]);

  const availableOptions = React.useMemo(() => {
    if (!channel?.businessUnitFulfillmentMethods) return [];

    // NOTE: channel already has fulfillmentChannels filtered by disabled
    return channel.businessUnitFulfillmentMethods
      .map(({ businessUnit, fulfillmentChannels }) => ({
        businessUnit,
        fulfillmentChannels: fulfillmentChannels,
      }))
      .filter(({ fulfillmentChannels }) => fulfillmentChannels.length > 0);
  }, [channel]);

  const handleConfirm = async () => {
    const fulfillments = Object.entries(selected)
      .filter(([_, codes]) => codes.length > 0)
      .map(([businessUnit, channelCodes]) => ({
        businessUnit: businessUnit as BusinessUnit,
        fulfillmentChannels: channelCodes.map(
          (code) =>
            channel!
              .businessUnitFulfillmentMethods!.find((bm) => bm.businessUnit === businessUnit)!
              .fulfillmentChannels.find((fc) => fc.code === code)!
        ),
      }));

    const fulfillmentChannelIds = fulfillments.flatMap((fulfillment) =>
      fulfillment.fulfillmentChannels.map((fc) => fc.id)
    );

    await onConfirm?.(fulfillmentChannelIds, fulfillments);
  };

  return (
    <ModalWrapper
      open={open}
      setOpen={setOpen}
      title={title}
      onConfirm={handleConfirm}
      contentClassName="w-[500px]"
      loading={isLoading}
    >
      <div className="flex flex-col space-y-6 p-4">
        <p className="text-gray-500 text-sm">
          {t(
            'outlet.fulfillment.description',
            {},
            'Select available fulfillment channels for this outlet'
          )}
        </p>
        {availableOptions.map(({ businessUnit, fulfillmentChannels }) => (
          <div key={businessUnit} className="space-y-3 p-4 bg-gray-50 rounded-lg">
            <h3 className="font-medium text-lg text-gray-700">
              {BusinessUnitTranslation[businessUnit as BusinessUnit]}
            </h3>
            <CheckboxGroup
              id={`fulfillment-${businessUnit.toLowerCase()}`}
              value={selected[businessUnit] || []}
              onValue={(values: string[]) => {
                setSelected((prev) => ({
                  ...prev,
                  [businessUnit]: values,
                }));
              }}
              options={fulfillmentChannels.map((fc) => ({
                label: t(
                  `outlet.fulfillmentMethod.${fc.code.toLowerCase()}`,
                  {},
                  toCamelCaseWord(fc.code)
                ),
                value: fc.code,
              }))}
            />
          </div>
        ))}
      </div>
    </ModalWrapper>
  );
};
