import camelCase from 'lodash/camelCase';
import { DayField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { useEffect } from 'react';

export const FormDatePicker = ({ input: { item, register, setValue, watch } }: InputProps) => {
  const { title, name, required, placeholder, props, defaultValue } = item;
  const formName = camelCase(name || title);
  const value = watch(formName);

  useEffect(() => {
    if (defaultValue && !value) {
      setValue(formName, new Date(defaultValue as Date).toISOString());
    }
  }, [defaultValue, value]);

  return (
    <DayField
      required={required}
      {...props}
      label={title}
      placeholder={placeholder}
      value={watch(formName) ? new Date(watch(formName)) : undefined}
      onValue={(date) => setValue(formName, date)}
      {...(register(formName, { required }) as any)}
    />
  );
};
