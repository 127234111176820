import { GQLClient } from '../config/gql-client';

export const fetchData = <TData, TVariables extends Record<string, unknown>>(
  query: string,
  variables?: TVariables,
  headers?: RequestInit['headers']
): (() => Promise<TData>) => {
  return async () => {
    const client = GQLClient.getInstance();
    const apiHeaders = {
      ...GQLClient.prepareApiHeaders(),
      ...headers
    };
    
    const response = await client.request<TData>(
      query,
      variables,
      apiHeaders
    );
    return response;
  };
}; 