import { useTranslate } from '@refinedev/core';
import { Tag } from '@scalingworks/react-admin-ui';

// TODO: just refactor into one component instead of each resource having one
export const StatusTag: React.FC<{ isActive: boolean; resource: string }> = (props) => {
  const { isActive, resource } = props;

  const color = isActive ? 'success' : 'default';
  const t = useTranslate();

  return (
    <Tag color={color}>
      {t(`${resource}.status.${isActive ? 'active' : 'inactive'}`).toUpperCase()}
    </Tag>
  );
};

/**
 * @deprecated use StatusTag instead
 */
export const renderStatusTag = (isActive: boolean, resource: string) => {
  return <StatusTag isActive={isActive} resource={resource} />;
};

export const IsPrivateTag: React.FC<{ isPrivate: boolean; resource: string }> = (props) => {
  const { isPrivate, resource } = props;

  const t = useTranslate();

  if (isPrivate === undefined) {
    return null;
  }

  return (
    <Tag color={!isPrivate ? 'success' : 'default'}>
      {t(`${resource}.${isPrivate ? 'hidden' : 'visible'}`).toUpperCase()}
    </Tag>
  );
};

export const renderTextWithAffixes = ({
  text,
  affixText,
  position,
}: {
  text: string;
  affixText?: string;
  position?: 'left' | 'right';
}) =>
  `${(position === 'left' && affixText) || ''}${text || ''}${
    (position === 'right' && affixText) || ''
  }`.trim();

export const renderTextWithPrefix = ({
  text,
  prefixText,
  prefixPosition = 'left',
}: {
  text: string;
  prefixText: string;
  prefixPosition?: 'left' | 'right';
}) => {
  const renderPrefixText = (
    <p
      style={{
        fontWeight: 'normal',
        fontSize: 10,
        marginBottom: 1,
        marginRight: prefixPosition === 'left' ? 5 : 0,
        marginLeft: prefixPosition === 'right' ? 5 : 0,
      }}
    >
      {prefixText}
    </p>
  );

  return (
    <p
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
      }}
    >
      {prefixPosition === 'left' && renderPrefixText}
      {`${text || '-'}`.trim()}
      {prefixPosition === 'right' && renderPrefixText}
    </p>
  );
};
