import React, { useContext, useState } from 'react';
import { PromoCode, PromoCodeStatus, PromoCodeType } from '~/api';
import { useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { ActionButton, TriggerConfirmModal, ViewBuilder, flexRow } from '~/components';
import { FiChevronDown } from 'react-icons/fi';
import { resourceNames } from '~/resources/resource-names';
import { numeralThousandFormat } from '~/config/helper';
import { IsPrivateTag, renderStatusTag, renderTextWithAffixes } from '../../helpers';
import { Props } from './props';
import { ViewConfigProps } from '~/components/ViewBuilder/props';
import { formatDateRange } from '~/resources/helpers';
import { ChannelContext } from '~/providers/Channel/context';
import { ExpiryConfigSummary } from '../form/OnRedeemConfigForm';

export const PromoShow: React.FC<Props> = (props) => {
  const { helpers } = props;

  const promoData = helpers?.queryResult?.data?.data as PromoCode;

  // ==================== HOOKS
  const { channel } = useContext(ChannelContext);
  const { mutate } = useDelete();
  const { edit, list } = useNavigation();
  const t = useTranslate();

  // ==================== STATES
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // ==================== VARIABLES
  const currency = channel?.defaultCurrencyCode;

  return (
    <ViewBuilder
      {...helpers}
      title={t('promoCodes.details.name')}
      items={[
        {
          cardTitle: t('common.general'),
          cardHeaderExtra: () => {
            return (
              <div>
                <ActionButton
                  customTitle={t('common.action')}
                  actions={[
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: async () => {
                        await edit(resourceNames?.promoCode, promoData?.id);
                      },
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: async () => {
                        await mutate({
                          resource: resourceNames?.promoCode,
                          id: promoData?.id,
                        });
                        list(resourceNames?.promoCode);
                      },
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={setShowDeleteConfirmation}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            );
          },
          config: [
            {
              title: t('promoCodes.form.name'),
              data: [promoData?.name],
              type: 'text',
            },
            {
              title: t('promoCodes.form.code'),
              data: [promoData?.code],
              type: 'text',
            },
            {
              title: t('promoCodes.form.image'),
              data: promoData?.imageUrls || [],
              type: 'image',
            },
            {
              title: t('promoCodes.form.name'),
              data: [promoData?.name],
              type: 'text',
            },
            {
              title: t('promoCodes.form.description'),
              data: [`${promoData?.description || '-'}`],
              type: 'text',
            },
            {
              title: t('promoCodes.form.code'),
              data: [promoData?.code],
              type: 'text',
            },
            {
              title: t('promoCodes.form.visible'),
              data: [
                promoData?.isPrivate ? t('promoCodes.form.hidden') : t('promoCodes.form.visible'),
              ],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      <IsPrivateTag
                        isPrivate={promoData?.isPrivate}
                        resource={resourceNames?.promoCode}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              title: t('promoCodes.form.status'),
              data: [promoData?.status],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      {renderStatusTag(
                        promoData?.status === PromoCodeStatus?.Active,
                        resourceNames.promoCode
                      )}
                    </div>
                  </div>
                );
              },
            },

            ...(promoData?.startAt && promoData?.endAt
              ? [
                  {
                    title: t('promoCodes.form.period'),
                    data: [
                      formatDateRange(new Date(promoData?.startAt), new Date(promoData?.endAt)),
                    ],
                    type: 'text',
                  } as ViewConfigProps,
                ]
              : []),
            {
              title: t('promoCodes.form.pointsCost'),
              data: [
                renderTextWithAffixes({
                  text: numeralThousandFormat(promoData?.pointCost),
                  affixText: ' PTS',
                  position: 'right',
                }),
              ],
              type: 'text',
            },
            {
              title: t('promoCodes.form.quantity'),
              data: [numeralThousandFormat(promoData?.quantity)],
              type: 'text',
            },
            {
              title: t('promoCodes.form.discountType'),
              data: [t(`promoCodes.type.${promoData?.type}`)],
              type: 'text',
            },
            {
              title: t('promoCodes.form.value'),
              data: [
                renderTextWithAffixes({
                  text: numeralThousandFormat(promoData?.value, true),
                  affixText: promoData?.type === PromoCodeType?.FixedValue ? currency + ' ' : '%',
                  position: promoData?.type === PromoCodeType?.FixedValue ? 'left' : 'right',
                }),
              ],
              type: 'text',
            },
            ...(promoData?.type === PromoCodeType?.DiscountPercentage
              ? [
                  {
                    title: 'Capped At',
                    data: [
                      `${currency} ${numeralThousandFormat(promoData?.cappedDiscount ?? 0, true)}`,
                    ],
                    type: 'text',
                  } as ViewConfigProps,
                ]
              : []),
            {
              title: t('promoCodes.form.minSpend'),
              data: [
                renderTextWithAffixes({
                  text: numeralThousandFormat(promoData?.minimumSpendAmount, true),
                  affixText: currency + ' ',
                  position: 'left',
                }),
              ],
              type: 'text',
            },
            {
              title: t('promoCodes.form.membershipTiers'),
              data: promoData?.membershipTiers?.map((subItem) => subItem?.name) as string[],
              type: 'text',
            },
            {
              title: t('promoCodes.form.redeemPerUserLimit'),
              data: [numeralThousandFormat(promoData?.redeemPerUserLimit || 0)],
              type: 'text',
            },
            {
              title: t('promoCodes.form.expiryValue'),
              data: [promoData?.onRedeemConfig],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      <ExpiryConfigSummary config={promoData?.onRedeemConfig || {}} readonly />
                    </div>
                  </div>
                );
              },
            },
            {
              title: t('promoCodes.form.criteria'),
              data: [`${promoData?.redeemCriteria || '-'}`],
              type: 'text',
            },
            {
              title: t('promoCodes.form.tnc'),
              data: [`${promoData?.termCondition || '-'}`],
              type: 'text',
            },
          ],
        },
      ]}
    />
  );
};
