import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Card } from '@scalingworks/react-admin-ui';
import { useForm } from '@refinedev/react-hook-form';
import { useNavigation, useTranslate } from '@refinedev/core';
import isEmpty from 'lodash/isEmpty';
import camelCase from 'lodash/camelCase';
import { FormTextField } from './TextField';
import { FormCheckBox } from './Checkbox';
import { FormNumberField } from './NumberField';
import { FormUploadInput, renderUploadInput } from './UploadInput';
import { FormRadioButtons } from './Radio';
import { FormMultiTextField } from './MultiTextField';
import { FormSwitchField } from './Switch';
import { FormDataSelection } from './DataSelection';
import { FormDatePicker } from './DatePicker';
import { FormTimePicker } from './TimePicker';
import { FormDateTextInput } from './DateText';
import { FormSelectField } from './Select';
import { FormPhoneField } from './PhoneField';
import { Props, RefProps } from './props';
import { ShowPageWrapper } from '../ShowPageWrapper';
import { resourceNames } from '~/resources/resource-names';

export const flexRow: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

export type FormBuilderRefProps = RefProps;

export const FormBuilder = forwardRef<RefProps, Props>((props, ref) => {
  const {
    title,
    items,
    onSubmit,
    isUpdate,
    resourceName,
    extra,
    keyToWatch,
    keyToHide,
    renderBody,
    disableSubmit,
    customForm,
    loading,
    showBackButton = true,
    showHeaderButton = true,
    showFooterButton = true,
  } = props;

  const useFormHook = customForm
    ? customForm
    : useForm<any>({
        refineCoreProps: {
          dataProviderName: undefined,
          resource: 'no-resource',
        },
        defaultValues: {},
        values: {},
      });
  const t = useTranslate();

  const { register, handleSubmit, setValue, watch, formState, getValues, control, reset } =
    useFormHook;
  const navigateTo = useNavigation();
  const [filteredKeyToWatch, setfilteredKeyToWatch] = useState<any[]>();

  useImperativeHandle(ref, () => ({
    get useFormHook() {
      return useFormHook;
    },
  }));

  //============== Force Re-render
  const [booleanRender, setBooleanRender] = useState(false);
  const forceRerender = () => {
    setBooleanRender(!booleanRender);
  };
  useEffect(() => {
    setfilteredKeyToWatch(
      keyToWatch?.flatMap((subItem) => watch(subItem))?.filter((subItem) => subItem) || []
    );
  }, [keyToWatch, watch, booleanRender]);

  const onPressSubmit = (data: any) => {
    onSubmit && onSubmit(data);
  };

  const renderItems = () => {
    if (!items) return;
    return items(useFormHook)?.map((subItem, index) => {
      if (
        !isEmpty(filteredKeyToWatch?.flatMap((subItem) => watch(subItem))) &&
        // TODO: Form builder is currently being update in another pr.
        // Will need to change the subItem?.title -> subItem?.name when the other pr is in main.
        keyToHide?.includes(camelCase(subItem?.title))
      )
        return;

      const inputVal = { input: { item: subItem, ...useFormHook } };
      const renderSwitch = () => {
        switch (subItem?.type) {
          case 'upload':
            return <FormUploadInput {...inputVal} />;
          case 'checkbox':
            return <FormCheckBox {...inputVal} />;
          case 'radio':
            return <FormRadioButtons {...inputVal} />;
          case 'number':
            return <FormNumberField {...inputVal} />;
          case 'multitext':
            return <FormMultiTextField {...inputVal} />;
          case 'switch':
            return <FormSwitchField {...inputVal} />;
          case 'select':
            return <FormSelectField {...inputVal} />;
          case 'phone':
            return <FormPhoneField {...inputVal} />;
          case 'date-text-input':
            return <FormDateTextInput {...inputVal} />;
          case 'date-picker':
            return <FormDatePicker {...inputVal} />;
          case 'time-picker':
            return <FormTimePicker {...inputVal} />;
          case 'data-selection':
            if (!subItem.dataSelectionProps) return null;
            return (
              <FormDataSelection
                input={inputVal.input}
                selectionComponent={subItem.dataSelectionProps.selectionComponent}
                selectionProps={subItem.dataSelectionProps.selectionProps}
              />
            );
          default:
            return <FormTextField {...inputVal} />;
        }
      };

      const rowStyling = index !== 0 ? 'mt-5' : '';

      if (subItem?.render) return subItem?.render(inputVal, { row: rowStyling });
      return <div className={rowStyling}>{renderSwitch()}</div>;
    });
  };

  const renderExtra = () => {
    return extra?.map((subItem, index) => {
      const registerKey = `${subItem?.key}`;
      register(registerKey);
      return (
        <Card className="p-5">
          {subItem?.render({
            registerKey,
            forceRerender,
            ...useFormHook,
          })}
        </Card>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit(onPressSubmit)}>
      <div className="max-w-screen-2xl mx-auto py-3 px-6">
        {/* Headers */}
        <ShowPageWrapper
          resourceName={resourceName}
          showBackButton={showBackButton}
          onBack={() => navigateTo.goBack()}
          title={title}
          extra={
            showHeaderButton && (
              <div style={{ ...flexRow }}>
                <Button size="sm" className="mr-2" onClick={() => navigateTo.goBack()}>
                  {t('actions.cancel')}
                </Button>
                <Button
                  variant="solid"
                  size="sm"
                  type="submit"
                  disabled={disableSubmit}
                  loading={loading}
                >
                  {isUpdate ? t('actions.update') : t('actions.create')}
                </Button>
              </div>
            )
          }
        >
          <div className="flex flex-col gap-3" onSubmit={handleSubmit(onPressSubmit)}>
            <Card className="p-5 bg-red-500!">
              {renderBody ? renderBody(useFormHook) : renderItems()}
            </Card>
            {!isEmpty(extra) && renderExtra()}
            {showFooterButton && (
              <Button variant="solid" type="submit" disabled={disableSubmit} loading={loading}>
                {isUpdate ? t('actions.update') : t('actions.create')}
              </Button>
            )}
          </div>
        </ShowPageWrapper>
      </div>
    </form>
  );
});
