import React, { ComponentType, useEffect } from 'react';
import { InputProps } from '../props';
import camelCase from 'lodash/camelCase';

type BaseProps = {
  selectionComponent: ComponentType<any>;
  selectionProps: Record<string, any>;
};

type Props = BaseProps & InputProps;

export const FormDataSelection = (props: Props) => {
  const { selectionComponent: Component, selectionProps, input } = props;
  const { item, register, setValue, watch } = input;
  const { title, name: fieldName = '', required, defaultValue } = item;
  const name = camelCase(fieldName || title);
  const registry = register(name, { required });

  useEffect(() => {
    if (!watch(name) && defaultValue) {
      setValue(name, defaultValue as string);
    }
  }, [watch(name), defaultValue]);

  return (
    <Component
      {...selectionProps}
      required={required}
      isField
      fieldLabel={title}
      onSelect={(value: string) => {
        selectionProps?.onSelect?.(value);
        setValue(name, value);
      }}
      {...registry}
    />
  );
};

export const renderDataSelection = (props: Props) => {
  return <FormDataSelection {...props} />;
};

export type RenderDataSelectionProps = BaseProps;
