import React, { useEffect, useState } from 'react';
import { useGraphQLClient } from '@scalingworks/refine-react-admin';
import { FormBuilder } from '~/components';
import { MembershipTier, PromoCodeType, PromoCodeStatus, getSdk } from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { FormItemProps } from '~/components/FormBuilder/props';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';
import { useGetMembershipTiersQuery } from '~/generated/hooks';
import { DaySelectorProps, TextFieldProps } from '@scalingworks/react-admin-ui';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import { OnRedeemConfigForm } from './OnRedeemConfigForm';
import dayjs from 'dayjs';

export const PromoCodeForm: React.FC<Props> = (props) => {
  const { onSubmit, promoCodeData } = props;
  const isUpdate = !isEmpty(promoCodeData);
  const t = useTranslate();

  const { data: membershipTiersData } = useGetMembershipTiersQuery();

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.promoCode}
        title={`${isUpdate ? t('promoCodes.update.update') : t('promoCodes.create.create')} ${
          isUpdate ? promoCodeData?.name : ''
        }`}
        isUpdate={isUpdate}
        items={({ register, setValue, watch }) => {
          const watchTypeField = watch('type');
          const watchStartDate = watch('startDate');
          const watchEndDate = watch('endDate');

          // Define all form fields in a single array
          const formFields: FormItemProps[] = [
            {
              name: 'images',
              title: t('promoCodes.form.image'),
              type: 'upload',
              placeholder: t('product.placeholder.images'),
              hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
              fileType: 'image',
              defaultValue: promoCodeData?.imageUrls,
            },
            {
              name: 'name',
              title: t('promoCodes.form.name'),
              placeholder: t('promoCodes.placeholder.name'),
              required: true,
              defaultValue: promoCodeData?.name,
            },
            {
              name: 'description',
              title: t('promoCodes.form.description'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.description'),
              required: true,
              defaultValue: promoCodeData?.description,
            },
            {
              name: 'code',
              title: t('promoCodes.form.code'),
              placeholder: t('promoCodes.placeholder.code'),
              required: true,
              defaultValue: promoCodeData?.code,
              props: {
                disabled: isUpdate,
                onValue: (value) => setValue('code', value.toUpperCase()),
              } as TextFieldProps,
            },
            {
              name: 'visible',
              title: t('promoCodes.form.visible'),
              type: 'switch',
              defaultValue: !promoCodeData?.isPrivate,
            },
            ...(isUpdate
              ? [
                  {
                    name: 'status',
                    title: t('promoCodes.form.status'),
                    type: 'select',
                    options: Object.values(PromoCodeStatus).map((value) => ({
                      label: toCamelCaseWord(value),
                      value,
                    })),
                    defaultValue: promoCodeData?.status,
                  } as FormItemProps,
                ]
              : []),
            {
              name: 'pointCost',
              title: t('promoCodes.form.point'),
              type: 'number',
              placeholder: t('promoCodes.placeholder.pointCost'),
              required: true,
              suffix: 'PTS',
              defaultValue: promoCodeData?.pointCost ?? 0,
            },
            {
              name: 'quantity',
              title: t('promoCodes.form.quantity'),
              type: 'number',
              placeholder: t('promoCodes.placeholder.quantity'),
              required: true,
              defaultValue: promoCodeData?.quantity ?? 0,
            },
            {
              name: 'type',
              title: t('promoCodes.form.discountType'),
              type: 'select',
              options: [
                ...Object.keys(PromoCodeType).map((key) => ({
                  label: toCamelCaseWord(key),
                  // @ts-ignore
                  value: PromoCodeType[key],
                })),
              ],
              props: {
                disabled: isUpdate,
              },
              placeholder: t('promoCodes.placeholder.type'),
              required: true,
              defaultValue: promoCodeData?.type ?? PromoCodeType.DiscountPercentage,
            },
            {
              name: 'value',
              title: t('promoCodes.form.value'),
              placeholder: '0.00',
              type: 'number',
              required: true,
              prefix: watchTypeField === PromoCodeType.FixedValue ? 'MYR' : undefined,
              suffix: watchTypeField === PromoCodeType.DiscountPercentage ? '%' : undefined,
              defaultValue: promoCodeData?.value,
            },
            // Conditionally include cappedAt field for DiscountPercentage type
            ...(watchTypeField === PromoCodeType.DiscountPercentage
              ? [
                  {
                    name: 'cappedAt',
                    title: t('promoCodes.form.cap'),
                    type: 'number',
                    prefix: 'MYR',
                    placeholder: '0.00',
                    required: true,
                    defaultValue: promoCodeData?.cappedDiscount,
                  } as FormItemProps,
                ]
              : []),
            {
              name: 'minimumSpending',
              title: t('promoCodes.form.minSpend'),
              placeholder: '0.00',
              type: 'number',
              required: true,
              prefix: 'MYR',
              defaultValue: promoCodeData?.minimumSpendAmount,
            },
            {
              name: 'membershipTiers',
              title: t('promoCodes.form.membershipTiers'),
              type: 'select',
              multiSelect: true,
              options: membershipTiersData?.getMembershipTiers?.items?.map((subItem) => ({
                label: subItem?.name,
                value: subItem?.id,
              })),
              placeholder: t('promoCodes.placeholder.membershipTiers'),
              defaultValue: promoCodeData?.membershipTiers?.map((subItem) => subItem?.id),
              required: true,
            },
            {
              name: 'startDate',
              title: t('promoCodes.form.start'),
              type: 'date-picker',
              required: true,
              placeholder: t('promoCodes.placeholder.start'),
              defaultValue: promoCodeData?.startAt,
              props: {
                max: watchEndDate ? new Date(watchEndDate) : undefined,
              } as DaySelectorProps,
            },
            {
              name: 'endDate',
              title: t('promoCodes.form.end'),
              type: 'date-picker',
              placeholder: t('promoCodes.placeholder.end'),
              required: true,
              defaultValue: promoCodeData?.endAt,
              props: {
                min: watchStartDate ? new Date(watchStartDate) : undefined,
                disabled: !watchStartDate,
                parseValue: (val) => (val ? dayjs(val).endOf('day').toDate() : undefined),
              } as DaySelectorProps,
            },
            // For Kedai
            // {
            //   name: 'bigTitle',
            //   title: t('promoCodes.form.bigTitle'),
            //   placeholder: t('promoCodes.placeholder.bigTitle'),
            //   defaultValue: promoCodeData?.bigTitle,
            // },
            // {
            //   name: 'smallSubTitle',
            //   title: t('promoCodes.form.smallSubTitle'),
            //   placeholder: t('promoCodes.placeholder.smallSubTitle'),
            //   defaultValue: promoCodeData?.smallSubTitle,
            // },
            {
              name: 'redeemPerUserLimit',
              title: t('promoCodes.form.redeemPerUserLimit'),
              type: 'number',
              placeholder: t('promoCodes.placeholder.redeemPerUserLimit'),
              defaultValue: promoCodeData?.redeemPerUserLimit,
            },
            {
              name: 'onRedeemConfig',
              title: t('promoCodes.form.expiryValue'),
              render: ({ input }, { row }) => {
                const watchOnRedeemConfig =
                  watch('onRedeemConfig') || promoCodeData?.onRedeemConfig;
                return (
                  <OnRedeemConfigForm
                    config={watchOnRedeemConfig}
                    onChange={(val) => {
                      input.setValue('onRedeemConfig', val);
                    }}
                    label={t('promoCodes.form.expiryValue')}
                    rowClassName={row}
                  />
                );
              },
              defaultValue: promoCodeData?.onRedeemConfig,
            },
            {
              name: 'redeemCriteria',
              title: t('promoCodes.form.criteria'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.criteria'),
              required: true,
              defaultValue: promoCodeData?.redeemCriteria,
            },
            {
              name: 'termsConditions',
              title: t('promoCodes.form.tnc'),
              multiline: true,
              placeholder: t('promoCodes.placeholder.tnc'),
              required: true,
              defaultValue: promoCodeData?.termCondition,
            },
          ];

          return formFields;
        }}
        onSubmit={(data) => {
          onSubmit?.(data);
        }}
      />
    </div>
  );
};
