import { useApiUrl, useCustom, useCustomMutation } from '@refinedev/core';
import { MutationOptions } from '@tanstack/react-query';
import { UpdateProductCustomFieldsInput } from '~/api';

export const useProductApiHooks = () => {
  // =========================== API
  const apiUrl = useApiUrl();

  // =========================== EVENTS
  const useUpdateProductFulfillmentChannels = (
    mutationOptions?: MutationOptions<any, any, any, any>
  ) => {
    const { mutate, isLoading } = useCustomMutation({
      mutationOptions,
    });

    const updateProductFulfillmentChannels = async (values: {
      productId: string;
      fulfillmentChannelIds: string[];
    }) => {
      return mutate({
        url: apiUrl,
        method: 'post',
        values: {},
        meta: {
          operation: 'updateProduct',
          fields: ['id'],
          variables: {
            input: {
              type: 'UpdateProductInput!',
              value: {
                id: values.productId,
                customFields: {
                  fulfillChannelsIds: values.fulfillmentChannelIds,
                } as UpdateProductCustomFieldsInput,
              },
            },
          },
        },
        successNotification: {
          type: 'success',
          message: 'Fulfillment channels updated successfully',
        },
        errorNotification: {
          type: 'error',
          message: 'Failed to update fulfillment channels',
        },
      });
    };

    return {
      updateProductFulfillmentChannels,
      isLoading,
    };
  };

  return {
    useUpdateProductFulfillmentChannels,
  };
};
