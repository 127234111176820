import { createResourceListingPage } from '@scalingworks/refine-react-admin';
import React from 'react';
import { FulfillmentMethodType, Order } from '~/api';
import { dateFormatter, parsePrice, toCamelCaseWord } from '~/resources/helpers';
import { resourceNames } from '~/resources/resource-names';
import { Props } from './props';
import { Loading } from '~/components';
import { getOrderStatusTagColor, getOrderStatus } from '~/resources/helpers/order';
import { Tag } from '@scalingworks/react-admin-ui';
import { OrderStatus } from '~/config/types';
import { FullDateTimeFormat } from '~/config/constant';
import head from 'lodash/head';

export const CustomerOrder: React.FC<Props> = (props) => {
  const { loading, customerId, customerOrderIds = [] } = props;

  // ==================== HOOKS
  const listing = createResourceListingPage<Order>({
    title: '',
    showTitle: false,
    customHelmet: 'Customer',
    resourceName: resourceNames.order,
    fields: [
      'id',
      'code',
      'totalQuantity',
      'createdAt',
      'subTotal',
      'totalWithTax',
      'currencyCode',
      'state',
      'orderPlacedAt',
      {
        fulfillments: [
          'method',
          {
            additionalProperties: ['courier', 'driverContact', 'driverName', 'pickUpTime'],
          },
        ],
      },
      { dineInMetadata: ['adultPax', 'kidPax', 'tableCodeWithPrefix'] },
      { customFields: ['shippingMethodCode'] },
    ],
    allowCreate: false,
    // TODO: allow search true after backend patched the api
    allowSearch: false,
    searchConfig: { placeholder: (t) => 'Search by Order ID' },
    filterConfig: { alwaysExpanded: true },
    defaultPageSize: 5,
    defaultSorter: [{ field: 'orderPlacedAt', order: 'desc' }],
    // TODO: allow tab & filter controls after backend patched the api
    tabs: {
      options: [
        {
          filterValue: { field: 'id', operator: 'in', value: customerOrderIds },
          label: () => 'All',
        },
      ],
    },
    // tabs: {
    //   options: [
    //     // @ts-ignore
    //     {
    //       filterValue: { field: 'id', operator: 'in', value: customerOrderIds },
    //       label: () => 'All',
    //     },
    //     {
    //       filterValue: [
    //         { field: 'id', operator: 'in', value: customerOrderIds },
    //         // @ts-ignore
    //         { field: 'fulfillmentMethod', operator: 'eq', value: FulfillmentMethodType.Delivery },
    //       ],
    //       label: () => 'Delivery',
    //     },
    //     {
    //       filterValue: [
    //         { field: 'id', operator: 'in', value: customerOrderIds },
    //         // @ts-ignore
    //         { field: 'fulfillmentMethod', operator: 'eq', value: FulfillmentMethodType.Pickup },
    //       ],
    //       label: () => 'Pick Up',
    //     },
    //     {
    //       filterValue: [
    //         { field: 'id', operator: 'in', value: customerOrderIds },
    //         // @ts-ignore
    //         { field: 'fulfillmentMethod', operator: 'eq', value: FulfillmentMethodType.DineIn },
    //       ],
    //       label: () => 'Dine in',
    //     },
    //   ],
    // },
    // filterControls: {
    //   createdAt: {
    //     type: 'daterange',
    //     config: {
    //       label: 'Order Date',
    //     },
    //   },
    // },
    columns: ({ LinkToDetails, t }) => [
      {
        id: 'code',
        header: 'Code',
        cell: (data) => {
          const { id, code } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <span>#{code}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'deliveryMethod',
        header: t('order.columns.method', { ns: 'common', fallback: 'Method' }),
        cell: (data) => {
          const { id, customFields } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              {toCamelCaseWord(customFields?.shippingMethodCode ?? '-')}
            </LinkToDetails>
          );
        },
      },
      {
        id: 'totalQuantity',
        header: 'Items',
        cell: (data) => {
          const { id, totalQuantity } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <span>{totalQuantity}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'orderPlacedAt',
        header: 'Order Date',
        cell: (data) => {
          const { id, orderPlacedAt } = data.row.original;
          const display = dateFormatter(orderPlacedAt, FullDateTimeFormat);

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <span>{display}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'orderState',
        header: 'Status',
        cell: (data) => {
          const { id, state } = data.row.original;
          const simplified = getOrderStatus(state) as OrderStatus;

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <Tag color={getOrderStatusTagColor(simplified)}>
                  {t(`order.status.${simplified.toLowerCase()}`, {
                    fallback: simplified,
                    ns: 'common',
                  }).toUpperCase()}
                </Tag>
              </div>
            </LinkToDetails>
          );
        },
      },
      {
        id: 'totalWithTax',
        header: 'Amount (RM)',
        cell: (data) => {
          const { id, totalWithTax, currencyCode = 'MYR' } = data.row.original;

          return (
            <LinkToDetails resourceId={id}>
              <div>
                <span>{totalWithTax ? <span>{parsePrice(totalWithTax)}</span> : '-'}</span>
              </div>
            </LinkToDetails>
          );
        },
      },
    ],
  });
  // ==================== VIEWS
  if (loading) return <Loading />;
  if (!customerId) return null;
  return listing();
};
