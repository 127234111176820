import routerProvider from '@refinedev/react-router-v6/legacy';
import {
  createRefineAdminApp,
  defineCustomPage,
  groupResources,
} from '@scalingworks/refine-react-admin';
import '@scalingworks/refine-react-admin/styles.css';
import ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css';
import { SlPresent } from 'react-icons/sl';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { BsGrid } from 'react-icons/bs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import logo from './assets/np-office-logo.png';
import { createAuthProvider, subscriberStorageKey } from './config/auth-provider';
import { LoginPage } from './config/Login/login-page';
import { createDataProvider } from './config/data-provider';
import { createAccessControlProvider } from './config/access-provider';
import { createAccessConfig } from './config/access-config';
import { ErrorPage } from './config/Error';
import { FiCalendar } from 'react-icons/fi';
import { MaintenancePage } from './config/Maintenance';
import { QuickActions } from './config/QuickActions';
import { AdminNotificationPage } from './config/AdminNotification';
import { path } from './config/constant';
import { NovuProvider } from '@novu/notification-center';
import { SellerContextProvider } from './providers';
import { SellerContext } from './providers/Seller/context';
import flatMap from 'lodash/flatMap';
import { Loading } from './components';
import { constructResources, getGroupNamesFromResource } from './config/construct-resource';
import { SidebarHeader } from './config/SidebarHeader';
import { ChannelContextProvider } from './providers/Channel';
import { ChannelContext } from './providers/Channel/context';
import { Channel, StockLocation } from './api';
import { useContext } from 'react';
import { dashboardResource } from './resources/dashboard/dashboard-resource';
import { orderResource } from './resources/order/order-resource';
import { PrinterContextProvider } from './providers/Printer';
import { MembershipImportPage } from './config/MembershipImport';
import { ResourceContext } from './resources/type';
import { InstantUpgradeImportPage } from './config/InstantUpgradeImport';

const authProvider = createAuthProvider();
const accessConfig = createAccessConfig();

const subscriberId = localStorage.getItem(subscriberStorageKey);

const pushNotification = () => {
  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {
      return;
    }
  );

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {
      return;
    }
  );
};

const createApp = (resourceConfigs: any, context: ResourceContext) => {
  return createRefineAdminApp({
    graphqlUrl: `${import.meta.env.VITE_API_BASE_URL}`,
    authProvider,
    dataProvider: (resourceMap, loader) => createDataProvider(resourceMap, loader),
    sidebarHeader: <SidebarHeader />,
    LoginPage: defineCustomPage({
      // TODO: pass kedai logo here
      render: () => <LoginPage />,
    }),
    design: {
      sidebarVariant: 'gray',
    },
    i18n: {
      translationLoadPath: '/locales/{{lng}}/common.json',
      preferredLanguage: 'en',
      appNamespace: 'common',
      languages: [
        {
          label: 'English',
          code: 'en',
        },
        // {
        //   label: 'Chinese',
        //   code: 'cn',
        // },
      ],
    },
    legacyRouterProvider: {
      ...routerProvider,
      routes: [
        ...(routerProvider.routes || []),
        { element: <MaintenancePage />, path: path.maintenance },
        { element: <AdminNotificationPage />, path: path.notification },
        { element: <MembershipImportPage />, path: path.membershipImport },
        // TODO: MB: This was for MB tenant, remove completely afterwards
        // { element: <InstantUpgradeImportPage />, path: path.instantUpgrade },
      ],
    },
    accessControlProvider: createAccessControlProvider(authProvider, accessConfig),
    accessConfig: createAccessConfig({
      allowedGroupName: getGroupNamesFromResource(resourceConfigs),
    }),
    resources: [
      // dashboardResource(channel),
      // TODO: make dashboard the first tab instead (inside construct resource)
      orderResource(context), // need to have a default resources before `constructResources` otherwise it'll fallback to "Welcome abroad" screen by refine
      ...flatMap(constructResources(resourceConfigs, context)),
    ],
    errorPage: <ErrorPage />,
    quickActions: <QuickActions />,
  });
};

// Loading for those required context provider
const ContextLoading: React.FC = () => {
  return (
    <section className="flex w-screen h-screen items-center justify-center">
      <Loading />
    </section>
  );
};

const AppLoader: React.FC = () => {
  const { loading: sellerLoading, getResourceConfig } = useContext(SellerContext);
  const { loading: channelLoading, channel, stockLocations } = useContext(ChannelContext);

  if (sellerLoading || channelLoading) {
    return <ContextLoading />;
  }

  const App = createApp(getResourceConfig(), { channel: channel || undefined, stockLocations });
  return <App />;
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <DndProvider backend={HTML5Backend}>
    <NovuProvider
      subscriberId={subscriberId!}
      socketUrl={import.meta.env.VITE_NOVU_SOCKET_URL}
      backendUrl={import.meta.env.VITE_NOVU_BACKEND_URL}
      applicationIdentifier={import.meta.env.VITE_NOVU_APP_ID}
    >
      <SellerContextProvider>
        <ChannelContextProvider>
          <PrinterContextProvider>
            <AppLoader />
          </PrinterContextProvider>
        </ChannelContextProvider>
      </SellerContextProvider>
    </NovuProvider>
  </DndProvider>
);
{
  pushNotification();
}
