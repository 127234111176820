import React from 'react';
import { Props } from './props';
import { Tag, TagProps } from '@scalingworks/react-admin-ui';
import { BusinessUnitTranslation } from '~/config/constant';
import { BusinessUnit } from '~/api';
import { toCamelCaseWord } from '~/resources/helpers';
import { EditIconButton } from '../EditIconButton';

export const BusinessUnitColor: Record<BusinessUnit, TagProps['color']> = {
  [BusinessUnit.Fnb]: 'amber',
  [BusinessUnit.Merchandise]: 'green',
  [BusinessUnit.Retail]: 'blue',
};

export const BusinessUnitFulfillmentTag: React.FC<Props> = (props) => {
  const { businessUnitFulfillments, showEditButton, onClickEdit } = props;

  return (
    <div className="flex flex-row gap-2 items-center">
      {businessUnitFulfillments.map((businessUnitFulfillment) => {
        const businessUnitLabel =
          BusinessUnitTranslation[businessUnitFulfillment.businessUnit as BusinessUnit];
        const fulfillmentLabels = businessUnitFulfillment.fulfillmentChannels
          .map((channel) => toCamelCaseWord(channel.code))
          .join(', ');

        return (
          <Tag key={businessUnitFulfillment.businessUnit} color={BusinessUnitColor[businessUnitFulfillment.businessUnit]}>
            {`${businessUnitLabel}: ${fulfillmentLabels}`}
          </Tag>
        );
      })}
      {showEditButton && <EditIconButton onClick={onClickEdit} />}
    </div>
  );
};
