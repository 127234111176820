import React, { useContext, useState } from 'react';
import {
  BusinessUnitFulfillmentTag,
  DetailRenderer,
  ShowPageWrapper,
  SomethingWentWrong,
} from '~/components';
import { useNavigation, useNotification, useOne, useTranslate } from '@refinedev/core';
import { resourceNames } from '~/resources/resource-names';
import { BsChevronUp } from 'react-icons/bs';
import { Card, Tag } from '@scalingworks/react-admin-ui';
import { outletFields, OutletStatusTagColor } from '../outlet-resource';
import { Outlet, OutletStatus } from '~/api';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { joinOutletAddress, formatBusinessUnitFulfillment } from '~/resources/helpers';
import { join } from 'lodash';
import { ChannelContext } from '~/providers/Channel/context';
import { FulfillmentChannelModal } from '~/config/FulfillmentChannelModal';
import { ManagerModal } from './ManagerModal';
import { useOutletApiHooks } from '../hooks/index';
import { DevicesStatus } from './DevicesStatus';
import { OperationHours } from './OperationHours';
import { EditIconButton } from '~/components/EditIconButton';

type Props = {
  resourceId?: string;
};

export const OutletDetailPage: React.FC<Props> = (props) => {
  const { resourceId: outletId } = props;

  // ======================= HOOKS
  const { channel } = useContext(ChannelContext);
  const t = useTranslate();
  const navigateTo = useNavigation();
  const notif = useNotification();
  const { useUpdateOutletFulfillmentChannels } = useOutletApiHooks();
  const { updateOutletFulfillmentChannels, isLoading: updatingOutletFC } =
    useUpdateOutletFulfillmentChannels(outletId, {
      onSuccess: () => {
        notif?.open?.({
          message: 'Fulfillment channels updated successfully',
          type: 'success',
        });
        refetch();
        setShowFulfillmentModal(false);
      },
    });

  // ======================= STATES
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false);
  const [showManagerModal, setShowManagerModal] = useState(false);

  // ======================= API
  const {
    data: outlet,
    isLoading,
    refetch,
  } = useOne<Outlet>({
    id: outletId,
    resource: resourceNames.outlet,
    metaData: {
      fields: [
        ...outletFields,
        {
          address: [
            'addressLineOne',
            'addressLineTwo',
            'city',
            'province',
            'postalCode',
            'country',
            'countryCode',
          ],
          operationHours: ['day', 'openingTime', 'closingTime', 'isClosed'],
        },
      ] as ResourceField<Outlet>[],
    },
  });

  // ======================= STATES
  const [showGeneral, setShowGeneral] = useState(true);

  const { useGetOutletDevicesStatus } = useOutletApiHooks();
  const { data: devicesStatus, refetch: refetchDevicesStatus } =
    useGetOutletDevicesStatus(outletId);

  if (!isLoading && !outletId) return <SomethingWentWrong />;

  const {
    name,
    contactNumber,
    status,
    onlineOrdering,
    address,
    operationHours,
    businessUnitFulfillmentMethods = [],
  } = outlet?.data || {};

  return (
    <ShowPageWrapper resourceName={resourceNames.outlet} loading={isLoading} title={name || ''}>
      <main className="flex flex-col space-y-8">
        {/* General Information */}
        <Card className="relative z-0">
          <Card.Header
            className="font-bold cursor-pointer"
            bordered
            onClick={() => setShowGeneral(!showGeneral)}
          >
            <section className="flex flex-row items-center justify-between">
              <h1>{t('common.general', undefined, 'General')}</h1>
              <div className="flex flex-row items-center space-x-2">
                <EditIconButton onClick={() => navigateTo.edit(resourceNames.outlet, outletId!)} />
                <BsChevronUp
                  size={20}
                  className={`transition-transform ${showGeneral ? 'rotate-180' : 'rotate-0'}`}
                />
              </div>
            </section>
          </Card.Header>
          {showGeneral && (
            <Card.Body>
              <DetailRenderer
                resource="outlet"
                data={{
                  name,
                  contactNumber,
                  address: joinOutletAddress(address),
                  manager: outlet?.data?.manager,
                  status,
                  onlineOrdering: onlineOrdering ? 'ENABLED' : 'DISABLED',
                  fulfillmentChannels: businessUnitFulfillmentMethods,
                }}
                render={{
                  status: (val) => (
                    <Tag color={OutletStatusTagColor[val as OutletStatus]}>
                      {t(`outlet.status.${val?.toLowerCase()}`, {
                        fallback: val as string,
                        ns: 'common',
                      }).toUpperCase()}
                    </Tag>
                  ),
                  manager: (val) => (
                    <div className="flex flex-row items-center space-x-2">
                      <span>{join([val?.firstName, val?.lastName].filter(Boolean), ' ')}</span>
                      <EditIconButton onClick={() => setShowManagerModal(true)} />
                    </div>
                  ),
                  onlineOrdering: (val) => <Tag color={val ? 'success' : 'default'}>{val}</Tag>,
                  fulfillmentChannels: (val) => (
                    <BusinessUnitFulfillmentTag
                      businessUnitFulfillments={val}
                      showEditButton
                      onClickEdit={() => setShowFulfillmentModal(true)}
                    />
                  ),
                }}
                loading={isLoading}
              />
            </Card.Body>
          )}
        </Card>

        {/* Operation Hours and Devices Status */}
        <div className="flex flex-col lg:grid lg:grid-cols-2 gap-8">
          <OperationHours operationHours={operationHours ?? undefined} />
          <DevicesStatus
            devices={devicesStatus?.data}
            outletId={outletId}
            refetch={refetchDevicesStatus}
          />
        </div>

        <FulfillmentChannelModal
          open={showFulfillmentModal}
          setOpen={setShowFulfillmentModal}
          title={t('outlet.fulfillment.title', undefined, 'Fulfillment Channels')}
          channel={channel}
          initialFulfillments={businessUnitFulfillmentMethods}
          isLoading={updatingOutletFC}
          onConfirm={(fulfillmentChannelIds) => {
            updateOutletFulfillmentChannels({
              fulfillmentChannelIds,
            });
          }}
        />

        <ManagerModal
          open={showManagerModal}
          setOpen={setShowManagerModal}
          title={t('outlet.manager.title', undefined, 'Manager')}
          outletId={outletId}
          currentManager={outlet?.data?.manager}
          refetch={refetch}
        />
      </main>
    </ShowPageWrapper>
  );
};
