import camelCase from 'lodash/camelCase';
import { FieldGroup, FileItem, FileUpload, ImageItem, Label } from '@scalingworks/react-admin-ui';
import { flexRow } from '..';
import { InputProps } from '../props';
import { Controller } from 'react-hook-form';
import { cloneDeep, compact, isEmpty } from 'lodash';

export type Props = InputProps;

export const FormUploadInput = ({
  input: { item, register, setValue, watch, control },
}: InputProps) => {
  const { title, name, required, placeholder, hint, props, fileType, defaultValue } = item;
  const formName = camelCase(name || title);

  return (
    <FieldGroup layout="responsive">
      <Label showRequiredIndicator={required}>{title}</Label>
      <div className="flex-1">
        <Controller
          name={formName}
          control={control}
          key={formName}
          rules={{ required }}
          defaultValue={defaultValue}
          render={({ field: { onChange, value = [] }, fieldState: { error } }) => {
            return (
              <>
                <FileUpload
                  {...props}
                  variant="area"
                  onFilesSelected={(file) => {
                    onChange(file);
                  }}
                  label={placeholder}
                  hint={hint}
                  fileType={fileType}
                />
                {!isEmpty(compact(value)) && (
                  <div>
                    {value?.map((subItem: any, index: number) => {
                      if (typeof subItem === 'string') {
                        return (
                          <ImageItem
                            url={subItem}
                            onRemove={() => {
                              const cloneArr = cloneDeep(value);
                              cloneArr?.splice(index, 1);
                              setValue(formName, cloneArr);
                            }}
                          />
                        );
                      }
                      return (
                        <FileItem
                          file={subItem}
                          onRemove={() => {
                            const cloneArr = cloneDeep(value);
                            cloneArr?.splice(index, 1);
                            setValue(formName, cloneArr);
                          }}
                        />
                      );
                    })}
                  </div>
                )}
                {!isEmpty(error) ? (
                  <div className="mt-2">
                    <p className="text-error-300 text-sm mt-3">{`Image is required`}</p>
                  </div>
                ) : undefined}
              </>
            );
          }}
        />
      </div>
    </FieldGroup>
  );
};

export const renderUploadInput = (props: Props) => {
  return <FormUploadInput {...props} />;
};
