import camelCase from 'lodash/camelCase';
import { MultiTextField, TextField } from '@scalingworks/react-admin-ui';
import { InputProps } from '../props';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

export const FormMultiTextField = ({
  input: { item, register, watch, setValue },
}: InputProps) => {
  const { title, name, required, multiline, placeholder, props, defaultValue } = item;
  const formName = camelCase(name || title);

  register(formName, { required });

  const getValue = () => {
    if (!isEmpty(watch(formName))) return watch(formName);
    if (defaultValue) return defaultValue;

    return [];
  };

  return (
    <MultiTextField
      {...props}
      required={required}
      label={title}
      layout="responsive"
      placeholder={placeholder}
      type={multiline ? 'textarea' : undefined}
      // value={!isEmpty(watch(formName)) ? watch(formName) : []}
      value={getValue()}
      includeDraft
      onValue={(val) => {
        setValue(formName, val);
      }}
    />
  );
};
