import React, { useState, useEffect } from 'react';
import { useTranslate } from '@refinedev/core';
import {
  Button,
  SwitchField,
  NumberField,
  SelectField,
  IconButton,
  Label,
  FieldGroup,
} from '@scalingworks/react-admin-ui';
import { Divider, flexRow, ModalWrapper } from '~/components';
import dayjs from 'dayjs';
import { ExpiryOnRedeemConfig } from '~/api';
import { FiEdit } from 'react-icons/fi';
import { isEmpty, join } from 'lodash';

type ExpiryConfigType = 'AFTER_REDEEM' | 'END_OF_PERIOD';

// Only use the ones we need
const AFTER_REDEEM_UNITS: dayjs.ManipulateType[] = ['hours', 'days', 'months', 'years'];
const END_OF_PERIOD_UNITS: dayjs.ManipulateType[] = ['day', 'week', 'month', 'year'];

// ExpiryConfigSummary component to display the summary with proper styling
export const ExpiryConfigSummary: React.FC<{
  config?: ExpiryOnRedeemConfig;
  readonly?: boolean;
}> = ({ config, readonly = false }) => {
  const { endOfThe, value, dateTimeUnit } = config || {};

  const t = useTranslate();

  if (!value) {
    if (readonly) {
      return <span>-</span>;
    }

    return <span className="text-gray-400 italic">Set Custom Expiry</span>;
  }

  const configTypeFromProps = endOfThe ? 'END_OF_PERIOD' : 'AFTER_REDEEM';

  if (configTypeFromProps === 'END_OF_PERIOD') {
    return (
      <span>
        {t('expiry.endOfPeriod', {
          unit: t(`dateTimeUnits.${dateTimeUnit}`),
        })}
        {readonly && <span> {t('expiry.afterRedemption')}</span>}
      </span>
    );
  }

  return (
    <span>{`${value} ${t(`dateTimeUnits.${dateTimeUnit}`)} ${
      readonly ? t('expiry.afterRedemption') : ''
    }`}</span>
  );
};

// Reusable AlignWithCampaignEndSwitch component
const AlignWithCampaignEndSwitch: React.FC<{
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
}> = ({ checked, onCheckedChange }) => {
  const t = useTranslate();

  return (
    <>
      <SwitchField
        label={t('expiry.useCurrentPeriodEndIfExceed')}
        checked={checked}
        onCheckedChange={onCheckedChange}
      />
      <div className="">
        <Label className="w-full !text-gray-400">
          {t('expiry.useCurrentPeriodEndIfExceedHint')}
        </Label>
        <Label className="w-full !text-gray-400">
          {t('expiry.useCurrentPeriodEndIfExceedExample')}
        </Label>
      </div>
    </>
  );
};

type OnRedeemConfigFormProps = {
  config?: ExpiryOnRedeemConfig;
  onChange?: (value: ExpiryOnRedeemConfig) => void;
  label?: string;
  rowClassName?: string;
};

export const OnRedeemConfigForm: React.FC<OnRedeemConfigFormProps> = ({
  config,
  onChange,
  label,
  rowClassName,
}) => {
  const { endOfThe, value, dateTimeUnit, alignWithCampaignEnd } = config || {};

  // ==================== HOOKS
  const t = useTranslate();

  // ==================== STATES
  const [open, setOpen] = useState(false);
  const [configType, setConfigType] = useState<ExpiryConfigType>(
    endOfThe ? 'END_OF_PERIOD' : 'AFTER_REDEEM'
  );
  const [draft, setDraft] = useState({
    value: value || 1,
    dateTimeUnit: dateTimeUnit || 'days',
    alignWithCampaignEnd: alignWithCampaignEnd || false,
    endOfThe: endOfThe || false,
  });

  // ==================== EFFECTS
  // Update form state when the config type changes
  useEffect(() => {
    if (configType === 'AFTER_REDEEM') {
      setDraft((prev) => ({
        ...prev,
        endOfThe: false,
        dateTimeUnit: AFTER_REDEEM_UNITS.includes(prev.dateTimeUnit as dayjs.ManipulateType)
          ? prev.dateTimeUnit
          : 'days',
      }));
    } else {
      setDraft((prev) => ({
        ...prev,
        endOfThe: true,
        dateTimeUnit: END_OF_PERIOD_UNITS.includes(prev.dateTimeUnit as dayjs.ManipulateType)
          ? prev.dateTimeUnit
          : 'day',
      }));
    }
  }, [configType]);

  // ==================== EVENTS
  const handleConfirm = () => {
    // Convert formState to match ExpiryOnRedeemConfig type
    const configData = {
      value: draft.value,
      dateTimeUnit: draft.dateTimeUnit,
      alignWithCampaignEnd: draft.alignWithCampaignEnd,
      // Convert boolean to string for API compatibility
      endOfThe: draft.endOfThe ? 'true' : undefined,
    };

    onChange?.(configData as ExpiryOnRedeemConfig);
    setOpen(false);
  };

  const handleInputChange = (field: string, value: any) => {
    setDraft((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // ==================== VIEWS
  return (
    <div>
      <div className={join([rowClassName, 'cursor-pointer'], ' ')} onClick={() => setOpen(true)}>
        <FieldGroup>
          <Label>{label}</Label>
          <div className="flex items-center">
            <ExpiryConfigSummary config={config} />
            <IconButton className="ml-2">
              <FiEdit className="h-4 w-4" />
            </IconButton>
          </div>
        </FieldGroup>
      </div>

      <ModalWrapper
        open={open}
        setOpen={setOpen}
        title={t('expiry.config')}
        onConfirm={handleConfirm}
        enableRemove={!isEmpty(config)}
        onRemove={() => {
          onChange?.({});
          setOpen(false);
        }}
      >
        <div className="space-y-4 py-4">
          <SelectField
            label={t('expiry.type.title')}
            placeholder={t('expiry.placeholder.type')}
            required
            value={configType}
            onValue={(val) => setConfigType(val as ExpiryConfigType)}
            options={[
              { label: t('expiry.type.afterRedeem'), value: 'AFTER_REDEEM' },
              { label: t('expiry.type.endOfPeriod'), value: 'END_OF_PERIOD' },
            ]}
          />

          <Divider />

          {configType === 'AFTER_REDEEM' && (
            <div className="flex flex-col gap-2">
              <Label className="w-full !text-gray-400">{t('expiry.afterRedeemHint')}</Label>
              <NumberField
                label={t('expiry.value')}
                placeholder={t('expiry.placeholder.value')}
                required
                value={draft.value.toString()}
                onValue={(val: string) => handleInputChange('value', Number(val) || 0)}
              />

              <SelectField
                label={t('expiry.unit')}
                placeholder={t('expiry.placeholder.unit')}
                required
                value={draft.dateTimeUnit}
                onValue={(val) => handleInputChange('dateTimeUnit', val)}
                options={AFTER_REDEEM_UNITS.map((unit) => ({
                  label: t(`dateTimeUnits.${unit}`),
                  value: unit,
                }))}
              />

              <AlignWithCampaignEndSwitch
                checked={draft.alignWithCampaignEnd}
                onCheckedChange={(checked) => handleInputChange('alignWithCampaignEnd', checked)}
              />
            </div>
          )}

          {configType === 'END_OF_PERIOD' && (
            <div className="flex flex-col gap-2">
              <Label className="w-full !text-gray-400">{t('expiry.endOfPeriodHint')}</Label>
              <SelectField
                label={t('expiry.periodUnit')}
                placeholder={t('expiry.placeholder.periodUnit')}
                required
                value={draft.dateTimeUnit}
                onValue={(val) => handleInputChange('dateTimeUnit', val)}
                options={END_OF_PERIOD_UNITS.map((unit) => ({
                  label: t(`dateTimeUnits.${unit}`),
                  value: unit,
                }))}
              />

              <AlignWithCampaignEndSwitch
                checked={draft.alignWithCampaignEnd}
                onCheckedChange={(checked) => handleInputChange('alignWithCampaignEnd', checked)}
              />
            </div>
          )}
        </div>
      </ModalWrapper>
    </div>
  );
};
