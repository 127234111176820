import React, { useContext, useState } from 'react';
import { Props } from './props';
import isEmpty from 'lodash/isEmpty';
import { Card } from '@scalingworks/react-admin-ui';
import { BsChevronUp, BsExclamationCircle, BsCheckCircle } from 'react-icons/bs';
import startCase from 'lodash/startCase';
import { ChannelContext } from '~/providers/Channel/context';
import { size } from 'lodash';

export const MembershipImportDetailResult: React.FC<Props> = ({ result }) => {
  // ========================= HOOKS
  const { channel } = useContext(ChannelContext);
  const { starTranslation = 'star', pointTranslation = 'point' } =
    channel?.loyaltyTranslations || {};

  // ========================= STATES
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

  // ========================= COMPUTED
  const totalFailedRecords =
    (result?.customersFailedImport?.length || 0) +
    (result?.pointDistributeFailedCustomers?.length || 0) +
    (result?.starDistributeFailedCustomers?.length || 0);

  const recordProcessed = result?.recordProcessed || 0;
  const successfulRecords = Math.max(recordProcessed - totalFailedRecords, 0);
  const hasErrors = totalFailedRecords > 0;

  // ========================= EVENTS
  const toggleSection = (section: string) => {
    setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  // ========================= VIEWS
  const renderSection = (
    title: string,
    records: any[] | undefined,
    isExpanded: boolean,
    onToggle: () => void,
    renderContent: (record: any) => React.ReactNode
  ) => {
    if (isEmpty(records)) return null;

    return (
      <Card className="w-full">
        <Card.Header
          className="font-medium cursor-pointer hover:bg-gray-50 border-l-4 border-l-error-500"
          bordered
          onClick={onToggle}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <BsExclamationCircle className="text-error-500" />
              <h2 className="text-gray-700">{title}</h2>
            </div>
            <div className="flex flex-row items-center gap-3">
              <span className="px-2 py-1 text-sm bg-error-50 text-error-700 rounded-full">
                {size(records)} records
              </span>
              <BsChevronUp
                size={16}
                className={`transition-transform ${
                  isExpanded ? 'rotate-180' : 'rotate-0'
                } text-gray-400`}
              />
            </div>
          </div>
        </Card.Header>
        {isExpanded && (
          <Card.Body className="p-4 space-y-2 bg-gray-50 divide-y divide-gray-100">
            {(records || []).map((record, index) => (
              <div key={index} className="p-3 first:pt-0 last:pb-0">
                {renderContent(record)}
              </div>
            ))}
          </Card.Body>
        )}
      </Card>
    );
  };

  return (
    <div className="space-y-6">
      {/* Summary Cards */}
      <div className="grid grid-cols-3 gap-4">
        <Card className="bg-gray-50">
          <Card.Body className="p-4">
            <div className="text-sm text-gray-600 mb-1">Total Records</div>
            <div className="text-2xl font-semibold">{result?.totalRecords || 0}</div>
          </Card.Body>
        </Card>

        <Card className="bg-success-50">
          <Card.Body className="p-4">
            <div className="text-sm text-gray-600 mb-1">Successfully Processed</div>
            <div className="flex items-center gap-2">
              <span className="text-2xl font-semibold text-success-700">{successfulRecords}</span>
              <BsCheckCircle className="text-success-500" />
            </div>
          </Card.Body>
        </Card>

        <Card className={hasErrors ? 'bg-error-50' : 'bg-success-50'}>
          <Card.Body className="p-4">
            <div className="text-sm text-gray-600 mb-1">Failed Records</div>
            <div className="flex items-center gap-2">
              <span
                className={`text-2xl font-semibold ${
                  hasErrors ? 'text-error-700' : 'text-success-700'
                }`}
              >
                {totalFailedRecords}
              </span>
              {hasErrors && <BsExclamationCircle className="text-error-500" />}
            </div>
          </Card.Body>
        </Card>
      </div>

      {/* Error Details */}
      {hasErrors && (
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-700">Failed Records Details</h3>

          {renderSection(
            'Failed to Import',
            result?.customersFailedImport,
            expandedSections['failedCustomers'] || false,
            () => toggleSection('failedCustomers'),
            (customerRecord) => (
              <div className="flex items-center justify-between text-sm">
                <div>
                  <span className="text-gray-500">Row</span>{' '}
                  <span className="font-medium text-gray-900">{customerRecord?.rowIndex}</span>
                </div>
                <div>
                  <span className="text-gray-500">Phone:</span>{' '}
                  <span className="font-medium text-gray-900">{customerRecord?.phone}</span>
                </div>
              </div>
            )
          )}

          {renderSection(
            `${startCase(starTranslation)} Distribution Failed`,
            result?.starDistributeFailedCustomers,
            expandedSections['failedStars'] || false,
            () => toggleSection('failedStars'),
            (customerRecord) => (
              <div className="space-y-1 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-500">Customer</span>
                  <span className="font-medium text-gray-900">{customerRecord?.title}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">Spending</span>
                  <span className="font-medium text-gray-900">{customerRecord?.spending}</span>
                </div>
              </div>
            )
          )}

          {renderSection(
            `${startCase(pointTranslation)} Distribution Failed`,
            result?.pointDistributeFailedCustomers,
            expandedSections['failedPoints'] || false,
            () => toggleSection('failedPoints'),
            (customerRecord) => (
              <div className="space-y-1 text-sm">
                <div className="flex justify-between">
                  <span className="text-gray-500">Customer</span>
                  <span className="font-medium text-gray-900">{customerRecord?.title}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-500">Spending</span>
                  <span className="font-medium text-gray-900">{customerRecord?.spending}</span>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};
