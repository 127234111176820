import React, { useState } from 'react';
import { Card } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';
import { BsChevronUp, BsCircleFill } from 'react-icons/bs';
import { formatDistanceToNow } from 'date-fns';
import { DeviceStatus } from '~/api';
import { EditIconButton } from '~/components/EditIconButton';
import { EditDeviceModal } from './EditDeviceModal';
import { isEmpty } from 'lodash';

type Props = {
  devices?: DeviceStatus[];
  outletId?: string;
  refetch?: () => void;
};

export const DevicesStatus: React.FC<Props> = ({ devices, outletId, refetch }) => {
  const t = useTranslate();
  const [showDevices, setShowDevices] = useState(true);
  const [expandedDevices, setExpandedDevices] = useState<string[]>([]);
  const [editingDevice, setEditingDevice] = useState<DeviceStatus | null>(null);

  const toggleDeviceExpand = (deviceId: string) => {
    setExpandedDevices((prev) =>
      prev.includes(deviceId) ? prev.filter((id) => id !== deviceId) : [...prev, deviceId]
    );
  };

  if (isEmpty(devices)) {
    return null;
  }

  return (
    <Card className="relative z-0">
      <Card.Header
        className="font-bold cursor-pointer"
        bordered
        onClick={() => setShowDevices(!showDevices)}
      >
        <section className="flex flex-row items-center justify-between">
          <h1>{t('outlet.devices.title', undefined, 'Devices Status')}</h1>
          <BsChevronUp
            size={20}
            className={`transition-transform ${showDevices ? 'rotate-180' : 'rotate-0'}`}
          />
        </section>
      </Card.Header>
      {showDevices && (
        <Card.Body>
          <div className="space-y-4">
            {devices?.map((device) => (
              <div key={device.id} className="overflow-hidden border border-gray-200 rounded-lg">
                {/* Device Header - Always visible */}
                <div
                  className="flex items-center justify-between p-3 bg-gray-50 cursor-pointer hover:bg-gray-100"
                  onClick={() => toggleDeviceExpand(device.id)}
                >
                  <div className="flex items-center space-x-3">
                    <span className="font-medium">
                      {device.name || t('outlet.devices.unnamed', {}, 'Unnamed Device')}
                    </span>
                    <EditIconButton
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent expanding the device details
                        setEditingDevice(device);
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-3">
                    <div className="flex items-center space-x-2">
                      <BsCircleFill
                        className={device.isConnected ? 'text-success-500' : 'text-gray-400'}
                        size={12}
                      />
                      <span className={device.isConnected ? 'text-success-500' : 'text-gray-500'}>
                        {device.isConnected ? 'Online' : 'Offline'}
                      </span>
                    </div>
                    <BsChevronUp
                      size={16}
                      className={`transition-transform ${
                        expandedDevices.includes(device.id) ? 'rotate-180' : 'rotate-0'
                      }`}
                    />
                  </div>
                </div>

                {/* Device Details - Expandable */}
                {expandedDevices.includes(device.id) && (
                  <div className="p-3 border-t border-gray-200 space-y-2">
                    <div className="grid grid-cols-2 gap-4 text-sm">
                      <div>
                        <p className="text-gray-500">
                          {t('outlet.devices.lastSeen', {}, 'Last Seen')}
                        </p>
                        <p className="font-medium">
                          {device.lastSeenAt
                            ? formatDistanceToNow(new Date(device.lastSeenAt), { addSuffix: true })
                            : t('outlet.devices.unknown', {}, 'Unknown')}
                        </p>
                      </div>
                      <div>
                        <p className="text-gray-500">
                          {t('outlet.devices.deviceToken', {}, 'Device Token')}
                        </p>
                        <p className="font-medium break-all">{device.deviceToken}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {!devices?.length && (
              <p className="text-gray-500 text-center py-4">
                {t('outlet.devices.empty', undefined, 'No devices found')}
              </p>
            )}
          </div>
        </Card.Body>
      )}

      <EditDeviceModal
        open={!!editingDevice}
        setOpen={() => setEditingDevice(null)}
        title={t('outlet.devices.edit.title', {}, 'Edit Device')}
        deviceId={editingDevice?.id}
        currentName={editingDevice?.name}
        onSuccess={() => {
          refetch?.();
          setEditingDevice(null);
        }}
      />
    </Card>
  );
};
