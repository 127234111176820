import React, { useState } from 'react';
import { useDelete, useNavigation, useTranslate } from '@refinedev/core';
import { FiChevronDown } from 'react-icons/fi';
import { Reward, RewardOrPromoCodeType, RewardStatus } from '~/api';
import { ActionButton, TriggerConfirmModal, ViewBuilder, flexRow } from '~/components';
import { dateFormatter, formatDateRange } from '~/resources/helpers';
import { resourceNames } from '~/resources/resource-names';
import { numeralThousandFormat } from '~/config/helper';
import { IsPrivateTag, renderStatusTag } from '../../helpers';
import { Props } from './props';
import { camelCase, isEmpty } from 'lodash';
import { ExpiryConfigSummary } from '../../promoCode/form/OnRedeemConfigForm';

export const RewardShow: React.FC<Props> = (props) => {
  const { helpers } = props;
  const rewardData = helpers?.queryResult?.data?.data as Reward;

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { mutate } = useDelete();
  const { edit, list } = useNavigation();
  const t = useTranslate();

  const title = t(`rewards.details`, {
    type: t(`rewards.type.${camelCase(rewardData?.type)}`),
  });

  return (
    <ViewBuilder
      {...helpers}
      title={title}
      items={[
        {
          cardTitle: t('common.general'),
          cardHeaderExtra: () => {
            return (
              <div>
                <ActionButton
                  customTitle={t('common.action')}
                  actions={[
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: async () => {
                        await edit(resourceNames?.reward, rewardData?.id);
                      },
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: async () => {
                        await mutate({
                          resource: resourceNames?.reward,
                          id: rewardData?.id,
                        });
                        list(resourceNames?.reward);
                      },
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={setShowDeleteConfirmation}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </div>
            );
          },
          config: [
            ...(!isEmpty(rewardData?.imageUrls)
              ? [
                  {
                    title: t('rewards.form.image'),
                    data: rewardData?.imageUrls || [],
                    type: 'image',
                  },
                ]
              : []),
            {
              title: t('rewards.form.name'),
              data: [rewardData?.name],
              type: 'text',
            },
            {
              title: t('rewards.form.description'),
              data: [`${rewardData?.description || '-'}`],
              type: 'text',
            },
            {
              title: t('rewards.form.visible'),
              data: [rewardData?.isPrivate ? t('rewards.form.hidden') : t('rewards.form.visible')],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      <IsPrivateTag
                        isPrivate={rewardData?.isPrivate}
                        resource={resourceNames?.reward}
                      />
                    </div>
                  </div>
                );
              },
            },
            {
              title: t('rewards.form.status'),
              data: [rewardData?.status],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      {renderStatusTag(
                        rewardData?.status === RewardStatus?.Active,
                        resourceNames.reward
                      )}
                    </div>
                  </div>
                );
              },
            },
            ...(rewardData?.claimableStartDate && rewardData?.claimableEndDate
              ? [
                  {
                    title: t('rewards.form.claimablePeriod'),
                    data: [
                      formatDateRange(
                        new Date(rewardData?.claimableStartDate),
                        new Date(rewardData?.claimableEndDate)
                      ),
                    ],
                    type: 'text',
                  },
                ]
              : []),
            ...(rewardData?.startAt && rewardData?.endAt
              ? [
                  {
                    title: t('rewards.form.usagePeriod'),
                    data: [
                      formatDateRange(new Date(rewardData?.startAt), new Date(rewardData?.endAt)),
                    ],
                    type: 'text',
                  },
                ]
              : []),
            ...(rewardData?.bigTitle
              ? [
                  {
                    title: t('rewards.form.bigTitle'),
                    data: [rewardData?.bigTitle],
                    type: 'text',
                  },
                ]
              : []),
            ...(rewardData?.smallSubTitle
              ? [
                  {
                    title: t('rewards.form.smallSubTitle'),
                    data: [rewardData?.smallSubTitle],
                    type: 'text',
                  },
                ]
              : []),
            {
              title: t('rewards.form.point'),
              data: [`${numeralThousandFormat(rewardData?.pointCost)} PTS`],
              type: 'text',
            },
            ...(rewardData?.quantity
              ? [
                  {
                    title: t('rewards.form.quantity'),
                    data: [`${numeralThousandFormat(rewardData?.quantity)}`],
                    type: 'text' as any,
                  },
                ]
              : []),
            {
              title: t('rewards.form.redeemedQuantity'),
              data: [`${numeralThousandFormat(rewardData?.redemptionCount)}`],
              type: 'text',
            },
            {
              title: t('rewards.form.usedQuantity'),
              data: [`${numeralThousandFormat(rewardData?.rewardUsedCount)}`],
              type: 'text',
            },
            ...(rewardData?.redeemPerUserLimit
              ? [
                  {
                    title: t('rewards.form.redeemPerUserLimit'),
                    data: [`${numeralThousandFormat(rewardData?.redeemPerUserLimit)}`],
                    type: 'text',
                  },
                ]
              : []),
            {
              title: t('rewards.form.expiryValue'),
              data: [rewardData?.onRedeemConfig],
              render: ({ data, flex, title }) => {
                return (
                  <div style={{ ...flexRow }}>
                    {title}
                    <div style={{ flex }}>
                      <ExpiryConfigSummary config={rewardData?.onRedeemConfig || {}} readonly />
                    </div>
                  </div>
                );
              },
            },
            ...(rewardData?.productVariantName
              ? [
                  {
                    title: t('rewards.form.products'),
                    data: [rewardData?.productVariantName],
                    type: 'text',
                  },
                ]
              : []),
            ...(rewardData?.productVariantImage
              ? [
                  {
                    title: t('rewards.form.variantImage'),
                    data: [rewardData.productVariantImage],
                    type: 'image' as 'image',
                  },
                ]
              : []),
            {
              title: t('rewards.form.tiers'),
              data: rewardData?.membershipTiers?.map((subItem) => subItem?.name) as string[],
              type: 'text',
            },
            {
              title: t('rewards.form.criteria'),
              data: [`${rewardData?.redeemCriteria || '-'}`],
              type: 'text',
            },
            {
              title: t('rewards.form.tnc'),
              data: [`${rewardData?.termCondition || '-'}`],
              type: 'text',
            },
          ],
        },
      ]}
    />
  );
};
