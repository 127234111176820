import { SellerResource } from '~/api';
import { resourceNames } from '../resource-names';
import { createReportingResource } from './create-reporting-resource';
import React from 'react';
import { 
  FiUser, 
  FiTrendingUp, 
  FiActivity,
  FiDollarSign,
  FiPackage,
  FiGift,
  FiSpeaker,
  FiShoppingCart,
  FiShoppingBag,
  FiBarChart2,
  FiBox
} from 'react-icons/fi';

// Customer Reporting
export const customerReportingResource = createReportingResource({
  resourceName: resourceNames.customerReports,
  sellerResource: SellerResource.CustomersReporting,
  label: 'Customer Reporting',
  icon: <FiUser />,
});

// Acquisition Reporting
export const acquisitionReportingResource = createReportingResource({
  resourceName: resourceNames.acquisitionReports,
  sellerResource: SellerResource.AcquisitionReporting,
  label: 'Acquisition Reporting',
  icon: <FiTrendingUp />,
});

// Behaviour Reporting
export const behaviourReportingResource = createReportingResource({
  resourceName: resourceNames.behaviourReports,
  sellerResource: SellerResource.BehaviourReporting,
  label: 'Behaviour Reporting',
  icon: <FiActivity />,
});

// Finances Reporting
export const financesReportingResource = createReportingResource({
  resourceName: resourceNames.financesReports,
  sellerResource: SellerResource.FinancesReporting,
  label: 'Finances Reporting',
  icon: <FiDollarSign />,
});

// Inventory Reporting
export const inventoryReportingResource = createReportingResource({
  resourceName: resourceNames.inventoryReports,
  sellerResource: SellerResource.InventoryReporting,
  label: 'Inventory Reporting',
  icon: <FiPackage />,
});

// Loyalty Reporting
export const loyaltyReportingResource = createReportingResource({
  resourceName: resourceNames.loyaltyReports,
  sellerResource: SellerResource.LoyaltyReporting,
  label: 'Loyalty Reporting',
  icon: <FiGift />,
});

// Marketing Reporting
export const marketingReportingResource = createReportingResource({
  resourceName: resourceNames.marketingReports,
  sellerResource: SellerResource.MarketingReporting,
  label: 'Marketing Reporting',
  icon: <FiSpeaker />,
});

// Orders Reporting
export const ordersReportingResource = createReportingResource({
  resourceName: resourceNames.ordersReports,
  sellerResource: SellerResource.OrdersReporting,
  label: 'Orders Reporting',
  icon: <FiBox />,
});

// Retail Sales Reporting
export const retailSalesReportingResource = createReportingResource({
  resourceName: resourceNames.retailSalesReports,
  sellerResource: SellerResource.RetailSalesReporting,
  label: 'Retail Sales Reporting',
  icon: <FiShoppingBag />,
});

// Sales Reporting
export const salesReportingResource = createReportingResource({
  resourceName: resourceNames.salesReports,
  sellerResource: SellerResource.SalesReporting,
  label: 'Sales Reporting',
  icon: <FiBarChart2 />,
}); 