import React, { useEffect, useState } from 'react';
import { Button, Progress, IconButton } from '@scalingworks/react-admin-ui';
import { useList } from '@refinedev/core';
import { CustomerImportHistory, ImportCustomerResult, CustomerImportState } from '~/api';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { format } from 'date-fns';
import { Props } from './props';
import { isEmpty } from 'lodash';
import { clsx } from 'clsx';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { ModalWrapper } from '~/components/ModalWrapper';
import { MembershipImportDetailResult } from '../DetailResult';

interface StatusBadgeProps {
  result: ImportCustomerResult;
  state?: CustomerImportState | null;
  onShowDetails: () => void;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ result, state, onShowDetails }) => {
  // Calculate progress and error states
  const progressValue = result.totalRecords ? result.recordProcessed / result.totalRecords : 0;
  const progressPercent = Math.round(progressValue * 100);
  const hasErrors =
    !isEmpty(result.customersFailedImport) ||
    !isEmpty(result.pointDistributeFailedCustomers) ||
    !isEmpty(result.starDistributeFailedCustomers);

  return (
    <div className="flex flex-col gap-1">
      {/* Only show status badge if we have state */}
      {state && (
        <div className="flex items-center gap-2">
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${(() => {
              switch (state) {
                case CustomerImportState.Completed:
                  return hasErrors
                    ? 'bg-warning-100 text-warning-800'
                    : 'bg-success-100 text-success-800';
                case CustomerImportState.Running:
                  return 'bg-info-100 text-info-800';
                case CustomerImportState.Failed:
                  return 'bg-error-100 text-error-800';
                default:
                  return 'bg-info-100 text-info-800';
              }
            })()}`}
          >
            {(() => {
              switch (state) {
                case CustomerImportState.Completed:
                  return hasErrors ? 'Completed with errors' : 'Completed';
                case CustomerImportState.Running:
                  return 'In progress';
                case CustomerImportState.Failed:
                  return 'Failed';
                default:
                  return 'Pending';
              }
            })()}
          </span>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onShowDetails();
            }}
            className="hover:bg-gray-100"
          >
            <HiOutlineInformationCircle className="w-4 h-4 text-gray-500" />
          </IconButton>
        </div>
      )}

      {/* Always show progress bar if we have totalRecords */}
      {result.totalRecords && (
        <div className="flex items-center gap-2">
          <div className="flex-1">
            <Progress
              progress={progressValue}
              className={clsx('h-1.5', '[&>div]:bg-success-500')}
            />
          </div>
          <span className="text-xs text-gray-600 whitespace-nowrap">
            {result.recordProcessed} / {result.totalRecords} ({progressPercent}%)
          </span>
        </div>
      )}

      {/* Show error count for completed jobs with errors */}
      {hasErrors && state === CustomerImportState.Completed && (
        <span className="text-xs text-error-600">
          {(result.customersFailedImport?.length || 0) +
            (result.pointDistributeFailedCustomers?.length || 0) +
            (result.starDistributeFailedCustomers?.length || 0)}{' '}
          failed
        </span>
      )}

      {/* Always show info button if no state */}
      {!state && (
        <div className="flex items-center gap-2">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onShowDetails();
            }}
            className="hover:bg-gray-100"
          >
            <HiOutlineInformationCircle className="w-4 h-4 text-gray-500" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const ImportHistory: React.FC<Props> = ({ className = '', triggerRefetch }) => {
  // Add state for modal
  const [selectedResult, setSelectedResult] = useState<ImportCustomerResult | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // ==================== HOOKS
  const {
    data: importHistories,
    isLoading: isLoadingHistories,
    refetch: refetchImportHistories,
  } = useList<CustomerImportHistory>({
    resource: 'customerImportHistories',
    pagination: { pageSize: 5, current: 1 },
    meta: {
      fields: [
        'id',
        'fileName',
        'result',
        'sheetName',
        'updatedAt',
        'createdAt',
        'state',
      ] as ResourceField<CustomerImportHistory>[],
    },
    sorters: [{ field: 'createdAt', order: 'desc' }],
    queryOptions: {
      // Poll every 3 seconds
      refetchInterval: 3000,
      // Stop polling if the tab is not active
      refetchIntervalInBackground: false,
      // Disable polling if all visible records are completed
      enabled: true,
      refetchOnWindowFocus: true,
    },
  });

  // Add a check to see if we need to continue polling
  const shouldPoll = React.useMemo(() => {
    if (!importHistories?.data) return true;

    // Check if any of the visible records are still in progress
    return importHistories.data.some((history) => {
      const result = (
        typeof history.result === 'object'
          ? history.result
          : JSON.parse((history.result || '{}') as string)
      ) as ImportCustomerResult;

      // If it has totalRecords and state is RUNNING, continue polling
      if (result.totalRecords && history.state === CustomerImportState.Running) {
        return true;
      }

      // For legacy records or non-running states, don't affect polling
      return false;
    });
  }, [importHistories?.data]);

  // Update polling based on completion status
  useEffect(() => {
    if (!shouldPoll) {
      // If all visible records are complete, we can stop polling
      refetchImportHistories();
    }
  }, [shouldPoll]);

  // Keep the existing manual trigger effect
  useEffect(() => {
    if (triggerRefetch) {
      // Delay 3 seconds before refetching due to job might be slower
      const timeoutId = setTimeout(() => {
        refetchImportHistories();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [triggerRefetch]);

  // ==================== HANDLERS
  const handleRefetch = async () => {
    await refetchImportHistories();
  };

  return (
    <>
      <div className={`w-full ${className}`}>
        <div className="flex justify-between items-center mb-3">
          <h2 className="text-lg font-medium text-gray-700">Recent Imports</h2>
          <Button onClick={handleRefetch} variant="default" size="sm" loading={isLoadingHistories}>
            Refresh
          </Button>
        </div>

        {importHistories?.data && importHistories.data.length > 0 && (
          <div className="border rounded-lg overflow-hidden">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Date</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">
                    File Name
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Sheet</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-500">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {importHistories.data.map((history) => {
                  const result = (
                    typeof history.result === 'object'
                      ? history.result
                      : JSON.parse((history.result || '{}') as string)
                  ) as ImportCustomerResult;
                  return (
                    <tr key={history.id} className="hover:bg-gray-50">
                      <td className="px-4 py-2 text-sm text-gray-600">
                        {format(new Date(history.createdAt), 'MMM dd, yyyy hh:mm a')}
                      </td>
                      <td className="px-4 py-2 text-sm text-gray-600">{history.fileName}</td>
                      <td className="px-4 py-2 text-sm text-gray-600">{history.sheetName}</td>
                      <td className="px-4 py-2 text-sm">
                        <StatusBadge
                          result={result}
                          state={history.state}
                          onShowDetails={() => {
                            setSelectedResult(result);
                            setIsModalOpen(true);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ModalWrapper
        open={isModalOpen}
        setOpen={setIsModalOpen}
        title="Import Details"
        hideButton
        contentClassName="w-[800px] max-h-[80vh] overflow-y-auto"
      >
        {selectedResult && (
          <div className="p-4">
            <MembershipImportDetailResult result={selectedResult} />
          </div>
        )}
      </ModalWrapper>
    </>
  );
};
