export const resourceNames = {
  dashboard: 'dashboard',
  customer: 'customers',
  reservation: 'reservations',
  product: 'products',
  order: 'orders',
  outlet: 'outlets',
  outletDevice: 'outletDevices',
  productReviews: 'productReviews',
  reward: 'rewards',
  promoCode: 'promoCodes',
  staff: 'administrators',
  pointTransaction: 'pointTransactionHistories',
  redemptionHistory: 'redemptionHistories',
  membershiptier: 'membershipTiers',
  discountPromotion: 'discountPromotions',
  banner: 'banners',
  collection: 'collections',
  shippingMethod: 'shippingMethods',
  setting: 'settings',
  notificationBlast: 'notificationBlasts',
  modifier: 'modifiers',
  modifierGroup: 'modifierGroups',
  auditLog: 'auditLogs',
  reservationCalendar: 'reservationCalendar',
  profile: 'profile',
  report: 'reports',
  customerReports: 'customerReports',
  acquisitionReports: 'acquisitionReports',
  behaviourReports: 'behaviourReports',
  financesReports: 'financesReports',
  inventoryReports: 'inventoryReports',
  loyaltyReports: 'loyaltyReports',
  marketingReports: 'marketingReports',
  ordersReports: 'ordersReports',
  retailSalesReports: 'retailSalesReports',
  salesReports: 'salesReports',
} as const;
