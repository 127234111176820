import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, FileUpload, IconButton } from '@scalingworks/react-admin-ui';
import { formatFileSize } from '~/resources/helpers';
import { useNavigation, useNotification, useTranslate } from '@refinedev/core';
import { CreateImportCustomerJobResult } from '~/api';
import { isEmpty } from 'lodash';
import { GQLClient } from '../gql-client';
import { HiArrowLeft } from 'react-icons/hi';
import { ImportHistory } from './ImportHistory';

export const MembershipImportPage: React.FC = () => {
  // ==================== HOOKS
  const t = useTranslate();
  const nav = useNavigation();
  const url = useLocation();
  const searchParams = new URLSearchParams(url.search);
  const type = searchParams.get('type') || 'feedme';
  const notification = useNotification();

  // ==================== STATES
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [result, setResult] = useState<CreateImportCustomerJobResult>();
  const [triggerRefetch, setTriggerRefetch] = useState<number>(0);

  // ==================== VARIABLES
  const backendUrl = import.meta.env.VITE_BACKEND_BASE_URL;
  const headers = GQLClient.prepareApiHeaders();

  // ==================== EVENTS
  const handleFileSelect = (files: File[]) => {
    if (files.length > 0) {
      setResult(undefined);
      setSelectedFile(files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('type', type);

    setIsUploading(true);
    try {
      const response = await fetch(`${backendUrl}/customer/import`, {
        headers,
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText || 'Upload failed');
      }

      const data = (await response.json()) as CreateImportCustomerJobResult;

      setResult(data);
      setSelectedFile(null);
      notification.open?.({ message: 'Import process will run in background', type: 'progress' });
      
      // Trigger refetch by updating timestamp
      setTriggerRefetch(Date.now());
    } catch (error: any) {
      try {
        const errorResult = JSON.parse(error.message) as CreateImportCustomerJobResult;
        setResult(errorResult);
        notification.open?.({ message: errorResult.message, type: 'error' });
      } catch (parseError) {
        setResult({
          success: false,
          message: error?.message || 'Failed to upload',
          fileName: '',
          sheetName: '',
          missingFields: [],
        });
        notification.open?.({ message: error?.message || 'Failed to upload', type: 'error' });
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <main className="flex flex-col items-center w-full pb-10">
      <section className="w-4/6 flex flex-col items-center">
        <div className="flex flex-row items-center justify-between mb-6 w-full">
          <div className="flex flex-row gap-3">
            <IconButton onClick={() => nav.goBack()}>
              <HiArrowLeft size={25} color="black" />
            </IconButton>
            <h1 className="text-2xl font-semibold text-gray-800">Membership Import</h1>
          </div>
          <p className="text-gray-600">
            Import Type: <span className="font-medium">{type}</span>
          </p>
        </div>

        <ImportHistory className="mb-6" triggerRefetch={triggerRefetch} />

        <div className="mb-4 w-full">
          <FileUpload
            fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            label="Upload XLSX File"
            hint="Drag or click to upload an XLSX file."
            onFilesSelected={handleFileSelect}
          />
        </div>

        {!isEmpty(result) && (
          <div className="border rounded-lg p-4 mb-4 bg-gray-50 w-full">
            <p className="text-gray-700">
              <span>Status: </span>
              <span className={`${result?.success ? 'text-success-600' : 'text-error-600'}`}>
                {result?.message}
              </span>
            </p>
            <div className="">
              {!!result?.fileName && <p>File name: {result.fileName}</p>}
              {!!result?.sheetName && <p>Sheet: {result.sheetName}</p>}
            </div>
            {!isEmpty(result?.missingFields) && (
              <p className="flex flex-row gap-2 items-center bg-error-100 text-error-700 p-2 rounded">
                Missing fields: {result?.missingFields.map((field) => field).join(', ')}
              </p>
            )}
          </div>
        )}

        {selectedFile && (
          <div className="border rounded-lg p-4 mb-4 bg-gray-50 w-full">
            <p className="text-gray-700">
              Selected File: <span className="font-medium">{selectedFile.name}</span>
            </p>
            <p className="text-gray-500 text-sm">Size: {formatFileSize(selectedFile.size)}</p>
          </div>
        )}

        <div className="flex items-center space-x-4 w-full">
          <Button
            className="flex-1"
            onClick={handleFileUpload}
            loading={isUploading}
            disabled={!selectedFile || isUploading}
            size="md"
            variant="solid"
          >
            {isUploading ? 'Uploading...' : 'Submit'}
          </Button>

          <Button
            className="flex-1"
            onClick={() => setSelectedFile(null)}
            disabled={!selectedFile || isUploading}
            size="md"
            variant="default"
          >
            Clear
          </Button>
        </div>
      </section>
    </main>
  );
};
