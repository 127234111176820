import React, { useEffect, useState } from 'react';
import { FormBuilder } from '~/components';
import { renderDataSelection } from '~/components/FormBuilder/DataSelection';
import { ProductVariantSelection } from '~/components/SearchAndSelect';
import { FormItemProps } from '~/components/FormBuilder/props';
import { MembershipTier, RewardType, RewardStatus, getSdk } from '~/api';
import isEmpty from 'lodash/isEmpty';
import { Props } from './props';
import { resourceNames } from '~/resources/resource-names';
import { useTranslate } from '@refinedev/core';
import camelCase from 'lodash/camelCase';
import { useGetMembershipTiersQuery } from '~/generated/hooks';
import { DaySelectorProps, TextFieldProps } from '@scalingworks/react-admin-ui';
import dayjs from 'dayjs';
import { OnRedeemConfigForm } from '../../../loyalty/promoCode/form/OnRedeemConfigForm';
import { toCamelCaseWord } from '~/resources/helpers';

export const RewardForm: React.FC<Props> = (props) => {
  const { onSubmit, rewardData } = props;
  const isUpdate = !isEmpty(rewardData);
  const t = useTranslate();

  const { data: membershipTiersData } = useGetMembershipTiersQuery();

  return (
    <div>
      <FormBuilder
        resourceName={resourceNames.reward}
        title={t(`rewards.${isUpdate ? 'update' : 'create'}.withName`, {
          name: t(`rewards.name.reward`),
        })}
        isUpdate={isUpdate}
        items={({ register, setValue, watch }) => {
          const type = watch('type');
          const watchStartDate = watch('startDate');
          const watchEndDate = watch('endDate');
          const watchClaimableStartDate = watch('claimableStartDate');
          const watchClaimableEndDate = watch('claimableEndDate');

          const finalOptions: FormItemProps[] = [
            {
              name: 'image',
              title: t('rewards.form.image'),
              type: 'upload',
              placeholder: t('product.placeholder.images'),
              hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
              fileType: 'image',
              defaultValue: rewardData?.imageUrls,
            },
            {
              name: 'name',
              title: t('rewards.form.name'),
              placeholder: t('rewards.placeholder.name'),
              required: true,
              defaultValue: rewardData?.name,
            },
            {
              name: 'description',
              title: t('rewards.form.description'),
              multiline: true,
              placeholder: t('rewards.placeholder.description'),
              required: true,
              defaultValue: rewardData?.description,
            },
            {
              name: 'visible',
              title: t('rewards.form.visible'),
              type: 'switch',
              defaultValue: !rewardData?.isPrivate,
            },
            ...(isUpdate
              ? [
                  {
                    name: 'status',
                    title: t('rewards.form.status'),
                    type: 'select',
                    options: Object.values(RewardStatus).map((value) => ({
                      label: toCamelCaseWord(value),
                      value,
                    })),
                    defaultValue: rewardData?.status,
                  } as FormItemProps,
                ]
              : []),
            {
              name: 'pointCost',
              title: t('rewards.form.point'),
              type: 'number',
              placeholder: t('rewards.placeholder.pointCost'),
              required: true,
              suffix: 'PTS',
              defaultValue: rewardData?.pointCost ?? 0,
            },
            {
              name: 'quantity',
              title: t('rewards.form.totalQuantity'),
              type: 'number',
              placeholder: t('rewards.placeholder.totalQuantity'),
              required: true,
              defaultValue: rewardData?.quantity ?? 0,
            },
            {
              name: 'type',
              title: t('rewards.form.type'),
              type: 'select',
              defaultValue: rewardData?.type ?? RewardType.InStore,
              options: [RewardType.InStore].map((value) => ({
                label: t(`rewards.type.${camelCase(value)}`),
                value,
              })),
              required: true,
              // TODO: Temporary hide in-app rewards
              props: {
                disabled: true,
              },
              // options: [RewardType.InStore, RewardType.InApp].map((value) => ({
              //   label: t(`rewards.type.${camelCase(value)}`),
              //   value,
              // })),
            },
            {
              name: 'products',
              title: t('rewards.form.products'),
              render: () => {
                const shouldShow = type && type !== RewardType.InStore;

                return (
                  <div className="mt-5" style={{ display: shouldShow ? 'block' : 'none' }}>
                    {renderDataSelection({
                      input: {
                        item: {
                          title: t('rewards.form.products'),
                          name: 'variantId',
                          required: false,
                          defaultValue: rewardData?.productVariantId,
                        },
                        register,
                        setValue,
                        watch,
                      },
                      selectionComponent: ProductVariantSelection,
                      selectionProps: {
                        required: false,
                        defaultValue: rewardData?.productVariantId,
                        initialOption: {
                          label: rewardData?.productVariantName,
                          value: rewardData?.productVariantId,
                        },
                      },
                    })}
                  </div>
                );
              },
            },
            {
              name: 'membershipTiers',
              title: t('rewards.form.tiers'),
              type: 'select',
              multiSelect: true,
              options: membershipTiersData?.getMembershipTiers?.items?.map((subItem) => ({
                label: subItem?.name,
                value: subItem?.id,
              })),
              placeholder: t('rewards.placeholder.tier'),
              defaultValue: rewardData?.membershipTiers?.map((subItem) => subItem?.id),
              required: true,
            },
            {
              name: 'startDate',
              title: t('rewards.form.start'),
              type: 'date-picker',
              required: true,
              defaultValue: rewardData?.startAt,
              placeholder: t('rewards.placeholder.start'),
              props: {
                max: watchEndDate,
              } as DaySelectorProps,
            },
            {
              name: 'endDate',
              title: t('rewards.form.end'),
              type: 'date-picker',
              defaultValue: rewardData?.endAt,
              placeholder: t('rewards.placeholder.end'),
              props: {
                min: watchStartDate ? new Date(watchStartDate) : undefined,
                disabled: !watchStartDate,
                parseValue: (val) => (val ? dayjs(val).endOf('day').toDate() : undefined),
              } as DaySelectorProps,
            },
            {
              name: 'claimableStartDate',
              title: t('rewards.form.claimableStart'),
              type: 'date-picker',
              placeholder: t('rewards.placeholder.claimableStart'),
              defaultValue: rewardData?.claimableStartDate,
              props: {
                max: watchClaimableEndDate ? new Date(watchClaimableEndDate) : undefined,
              } as DaySelectorProps,
            },
            {
              name: 'claimableEndDate',
              title: t('rewards.form.claimableEnd'),
              type: 'date-picker',
              placeholder: t('rewards.placeholder.claimableEnd'),
              defaultValue: rewardData?.claimableEndDate,
              props: {
                min: watchClaimableStartDate,
                disabled: !watchClaimableStartDate,
                parseValue: (val) => (val ? dayjs(val).endOf('day').toDate() : undefined),
              } as DaySelectorProps,
            },
            // NOTE: For Kedai
            // {
            //   name: 'bigImageUrl',
            //   title: t('rewards.form.bigImage'),
            //   type: 'upload',
            //   placeholder: t('rewards.placeholder.bigImage'),
            //   hint: t('messages.acceptedFileTypes', { files: 'JPG, JPEG, PNG' }),
            //   fileType: 'image',
            // },
            // {
            //   name: 'bigTitle',
            //   title: t('rewards.form.bigTitle'),
            //   placeholder: t('rewards.placeholder.bigTitle'),
            //   defaultValue: rewardData?.bigTitle,
            // },
            // {
            //   name: 'smallSubTitle',
            //   title: t('rewards.form.smallSubTitle'),
            //   placeholder: t('rewards.placeholder.smallSubTitle'),
            //   defaultValue: rewardData?.smallSubTitle,
            // },
            {
              name: 'redeemPerUserLimit',
              title: t('rewards.form.redeemPerUserLimit'),
              type: 'number',
              placeholder: t('rewards.placeholder.redeemPerUserLimit'),
              defaultValue: rewardData?.redeemPerUserLimit,
            },
            {
              name: 'onRedeemConfig',
              title: t('rewards.form.expiryValue'),
              render: ({ input }, { row }) => {
                const watchOnRedeemConfig = watch('onRedeemConfig') || rewardData?.onRedeemConfig;
                return (
                  <OnRedeemConfigForm
                    config={watchOnRedeemConfig}
                    onChange={(val) => {
                      input.setValue('onRedeemConfig', val);
                    }}
                    label={t('rewards.form.expiryValue')}
                    rowClassName={row}
                  />
                );
              },
              defaultValue: rewardData?.onRedeemConfig,
            },
            {
              name: 'redeemCriteria',
              title: t('rewards.form.criteria'),
              multiline: true,
              placeholder: t('rewards.placeholder.criteria'),
              required: true,
              defaultValue: rewardData?.redeemCriteria,
            },
            {
              name: 'termsConditions',
              title: t('rewards.form.tnc'),
              multiline: true,
              placeholder: t('rewards.placeholder.tnc'),
              required: true,
              defaultValue: rewardData?.termCondition,
            },
          ];

          return finalOptions;
        }}
        onSubmit={(data) => {
          onSubmit?.(data);
        }}
      />
    </div>
  );
};
