import React, { useState } from 'react';
import { Card } from '@scalingworks/react-admin-ui';
import { useTranslate } from '@refinedev/core';
import { BsChevronUp } from 'react-icons/bs';
import { DetailRenderer } from '~/components';
import { formatTime } from '~/resources/helpers';
import { OutletOperationHour as OperationHour } from '~/api';

type Props = {
  operationHours?: OperationHour[];
};

export const OperationHours: React.FC<Props> = ({ operationHours }) => {
  const t = useTranslate();
  const [showOperationHours, setShowOperationHours] = useState(true);

  if (!operationHours?.length) return null;

  return (
    <Card className="relative z-0">
      <Card.Header
        className="font-bold cursor-pointer"
        bordered
        onClick={() => setShowOperationHours(!showOperationHours)}
      >
        <section className="flex flex-row items-center justify-between">
          <h1>{t('outlet.operationHours', undefined, 'Operation Hours')}</h1>
          <BsChevronUp
            size={20}
            className={`transition-transform ${
              showOperationHours ? 'rotate-180' : 'rotate-0'
            }`}
          />
        </section>
      </Card.Header>
      {showOperationHours && (
        <Card.Body>
          <DetailRenderer
            resource="outlet"
            data={
              operationHours?.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.day.toLowerCase()]: curr.isClosed
                    ? 'Closed'
                    : `${formatTime(curr.openingTime)} - ${formatTime(curr.closingTime)}`,
                }),
                {}
              ) || {}
            }
          />
        </Card.Body>
      )}
    </Card>
  );
}; 