import { useTranslate } from '@refinedev/core';
import { FiPieChart } from 'react-icons/fi';
import { useMetabaseApi } from '~/config/metabase-hook';
import { IframeRefreshButton, Loading, ShowPageWrapper } from '~/components';
import { useMemo, useState } from 'react';
import { Tabs } from '@scalingworks/react-admin-ui';
import isEmpty from 'lodash/isEmpty';
import { SellerResource } from '~/api';

interface BaseReportingProps {
  resourceName: string;
  sellerResource: SellerResource;
  title: string;
}

const NoReport: React.FC<{ message: string }> = ({ message }) => (
  <div className="flex flex-col items-center justify-center p-8 text-center">
    <FiPieChart className="w-12 h-12 text-gray-300 mb-4" />
    <p className="text-gray-500 text-lg">{message}</p>
  </div>
);

const ReportFrame: React.FC<{ questionId?: number; dashboardId?: number; title: string }> = ({
  questionId,
  dashboardId,
  title,
}) => {
  const { useGetMetabaseUrl } = useMetabaseApi();
  const { data: url, isLoading } = useGetMetabaseUrl({
    question: questionId ? questionId?.toString() : undefined,
    dashboard: dashboardId ? dashboardId?.toString() : undefined,
  });

  if (isLoading) return <Loading />;

  return (
    <div className="flex flex-col space-y-4">
      {title && <h3 className="text-xl">{title}</h3>}
      <div style={{ position: 'relative', width: '100%', height: 600 }}>
        <iframe className="w-full" height={600} src={url?.data as string} />
        {/* Bottom panel overlay */}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '65px',
            backgroundColor: 'white',
            zIndex: 1,
          }}
        />
      </div>
    </div>
  );
};

export const BaseReporting: React.FC<BaseReportingProps> = ({
  resourceName,
  sellerResource,
  title,
}) => {
  // ======================= HOOKS
  const t = useTranslate();
  const { useGetMetabaseReportGroups } = useMetabaseApi();

  // ======================= STATES
  const [activeSubTab, setActiveSubTab] = useState<string>('');

  // ======================= API
  const { data: reportGroups, isLoading: loadingGroups } =
    useGetMetabaseReportGroups(sellerResource);

  const groups = useMemo(() => reportGroups?.data || [], [reportGroups]);
  const currentGroup = useMemo(() => groups[0], [groups]);

  // Set initial active tab when collections are loaded
  useMemo(() => {
    if (currentGroup?.collections?.length > 0 && !activeSubTab) {
      setActiveSubTab(currentGroup.collections[0].title);
    }
  }, [currentGroup, activeSubTab]);

  if (loadingGroups) {
    return <Loading />;
  }

  // Handle no report groups
  if (isEmpty(groups)) {
    return (
      <ShowPageWrapper resourceName={resourceName} title={title} showBackButton={false}>
        <NoReport message={t('reporting.noReports', {}, 'No reports available')} />
      </ShowPageWrapper>
    );
  }

  return (
    <section className="overflow-y-scroll">
      <ShowPageWrapper
        resourceName={resourceName}
        title={title}
        showBackButton={false}
        extra={<IframeRefreshButton onClick={() => window.location.reload()} />}
      >
        {/* Handle no collections */}
        {!currentGroup?.collections?.length ? (
          <NoReport
            message={t('reporting.noCollections', {}, 'No collections available for this report')}
          />
        ) : (
          <Tabs.Root value={activeSubTab} onValueChange={setActiveSubTab}>
            <Tabs.List className="mb-6">
              {currentGroup.collections.map((collection) => (
                <Tabs.Trigger key={collection.title} value={collection.title}>
                  {collection.title}
                </Tabs.Trigger>
              ))}
            </Tabs.List>

            {/* Content for each tab */}
            {currentGroup.collections.map((collection) => (
              <Tabs.Content key={collection.title} value={collection.title} className="space-y-8">
                {collection.description && (
                  <p className="text-gray-600">{collection.description}</p>
                )}

                {/* Handle dashboards */}
                {collection.dashboards.map((dashboard) => (
                  <ReportFrame
                    key={dashboard.id}
                    dashboardId={dashboard.id}
                    title={dashboard.title}
                  />
                ))}

                {/* Handle questions */}
                {collection.questions.map((question) => (
                  <ReportFrame key={question.id} questionId={question.id} title={question.title} />
                ))}

                {/* Handle no content */}
                {collection.questions.length === 0 && collection.dashboards.length === 0 && (
                  <NoReport
                    message={t(
                      'reporting.noContent',
                      {},
                      'No content available in this collection'
                    )}
                  />
                )}
              </Tabs.Content>
            ))}
          </Tabs.Root>
        )}
      </ShowPageWrapper>
    </section>
  );
};
