import { useApiUrl, useCustom, useCustomMutation } from '@refinedev/core';
import { ResourceField } from '@scalingworks/refine-react-admin';
import { MutationOptions } from '@tanstack/react-query';
import { AllOutletFulfillmentTypeByDay, OutletFulfillmentByDayAndType, Outlet } from '~/api';
import { QueryOptions } from '~/config/types';
import { DeviceStatus } from '~/api';

const outletFulfillmentFields = [
  'day',
  {
    outletFulfillments: ['id', 'day', 'openingTime', 'closingTime', 'capacity', 'type', 'status'],
  },
] as ResourceField<OutletFulfillmentByDayAndType>[];

export const useOutletApiHooks = () => {
  // =========================== API
  const apiUrl = useApiUrl();

  // =========================== EVENTS
  const useGetAllOutletFulfillmentsTypeByDay = (options: {
    variables: any;
    queryOptions?: QueryOptions<AllOutletFulfillmentTypeByDay[]>;
  }) => {
    const { variables, queryOptions } = options;

    const hook = useCustom<AllOutletFulfillmentTypeByDay[]>({
      url: apiUrl,
      method: 'get',
      queryOptions,
      metaData: {
        variables,
        fields: [
          'type',
          { days: outletFulfillmentFields },
        ] as ResourceField<AllOutletFulfillmentTypeByDay>[],
        operation: 'getAllOutletFulfillmentsTypeByDay',
      },
    });

    return hook;
  };

  const useGetOutletFulfillmentsByDayAndType = (options: {
    variables: any;
    queryOptions?: QueryOptions<OutletFulfillmentByDayAndType[]>;
  }) => {
    const { variables, queryOptions } = options;

    const hook = useCustom<OutletFulfillmentByDayAndType[]>({
      method: 'get',
      queryOptions,
      url: apiUrl,
      metaData: {
        variables,
        fields: outletFulfillmentFields,
        operation: 'getOutletFulfillmentsByDayAndType',
      },
    });

    return hook;
  };

  const useUpdateOutletFulfillmentChannels = (
    outletId?: string,
    mutationOptions?: MutationOptions<any, any, any, any>
  ) => {
    const { mutate, isLoading } = useCustomMutation({
      mutationOptions,
    });

    const updateOutletFulfillmentChannels = async (values: { fulfillmentChannelIds: string[] }) => {
      return mutate({
        url: apiUrl,
        method: 'post',
        values: {},
        meta: {
          operation: 'updateOutletFulfillmentChannels',
          fields: ['id'],
          variables: {
            outletId: {
              type: 'ID!',
              value: outletId,
            },
            fulfillmentChannelIds: {
              type: '[ID!]!',
              value: values.fulfillmentChannelIds,
            },
          },
        },
        successNotification: {
          type: 'success',
          message: 'Fulfillment channels updated successfully',
        },
        errorNotification: {
          type: 'error',
          message: 'Failed to update fulfillment channels',
        },
      });
    };

    return {
      updateOutletFulfillmentChannels,
      isLoading,
    };
  };

  const useGetOutletDevicesStatus = (outletId?: string) => {
    return useCustom<DeviceStatus[]>({
      url: apiUrl,
      method: 'get',
      queryOptions: {
        enabled: !!outletId,
      },
      meta: {
        fields: [
          'id',
          'lastSeenAt',
          'deviceToken',
          'name',
          'isConnected',
        ] as ResourceField<DeviceStatus>[],
        operation: 'getOutletDevicesStatus',
        variables: {
          outletId: {
            type: 'ID!',
            value: outletId,
          },
        },
      },
    });
  };

  return {
    useGetAllOutletFulfillmentsTypeByDay,
    useGetOutletFulfillmentsByDayAndType,
    useUpdateOutletFulfillmentChannels,
    useGetOutletDevicesStatus,
  };
};
