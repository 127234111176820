import { createHelpers, createResource } from '@scalingworks/refine-react-admin';

import { resourceNames } from '../resource-names';
import { MetabaseQuestionGroup, ShippingMethod } from '~/api';
import { useTranslate } from '@refinedev/core';
import { FiPieChart } from 'react-icons/fi';
import { useMetabaseApi } from '~/config/metabase-hook';
import { IframeRefreshButton, Loading, ShowPageWrapper } from '~/components';
import { useMemo, useState } from 'react';
import { ResourceContext } from '../type';
import { Tabs } from '@scalingworks/react-admin-ui';

const { defineShowPage } = createHelpers<ShippingMethod>({
  resourceName: resourceNames.profile,
});

export const reportingResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames.report,
    label: 'Reporting',
    icon: <FiPieChart />,
    defaultValues: {},
    fields: ['id'],
    columns: () => [],
    allowCreate: false,
    allowDelete: false,
    allowEdit: false,
    allowSearch: false,
    list: {
      render: () => {
        // TODO: show a list of reports 
        return <div>Nothing here</div>;
      },
    },
    show: defineShowPage({
      component: () => {
        return <div>Nothing here</div>;
      },
    }),
  });
};
