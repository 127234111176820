import { groupResources } from '@scalingworks/refine-react-admin';
import compact from 'lodash/compact';
import { ReactNode } from 'react';
import { BsGrid } from 'react-icons/bs';
import { FiCalendar } from 'react-icons/fi';
import { SlPresent } from 'react-icons/sl';
import * as BS from 'react-icons/bs';
import * as FI from 'react-icons/fi';
import * as HI from 'react-icons/hi';
import * as SL from 'react-icons/sl';
import { GroupResource, SellerResource } from '~/api';
import { auditLogResource } from '~/resources/audit/audit-resource';
import { customerResource } from '~/resources/customer/customer-resource';
import { membershipTierResource } from '~/resources/loyalty/membershipTier/membershiptier-resource';
import { pointAdjustmentResource } from '~/resources/loyalty/point-adjustment/point-adjustment-resource';
import { promoCodeResource } from '~/resources/loyalty/promoCode/promo-code-resource-';
import { rewardResource } from '~/resources/loyalty/rewards/reward-resource';
import { discountPromotionResource } from '~/resources/marketing';
import { bannerResource } from '~/resources/marketing/banner/banner-resource';
import { notificationBlastResource } from '~/resources/marketing/notification-blast/notification-blast-resource';
import { orderResource } from '~/resources/order/order-resource';
import { collectionResource } from '~/resources/product/collections/collection-resource';
import { productResource } from '~/resources/product/products/product-resource';
import { profileResource } from '~/resources/profile';
import { reportingResource } from '~/resources/reporting/reporting-resource';
import { reservationResource } from '~/resources/reservation/reservation-resource';
import { settingResource } from '~/resources/setting/setting-resource';
import { staffResource } from '~/resources/staff/staff-resource';
import { HiSpeakerphone } from 'react-icons/hi';
import startCase from 'lodash/startCase';
import { modifierGroupResource } from '~/resources/product/modifiers-group/modifier-group-resource';
import { modifierResource } from '~/resources/product/modifiers/modifier-resource';
import { dashboardResource } from '~/resources/dashboard/dashboard-resource';
import { ResourceContext } from '~/resources/type';
import { outletResource } from '~/resources/outlet/outlet-resource';
import {
  customerReportingResource,
  acquisitionReportingResource,
  behaviourReportingResource,
  financesReportingResource,
  inventoryReportingResource,
  loyaltyReportingResource,
  marketingReportingResource,
  ordersReportingResource,
  retailSalesReportingResource,
  salesReportingResource,
} from '~/resources/reporting';
import { IconType } from 'react-icons';

type ResourceConfig = GroupResource | SellerResource;

const iconLibraries = {
  Bs: BS,
  Fi: FI,
  Hi: HI,
  Sl: SL,
} as const;

const getIconForGroup = (group: GroupResource) => {
  const groupName = group.groupName;
  const groupIcon = group.icon;

  if (groupIcon) {
    // Extract prefix (first two characters) to determine the library
    const prefix = groupIcon.slice(0, 2);
    const library = iconLibraries[prefix as keyof typeof iconLibraries];
    
    if (library) {
      const Icon = library[groupIcon as keyof typeof library] as IconType;
      if (Icon) return <Icon />;
    }
  }

  const icons: Record<string, ReactNode> = {
    productCatalogue: <BsGrid />,
    scheduling: <FiCalendar />,
    loyalty: <SlPresent />,
    marketing: <HiSpeakerphone />,
  };
  
  return icons[groupName ?? ''];
};

const resourceMap: Record<SellerResource, Function | Function[]> = {
  DASHBOARD: dashboardResource,
  REPORTING: reportingResource,
  ORDER: orderResource,
  PRODUCT: productResource,
  MODIFIER: [modifierGroupResource, modifierResource],
  COLLECTION: collectionResource,
  CUSTOMER: customerResource,
  OUTLET: outletResource,
  STAFF: staffResource,
  BANNER: bannerResource,
  DISCOUNT_PROMOTION: discountPromotionResource,
  MEMBERSHIP_TIER: membershipTierResource,
  NOTIFICATION_BLAST: notificationBlastResource,
  POINT_ADJUSTMENT: pointAdjustmentResource,
  PROMO_CODE: promoCodeResource,
  REWARD: rewardResource,
  RESERVATION: reservationResource,
  AUDIT_LOG: auditLogResource,
  SETTING: settingResource,
  PROFILE: profileResource,
  CUSTOMERS_REPORTING: customerReportingResource,
  ACQUISITION_REPORTING: acquisitionReportingResource,
  BEHAVIOUR_REPORTING: behaviourReportingResource,
  FINANCES_REPORTING: financesReportingResource,
  INVENTORY_REPORTING: inventoryReportingResource,
  LOYALTY_REPORTING: loyaltyReportingResource,
  MARKETING_REPORTING: marketingReportingResource,
  ORDERS_REPORTING: ordersReportingResource,
  RETAIL_SALES_REPORTING: retailSalesReportingResource,
  SALES_REPORTING: salesReportingResource,
};

export const constructResources = (
  resourceConfig: ResourceConfig[] = [],
  context: ResourceContext
) => {
  return resourceConfig
    .map((item) => {
      if (typeof item === 'string') {
        const resources = resourceMap[item];

        if (Array.isArray(resources)) {
          return resources.map((resource) => resource(context));
        }

        return resources?.(context);
      }

      // Nested Group
      if (typeof item === 'object') {
        const groupName = item.groupName!;
        const resources = compact(
          item.resources?.flatMap((resourceName) => {
            const mapped = resourceMap[resourceName];

            if (Array.isArray(mapped)) {
              return mapped.map((resource) => resource(context));
            }

            return mapped?.(context);
          }) || []
        );

        return groupResources({
          groupName,
          icon: getIconForGroup(item),
          label: startCase(groupName),
          resources,
        });
      }
    })
    .filter(Boolean);
};

export const getGroupNamesFromResource = (resourceConfig: ResourceConfig[]): string[] => {
  const groupNames: string[] = [];

  resourceConfig.map((item) => {
    if (typeof item === 'object') {
      groupNames.push(item.groupName!);
    }
  });

  return compact(groupNames);
};
