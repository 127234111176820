import { createHelpers, createResource, ResourceField } from '@scalingworks/refine-react-admin';
import { RewardStatus, Reward, RewardType, getSdk, Channel } from '~/api';
import { useEffect, useRef, useState } from 'react';
import {
  ActionButton,
  ActionButtonRefProps,
  ActionModalWithTrigger,
  Loading,
  RadioGroup,
  SomethingWentWrong,
  TriggerConfirmModal,
} from '~/components';
import { MaybeTranslationCallback } from '@scalingworks/refine-react-admin/src/types';
import { FilterControlRecord } from '@scalingworks/refine-react-admin/src/components/filter-controls.types';
import { dateFormatter, formatDateRange, toCamelCaseWord } from '~/resources/helpers';
import { useCreate, useNavigation, useOne, useTranslate, useUpdate } from '@refinedev/core';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { numeralThousandFormat } from '~/config/helper';
import { resourceNames } from '../../resource-names';
import { IsPrivateTag, renderStatusTag, renderTextWithPrefix } from '../helpers';
import { RewardShow } from './show';
import { RewardForm } from './form';
import isEmpty from 'lodash/isEmpty';
import { ResourceContext } from '~/resources/type';
import { SlPresent } from 'react-icons/sl';
import { Tag } from '@scalingworks/react-admin-ui';
import { RewardTypeTagColor } from '~/config/constant';

const { defineFields, defineCardSection, defineShowPage, defineFilterControls } =
  createHelpers<Reward>({
    resourceName: resourceNames.reward,
  });

const fields: ResourceField<Reward>[] = [
  'id',
  'createdAt',
  'updatedAt',
  'name',
  'type',
  'pointCost',
  'status',
  'isPrivate',
  'productVariantId',
  'productVariantName',
  'productVariantImage',
  'productVariantPrice',
  'runningId',
  'redemptionCount',
  'rewardUsedCount',
  { membershipTiers: ['id', 'name'] },
];

export const rewardResource = (context: ResourceContext) => {
  return createResource({
    name: resourceNames?.reward,
    label: 'Rewards',
    icon: <SlPresent />,
    fields: defineFields(fields),
    defaultValues: {} as any,
    defaultPageSize: 25,
    defaultSorter: [{ field: 'createdAt', order: 'desc' }],
    allowCreate: true,
    allowSearch: true,
    createConfig: {
      title: ({ t }) => t('rewards.create.name', { fallback: 'Create Rewards', ns: 'common' }),
    },
    searchConfig: {
      placeholder: ({ t }) =>
        t('rewards.placeholder.search', { fallback: 'Search by Reward Name', ns: 'common' }),
    },
    filterConfig: {
      alwaysExpanded: true,
    },
    filterControls: {
      startAt: {
        type: 'daterange',
        config: {
          label: 'Start At',
        },
      },
      endAt: {
        type: 'daterange',
        config: {
          label: 'End At',
        },
      },
      status: {
        type: 'select',
        config: {
          options: [
            {
              label: 'All',
              value: 'all',
            },
            ...Object.keys(RewardStatus).map((key) => ({
              label: key,
              // @ts-ignore
              value: RewardStatus[key],
            })),
          ],
          label: 'Status',
          placeholder: 'Select Status',
        },
      },
    } as MaybeTranslationCallback<FilterControlRecord<Reward>>,
    dataProvider: {
      getList: ({ metaData, client, pagination, sort }) => {
        const current = pagination?.current || 1;
        const pageSize = pagination?.pageSize || 25;
        const queryKey = metaData?.queryContext?.queryKey as any[];

        const filters = queryKey?.[3]?.filters || [];
        const findFilter = (field: string, operator?: string) =>
          filters.find(
            (item: any) => item?.field === field && (!operator || item?.operator === operator)
          );

        const searchField = findFilter('search');
        const statusField = findFilter('status');
        const startAtBegin = findFilter('startAt', 'gte');
        const startAtEnd = findFilter('startAt', 'lte');
        const endAtBegin = findFilter('endAt', 'gte');
        const endAtEnd = findFilter('endAt', 'lte');
        const type = queryKey?.[3]?.filters?.find((subItem: any) => subItem?.key === 'listingTab');
        return getSdk(client)
          ?.getRewards({
            input: {
              search: searchField?.value,
              type: type?.value?.[0]?.value,
              status: statusField?.value === 'all' ? undefined : statusField?.value,
              startDateBegin: startAtBegin?.value,
              startDateEnd: startAtEnd?.value,
              endDateBegin: endAtBegin?.value,
              endDateEnd: endAtEnd?.value,
            },
            options: {
              skip: (current - 1) * pageSize,
              take: pageSize,
            },
          })
          ?.then((res) => {
            return {
              data: res?.getRewards?.items,
              total: res?.getRewards?.totalItems,
            };
          });
      },
      getOne: ({ client, id }) => {
        return getSdk(client)
          ?.Reward({
            rewardId: id as string,
          })
          ?.then((res) => {
            return {
              data: res?.reward,
            };
          });
      },
      create: async ({ client, variables, metaData }) => {
        let assetsUrl;
        if (!isEmpty(variables?.image)) {
          assetsUrl = await getSdk(client)?.createAssets({
            input: variables?.image?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }
        return getSdk(client)
          ?.CreateReward({
            input: {
              name: variables?.name,
              description: variables?.description,
              pointCost: parseInt(variables?.pointCost),
              quantity: parseInt(variables?.quantity),
              isPrivate: !variables?.visible,
              bigTitle: variables?.bigTitle || undefined,
              smallSubTitle: variables?.smallSubTitle || undefined,
              redeemPerUserLimit: !!variables?.redeemPerUserLimit ? parseInt(variables?.redeemPerUserLimit) : undefined,
              onRedeemConfig: variables?.onRedeemConfig || undefined,
              bigImageUrl: variables?.bigImageUrl || undefined,
              claimableStartDate: variables?.claimableStartDate,
              claimableEndDate: variables?.claimableEndDate,
              productVariantId: variables?.variantId,
              redeemCriteria: variables?.redeemCriteria || '-',
              startAt: dayjs(variables?.startDate)?.toISOString(),
              endAt: dayjs(variables?.endDate)?.toISOString(),
              termCondition: variables?.termsConditions || '-',
              type: variables?.type,
              entitledTierIds: variables?.membershipTiers,
              imageUrls: assetsUrl?.createAssets?.map((subItem: any) => subItem?.source),
            },
          })
          ?.then((res) => ({
            data: res,
          }));
      },
      deleteOne: ({ client, id, metaData }) => {
        return getSdk(client)
          ?.DeleteReward({
            deleteRewardId: id?.toString() || '',
          })
          ?.then((res) => ({
            data: res,
          }));
      },
      update: async ({ client, variables, id }) => {
        const formData = variables?.data;
        const rewardData = variables?.rewardData as Reward;
        const rewardId = id as string;

        let assetsUrl: any;
        if (!isEmpty(formData?.image) && typeof formData?.image?.[0] !== 'string') {
          assetsUrl = await getSdk(client)?.createAssets({
            input: formData?.image?.map((subItem: any) => {
              return {
                file: subItem,
              };
            }),
          });
        }

        return getSdk(client)
          ?.UpdateReward({
            input: {
              id: rewardId,
              description: formData?.description || rewardData?.description,
              name: formData?.name || rewardData?.name,
              bigImageUrl: formData?.bigImageUrl || rewardData?.bigImage || undefined,
              bigTitle: formData?.bigTitle || rewardData?.bigTitle || undefined,
              smallSubTitle: formData?.smallSubTitle || rewardData?.smallSubTitle || undefined,
              redeemPerUserLimit: !!formData?.redeemPerUserLimit
                ? parseInt(formData?.redeemPerUserLimit)
                : rewardData?.redeemPerUserLimit || undefined,
              onRedeemConfig: formData?.onRedeemConfig ?? (rewardData?.onRedeemConfig || undefined),
              claimableStartDate: formData?.claimableStartDate || rewardData?.claimableStartDate,
              claimableEndDate: formData?.claimableEndDate || rewardData?.claimableEndDate,
              isPrivate:
                formData?.visible === undefined ? rewardData?.isPrivate : !formData?.visible,
              pointCost: parseInt(formData?.pointCost || rewardData?.pointCost),
              quantity: parseInt(formData?.quantity || rewardData?.quantity),
              productVariantId: formData?.variantId,
              redeemCriteria: formData?.redeemCriteria || rewardData?.redeemCriteria || '-',
              startAt: formData?.startDate
                ? dayjs(formData?.startDate)?.toISOString()
                : rewardData?.startAt,
              endAt: formData?.endDate
                ? dayjs(formData?.endDate)?.toISOString()
                : rewardData?.endAt,
              termCondition: formData?.termsConditions || rewardData?.termCondition || '-',
              entitledTierIds:
                formData?.membershipTiers ||
                rewardData?.membershipTiers?.map((subItem) => subItem?.id),
              status: formData?.status || rewardData?.status,
              imageUrl: assetsUrl?.createAssets?.[0]?.source || rewardData?.imageUrls?.[0],
              type: formData?.type,
            },
          })
          ?.then((res) => ({
            data: res,
          }));
      },
    },
    columns: ({ LinkToDetails, navigateToEdit, invokeDelete, t }) => {
      return [
        {
          id: 'name',
          header: t('rewards.column.name', { fallback: 'Reward Name', ns: 'common' }),
          cell: (data) => {
            const { id, name } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>{name}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'type',
          header: t('rewards.column.type', { fallback: 'Type', ns: 'common' }),
          cell: (data) => {
            const { id, type: rawType } = data.row.original as Reward;
            const type = rawType as unknown as RewardType;
            return (
              <LinkToDetails resourceId={id}>
                <Tag color={RewardTypeTagColor[type]}>{toCamelCaseWord(type)}</Tag>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'availableQuantity',
          header: t('rewards.column.availableQty', { fallback: 'Available Qty', ns: 'common' }),
          tooltip: t('rewards.column.availableQtyHint', {
            fallback: 'Available Quantity',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, quantity, redemptionCount } = data.row.original as Reward;
            const remaining = (quantity || 0) - (redemptionCount || 0);
            return (
              <LinkToDetails resourceId={id}>
                <span>{numeralThousandFormat(remaining)}</span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'pointsCost',
          header: t('rewards.column.points', { fallback: 'Points Cost', ns: 'common' }),
          cell: (data) => {
            const { id, pointCost } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                {renderTextWithPrefix({
                  text: `${numeralThousandFormat(pointCost)}`,
                  prefixText: 'PTS',
                  prefixPosition: 'right',
                })}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'dateRange',
          header: t('rewards.column.period', { fallback: 'Period', ns: 'common' }),
          tooltip: t('rewards.column.periodHint', {
            fallback: 'Period',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, startAt, endAt } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <span>
                  {formatDateRange(new Date(startAt), endAt ? new Date(endAt) : undefined)}
                </span>
              </LinkToDetails>
            );
          },
        },
        {
          id: 'isPrivate',
          header: t('rewards.column.visible', { fallback: 'Visible', ns: 'common' }),
          tooltip: t('rewards.column.visibleHint', {
            fallback: 'Reward available for redemption',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, isPrivate } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                <IsPrivateTag isPrivate={isPrivate} resource={resourceNames?.reward} />
              </LinkToDetails>
            );
          },
        },
        {
          id: 'status',
          header: t('rewards.column.status', { fallback: 'Status', ns: 'common' }),
          tooltip: t('rewards.column.statusHint', {
            fallback: '',
            ns: 'common',
          }),
          cell: (data) => {
            const { id, status } = data.row.original as Reward;
            return (
              <LinkToDetails resourceId={id}>
                {renderStatusTag(status === RewardStatus?.Active, resourceNames?.reward)}
              </LinkToDetails>
            );
          },
        },
        {
          id: 'actions',
          header: () => '',
          accessorKey: 'id',
          enableSorting: false,
          cell: (data) => {
            const { id, status } = data?.row?.original as Reward;
            const actionButtonRef = useRef<ActionButtonRefProps>(null);
            const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
            const [showUpdateModal, setShowUpdateModal] = useState(false);
            const [statusVal, setStatusVal] = useState<string>(status);
            const { mutate } = useUpdate();
            const t = useTranslate();

            useEffect(() => {
              setStatusVal(status);
            }, [data?.row?.original]);

            return (
              <>
                <ActionButton
                  ref={actionButtonRef}
                  actions={[
                    {
                      label: t('actions.updateStatus'),
                      name: 'edit',
                      onAction: () => navigateToEdit({ id }),
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <ActionModalWithTrigger
                              triggerText={t('actions.updateStatus')}
                              visible={showUpdateModal}
                              title={t('actions.updateStatus')}
                              renderBody={() => {
                                return (
                                  <div>
                                    <RadioGroup
                                      isField
                                      label={t('rewards.column.status')}
                                      options={[
                                        ...Object.keys(RewardStatus).map((key) => ({
                                          label: t(`rewards.status.${key.toLowerCase()}`),
                                          // @ts-ignore
                                          value: RewardStatus[key],
                                        })),
                                      ]}
                                      value={statusVal}
                                      onValueChange={setStatusVal}
                                    />
                                  </div>
                                );
                              }}
                              onOpenChange={(val) => {
                                const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                                actionButtonSetOpen?.(val);
                                setShowUpdateModal(val);
                              }}
                              onPressCancel={() => setStatusVal(status)}
                              onPressConfirm={() => {
                                mutate({
                                  id,
                                  resource: resourceNames?.reward,
                                  values: {
                                    data: {
                                      status: statusVal,
                                    },
                                    rewardData: data?.row?.original,
                                  },
                                });
                              }}
                            />
                          </button>
                        );
                      },
                    },
                    {
                      label: t('actions.edit'),
                      name: 'edit',
                      onAction: () => navigateToEdit({ id }),
                    },
                    {
                      label: t('actions.delete'),
                      name: 'delete',
                      onAction: () => invokeDelete({ id }),
                      render: (onAction) => {
                        return (
                          <button type="button">
                            <TriggerConfirmModal
                              visible={showDeleteConfirmation}
                              onOpenChange={(val) => {
                                const actionButtonSetOpen = actionButtonRef?.current?.setOpen;
                                setShowDeleteConfirmation(val);
                                actionButtonSetOpen?.(val);
                              }}
                              onPressConfirm={onAction}
                            />
                          </button>
                        );
                      },
                    },
                  ]}
                />
              </>
            );
          },
        },
      ];
    },
    list: {
      tabs: {
        options: [
          {
            // @ts-ignore
            filterValue: {},
            label: ({ t }) => t(`rewards.tabs.all`, { fallback: 'All', ns: 'common' }),
          },
          {
            // @ts-ignore
            filterValue: { field: 'type', operator: 'eq', value: RewardType?.InStore },
            label: ({ t }) => t(`rewards.tabs.inStore`, { fallback: 'In-Store', ns: 'common' }),
          },
          // TODO: Temporary hide in-app rewards
          // {
          //   // @ts-ignore
          //   filterValue: { field: 'type', operator: 'eq', value: RewardType?.InApp },
          //   label: ({ t }) => t(`rewards.tabs.inApp`, { fallback: 'In-App', ns: 'common' }),
          // },
        ],
      },
    },
    show: {
      component: (helpers) => {
        return <RewardShow helpers={helpers} />;
      },
    },
    create: {
      render: (helpers) => {
        const navigation = useNavigation();
        const { mutate } = useCreate();

        const onSubmit = async (formData: any) => {
          await mutate(
            {
              resource: resourceNames.reward,
              values: formData,
            },
            {
              onSuccess: () => {
                navigation?.goBack();
              },
            }
          );
        };
        return <RewardForm onSubmit={onSubmit} />;
      },
    },
    edit: {
      render: () => {
        const { id } = useParams();
        const navigation = useNavigation();
        const { mutate } = useUpdate();
        if (!id) return <SomethingWentWrong />;

        const { data, isLoading } = useOne({
          resource: 'rewards',
          id,
          metaData: {
            fields,
          },
        });

        const rewardData = data?.data as Reward;

        const onSubmit = async (formData: any) => {
          await mutate(
            {
              id,
              resource: resourceNames.reward,
              values: {
                data: formData,
                rewardData,
              },
            },
            {
              onSuccess: () => {
                navigation?.goBack();
              },
            }
          );
        };
        if (isLoading) return <Loading />;
        return <RewardForm onSubmit={onSubmit} rewardData={rewardData} />;
      },
    },
  });
};
